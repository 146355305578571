// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React from "react";
import { PlasmicControllerCard } from "./plasmic/collect_wise_draft/PlasmicControllerCard";
import {useAuth} from "../contexts/AuthContext"


function ControllerCard_(props, ref) {
  const {controllers, setControllers} = useAuth()


  return <PlasmicControllerCard root={{ ref }} {...props} 
  deleteController={{
    onClick: () => setControllers(controllers.filter((x) => x.id !== props.id))
  }}
  />;
}

const ControllerCard = React.forwardRef(ControllerCard_);

export default ControllerCard;
