import * as React from "react";
import { PlasmicAutomationSelect__Overlay } from "./plasmic/collect_wise_draft/PlasmicAutomationSelect__Overlay";

function AutomationSelect__Overlay_(props, ref) {
  const { plasmicProps } = PlasmicAutomationSelect__Overlay.useBehavior(
    props,
    ref
  );

  return <PlasmicAutomationSelect__Overlay {...plasmicProps} />;
}

const AutomationSelect__Overlay = React.forwardRef(AutomationSelect__Overlay_);

export default Object.assign(AutomationSelect__Overlay, {
  __plumeType: "triggered-overlay"
});
