import * as React from "react";
import { PlasmicSelectGender2__Option } from "./plasmic/collect_wise_draft/PlasmicSelectGender2__Option";

function SelectGender2__Option_(props, ref) {
  const { plasmicProps } = PlasmicSelectGender2__Option.useBehavior(props, ref);
  return <PlasmicSelectGender2__Option {...plasmicProps} />;
}

const SelectGender2__Option = React.forwardRef(SelectGender2__Option_);

export default Object.assign(SelectGender2__Option, {
  __plumeType: "select-option"
});
