// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicChildPassword } from "./plasmic/collect_wise_draft/PlasmicChildPassword";
import { useState } from "react";
import axios from "axios";
import { config } from "./constants";
import { useLocation,useParams, useHistory } from 'react-router-dom';


function ChildPassword_(props, ref) {

  const [passwordContent, setPasswordContent] = useState('')
  const [ErrorContent, setErrorContent] = useState("")
  const {showToast, data, setLoading} = props;
  const history = useHistory()



  function containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }

  function containsNumber(str) {
    const specialChars = /[1234567890]/;
    return specialChars.test(str);
  }

  const handleSubmit = async () =>{

    if (!(passwordContent.length > 11 && containsSpecialChars(passwordContent) && containsNumber(passwordContent))){
      setErrorContent("Password needs to be more than 11 characters, contain at least 1 special character, and contain at least 1 number")
      return;
    }
    setErrorContent("")

    try {
      setLoading(true)
      await axios({
        method: "POST",
        url: `${config.endpoint}/createChildUser`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          payload: data,
          password : passwordContent
        }
      })
      setLoading(false)
      history.push("/login")

    } catch (error) {
      console.log(error)
      showToast(false, "Something went wrong")
      setLoading(false)
    }

    

    
  }




  return <React.Fragment>
    <div style={{width:"100%", maxWidth: "700px"}}>
  <PlasmicChildPassword root={{ ref }} {...props}
  
  signupPasswordInput={{
    danger: ErrorContent ? true : false,
    value: passwordContent,
    onChange: (e) => {
      setPasswordContent(e.target.value);
    }

  }}

  customErrorInstance={{
    // wrap: node => true ? node : null,
    props: {
      customErrorText: ErrorContent
    }
  }}

  saveBtn = {{
    onClick : handleSubmit
  }}
  
  />
  </div>
</React.Fragment>
}

const ChildPassword = React.forwardRef(ChildPassword_);

export default ChildPassword;
