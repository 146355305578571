// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Icons8EditSvgIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 40 40"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fill={"#dff0fe"}
        d={"M5.982 29.309l2.589-2.59 5.047 4.396-2.903 2.904-8.262 3.528z"}
      ></path>

      <path
        fill={"#4788c7"}
        d={
          "M8.595 27.403l4.291 3.737-2.457 2.457-7.026 3.001 3.001-7.003 2.191-2.192m-.047-1.367L5.56 29.024l-4.059 9.474L11 34.44l3.351-3.351-5.803-5.053z"
        }
      ></path>

      <path fill={"#4788c7"} d={"M3.805 33.13L1.504 38.5l5.384-2.299z"}></path>

      <path
        fill={"#b6dcfe"}
        d={
          "M30.062 5.215L32.3 2.978C32.931 2.347 33.769 2 34.66 2s1.729.347 2.36.978a3.341 3.341 0 010 4.721l-2.237 2.237-4.721-4.721z"
        }
      ></path>

      <path
        fill={"#4788c7"}
        d={
          "M34.66 2.5a2.82 2.82 0 012.007.831 2.84 2.84 0 010 4.014l-1.884 1.884-4.013-4.014 1.884-1.884A2.816 2.816 0 0134.66 2.5m0-1c-.982 0-1.965.375-2.714 1.124l-2.591 2.591 5.428 5.428 2.591-2.591a3.838 3.838 0 000-5.428A3.826 3.826 0 0034.66 1.5z"
        }
      ></path>

      <path
        fill={"#b6dcfe"}
        d={
          "M11.346 33.388a1.777 1.777 0 00-.282-.454c-.31-.363-.749-.584-1.31-.661-.2-1.267-1.206-1.803-1.989-1.964-.132-.864-.649-1.342-1.201-1.582l21.49-21.503 4.721 4.721-21.429 21.443z"
        }
      ></path>

      <path
        fill={"#4788c7"}
        d={
          "M28.054 7.931l4.014 4.014-20.637 20.649c-.242-.278-.638-.59-1.261-.748-.306-1.078-1.155-1.685-1.983-1.943a2.454 2.454 0 00-.821-1.272l20.688-20.7m-.001-1.414L5.56 29.023h.021c.197 0 1.715.054 1.715 1.731 0 0 1.993.062 1.993 1.99 1.982 0 1.71 1.697 1.71 1.697l22.482-22.495-5.428-5.429z"
        }
      ></path>

      <path
        fill={"#dff0fe"}
        d={"M27.562 7.714l3.944-3.945 4.722 4.72-3.943 3.946z"}
      ></path>

      <path
        fill={"#4788c7"}
        d={
          "M31.507 4.477l4.014 4.014-3.237 3.237-4.014-4.014 3.237-3.237m0-1.414l-4.651 4.651 5.428 5.428 4.651-4.651-5.428-5.428z"
        }
      ></path>
    </svg>
  );
}

export default Icons8EditSvgIcon;
/* prettier-ignore-end */
