import React from "react";
import "../spinner.css";

export default function LoadingSpinner() {
  return (
    <div className="spinnermodal">
        {/* <span>Loading...</span> */}
    <div className="half-spinner"></div>
    </div>
  );
}