// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React from "react";
import { PlasmicPaymentsPageSidebar } from "./plasmic/collect_wise_draft/PlasmicPaymentsPageSidebar";
import {useAuth} from "../contexts/AuthContext"

function PaymentsPageSidebar_(props, ref) {
  const {showSideBar, setShowSideBar, escrowPaymentPreview} = useAuth()



  return <PlasmicPaymentsPageSidebar root={{ ref }} {...props} 
  closeButton={{
    onClick: () => setShowSideBar(false)
  }}
  milestoneNameStack={{
    wrap: node => escrowPaymentPreview.milestonesExist ? node : null,
  }}
  />;
}

const PaymentsPageSidebar = React.forwardRef(PaymentsPageSidebar_);

export default PaymentsPageSidebar;
