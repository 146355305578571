import React, {useState, useMemo, useEffect} from "react";
import { useAuth } from "../contexts/AuthContext";
import { PlasmicConfirmEmail } from "./plasmic/collect_wise_draft/PlasmicConfirmEmail";
import { sendEmailVerification } from "firebase/auth";
import {auth} from "../firebase"
import { useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module'
import { PlasmicGetStarted } from "./plasmic/collect_wise_draft/PlasmicGetStarted";



const tagManagerArgs = {
    gtmId: 'GTM-NF9C29K'
}
 
TagManager.initialize(tagManagerArgs)



function ConfirmEmail_(props, ref) {
  
const {currentUser, isVerified, setIsVerified} = useAuth()
const history = useHistory();



  return <PlasmicConfirmEmail root={{ ref }} {...props} emailText={"Check " + currentUser.email + " to verify your email and get started"} 
  resendEmailButton={{
    onClick: async () => {

      const host = window.location.host
      const protocol = host.startsWith('localhost') ? 'http' : 'https'
      const settings = {
        url: `${protocol}://${host}/get-started`,
      }

      await sendEmailVerification(auth.currentUser, settings)
      
    }
  }}/>;
}

const ConfirmEmail = React.forwardRef(ConfirmEmail_);

export default ConfirmEmail;