import React from "react";
import "../spinnertext3.css";

export default function LoadingSpinner3WithText(props) {
  return (
    <div className="spinner">
       <span className="displaytext3">{props.text}...</span>
    <div className="half-spinner"></div>
    </div>
  );
}