// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState} from "react";
import { PlasmicOnboardingOwnerDetailsRetry } from "./plasmic/collect_wise_draft/PlasmicOnboardingOwnerDetailsRetry";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Onboarding.css"
import axios from "axios"
import {useAuth} from "../contexts/AuthContext"
import {updateDoc, doc, getDoc} from "firebase/firestore"; 
import { db } from "../firebase"
import { useHistory } from 'react-router-dom';
import LoadingSpinner from "./LoadingSpinner";
import { config } from './constants'

function OnboardingOwnerDetailsRetry_(props, ref) {
 
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [ssn, setSSN] = useState('')
  const [address1, setAddress1] = useState('')
  const [country, setCountry] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [dob, setDob] = useState('')
  const [passport, setPassport] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const {currentUser, tempUrl, setTempUrl, onboardModal, setOnboardModal} = useAuth()

  const history = useHistory()

  return onboardModal ? <LoadingSpinner/> : loading ? <LoadingSpinner/> : <PlasmicOnboardingOwnerDetailsRetry root={{ ref }} {...props} 
  ownerText={`The owner ${tempUrl.name} wasn't immediately verified by our compliance team. Please try again by re-entering their information below.`}
  firstName={{
    value: firstName,
    onChange: (e) => {
      setFirstName(e.target.value)
    }
  }}
  lastName={{
    value: lastName,
    onChange: (e) => {
      setLastName(e.target.value)
    }
  }} 
  countrySelect={{
    onChange: (e) => {
      setCountry(e)
    }
  }}
  address1={{
    value: address1,
    onChange: (e) => {
      setAddress1(e.target.value)
    }
  }}
  city={{
    value: city,
    onChange: (e) => {
      setCity(e.target.value)
    }
  }}
  selectState={{
    onChange: (e) => {
      setState(e)
    }
  }}
  zip={{
    value: zip,
    onChange: (e) => {
      setZip(e.target.value)
    }
  }}
  dob={{
    children: <DatePicker placeholderText={"mm/dd/yyyy"} className="dateBox" selected={dob} onChange={(date) => setDob(date)} />
  }}
  ssnStack={{
    wrap: node => country === 'US' || country === '' ? node : null
  }}
  ssn={{
    value: ssn,
    onChange: (e) => {
      setSSN(e.target.value)
      setPassport('NA')
    }, 
    onBlur: (e) => {
      if (!(e.target.value.includes("-")) && ssn.length > 4){
        setSSN(ssn.substring(0, 3) + "-" + ssn.substring(3,5) + "-" + ssn.substring(5))
      }
    }
  }}
  passportStack={{
    wrap: node => country === 'US' || country === '' ? null : node
  }}
  passport={{
    value: passport,
    onChange: (e) => {
      setPassport(e.target.value)
      setSSN('NA')
    }
  }}
  errorText={{
    wrap: node => error ? node : null,
  }}
  continueButton={{
    onClick: async () => {

      if (firstName === '' || lastName  === '' || country  === '' || address1  === '' || city  === '' || state  === '' || zip  === '' || dob  === '' || ssn === '' || passport === '' ){
        
        setError(true)
      
      } else {

        setLoading(true)

        var requestBody = {
          firstName: firstName,
          lastName: lastName,
          dateOfBirth: dob.getFullYear() + "-" + ("0" + (Number(dob.getMonth())+ 1)).slice(-2) + "-" + ("0" + dob.getDate()).slice(-2),
          ssn: ssn,
          address: {
            address1: address1,
            city: city,
            stateProvinceRegion: state,
            country: country,
            postalCode: zip
          }
        };


        const retryStatus = await axios({
          method: "POST",
          url: `${config.endpoint}/onboardingFunctions-handleRetryStatus`,
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${await currentUser.getIdToken()}`
          },
          data: {
            customerUrl: tempUrl.ownerUrl,
            reqBody: requestBody
          }
        })
  
          
       

          var ownerUrlJSON = JSON.stringify({
            ownerUrl: tempUrl.ownerUrl
          })
        
          const resOwnerVerification = await axios({
            method: "POST",
            url: `${config.endpoint}/onboardingFunctions-getOwnerVerificationStatus`,
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${await currentUser.getIdToken()}`
            },
            data: {
            ownerUrl: ownerUrlJSON
            }
          })
          
        var ownerVerification = resOwnerVerification.data


         

        var tempOwners = tempUrl.owners


        for (var i = 0; i < tempOwners.length; i++){

          if (tempOwners[i].ownerUrl === tempUrl.ownerUrl){
            tempOwners[i].ownerVerification = ownerVerification
            break;
          }

        }


        if (ownerVerification === "verified") {

        


          await updateDoc(doc(db, "userInfo", tempUrl.docId), {


            owners: tempOwners
            
        
          })

          var proceed = true


          for (var i = 0; i < tempOwners.length; i++){
           
            

            if (tempOwners[i].ownerVerification !== "verified" && tempOwners[i].ownerVerification !== "pending"){

             

              setTempUrl({ownerUrl: tempOwners[i].ownerUrl, name: tempOwners[i].name, docId: tempUrl.docId, owners: tempOwners, customerUrl: tempUrl.customerUrl})

              if (tempOwners[i].ownerVerification === "incomplete"){
                history.push("/verify-owners")
                proceed = false
                setFirstName('')
                setLastName('')
                setSSN('')
                setAddress1('')
                setCountry('')
                setCity('')
                setState('')
                setZip('')
                setDob('')
                setPassport('')
                setError(false)
                setLoading(false)

                break
              } else {
                history.push("/verify-owners-document")
                proceed = false
                break
              }

              
            }

          }

          if (proceed){
          

          var customerUrlJSON = JSON.stringify({
            customerUrl: tempUrl.customerUrl
          })

          const resCertifyCustomer = await axios({
            method: "POST",
            url: `${config.endpoint}/onboardingFunctions-certifyCustomerOwnership`,
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${await currentUser.getIdToken()}`
            },
            data: {
              customerUrl: customerUrlJSON
            }
          })


          var anyPending = false

          for (var i = 0; i < tempOwners.length; i++){
           
            if (tempOwners[i].ownerVerification === "pending"){

              anyPending = true

            }

          }

          if (!anyPending) {
     
            
            await updateDoc(doc(db, "userInfo", tempUrl.docId), {

              allOwnersVerified: true
          
            })


            const docRef = doc(db, "userInfo", tempUrl.docId);
            const docSnap = await getDoc(docRef);


            if (docSnap.data().verificationStatus === "verified"){
              history.push("/business-approved")
            } else {
              history.push("/onboarding-businessdetails-documentreceived")
            }
           
          

        } else {

          history.push("/onboarding-businessdetails-documentreceived")
        }

        } else if (ownerVerification === "document"){

          setTempUrl({ownerUrl: tempOwners[i].ownerUrl, name: tempOwners[i].name, docId: tempUrl.docId, owners: tempOwners, customerUrl: tempUrl.customerUrl})

          history.push("/verify-owners-document")

        }

      }

      }
    }
  }}
  />;
}

const OnboardingOwnerDetailsRetry = React.forwardRef(
  OnboardingOwnerDetailsRetry_
);

export default OnboardingOwnerDetailsRetry;
