import * as React from "react";
import { PlasmicSelectGender2__OptionGroup } from "./plasmic/collect_wise_draft/PlasmicSelectGender2__OptionGroup";

function SelectGender2__OptionGroup(props) {
  const { plasmicProps } = PlasmicSelectGender2__OptionGroup.useBehavior(props);
  return <PlasmicSelectGender2__OptionGroup {...plasmicProps} />;
}

export default Object.assign(SelectGender2__OptionGroup, {
  __plumeType: "select-option-group"
});
