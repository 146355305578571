// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useRef, useEffect} from "react";
import { PlasmicViewDebtor } from "./plasmic/collect_wise_draft/PlasmicViewDebtor";
import CurrencyInput from "react-currency-input-field";
import "../Input.css"
import { db, storage } from "../firebase"
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit, updateDoc } from "firebase/firestore";
import { v4 } from "uuid";
import { ref, uploadBytes, getDownloadURL, uploadBytesResumable, listAll, list, getStorage} from "firebase/storage";
import { useAuth } from '../contexts/AuthContext';
import LoadingSpinner from "./LoadingSpinner";
import Moment from 'moment';
import { useHistory } from 'react-router-dom';
import {useParams} from "react-router-dom"
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from 'react-toastify';


function ViewDebtor_(props, ref1) {

  
  const [debtorName, setDebtorName] = useState('')
  const [debtAge, setDebtAge] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [address1, setAddress1] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [creditorName, setCreditorName] = useState('')
  const [debtSituation, setDebtSituation] = useState('')
  const [error, setError] = useState('')
  const [productNameError, setProductNameError] = useState('')
  const [debtorNameError, setDebtorNameError] = useState('')
  const [debtAmountError, setDebtAmountError] = useState('')
  const [debtAgeError, setDebtAgeError] = useState('')
  const [debtorPhoneError, setDebtorPhoneError] = useState('')
  const [debtorEmailError, setDebtorEmailError] = useState('')
  const [creditorNameError, setCreditorNameError] = useState('')
  const [uploadContractFile, setUploadContractFile] = useState(null)
  const [contractFile, setContractFile] = useState(null)
  const [invoiceFile, setInvoiceFile] = useState(null)
  const {showToast, checkPermission } = useAuth()
  const { currentUser } = useAuth();
  const [initialDebtAmount, setInitialDebtAmount] = useState(0)
  const [loading, setLoading] = useState(true)
  let { id } = useParams()
  const formatDate = Moment().format("MMM Do, YYYY");
  const history = useHistory()
  const [productName, setProductName] = useState('')
  const [dob, setDob] = useState('')
  const [files, setFiles] = useState([])
  const [docId, setDocId] = useState('')

  
const currencyInput = useRef(null)


const handleBeforeUnload = (e) => {
  e.preventDefault();
  const message =
    "Are you sure you want to leave? All provided data will be lost.";
  e.returnValue = message;
  return message;
}


  useEffect(() => {
     const q = query(collection(db, "debtors"), where("id", "==", id), where("user", "==", currentUser.uid) )
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
    querySnapshot.forEach((doc) => {
        
      console.log("This isworking")
      setInitialDebtAmount(Number(doc.data().debtAmount.substring(1).replace(/,/g, "")))
      setDebtorName(doc.data().debtorName)

      setPhoneNumber(doc.data().contactFields ? doc.data().contactFields[0].phone : doc.data().phoneNumber)
      setEmail(doc.data().contactFields ? doc.data().contactFields[0].email : doc.data().email)

      setAddress1(doc.data().address1)
      setCity(doc.data().city)
      setState(doc.data().state)
      setZip(doc.data().zip)
      setCreditorName(doc.data().creditorName)
      setDebtSituation(doc.data().debtSituation)
      setProductName(doc.data().productName)
      setFiles(doc.data().files)
      setContractFile(doc.data().contractFile)
      setInvoiceFile(doc.data().invoiceFile)
      setDocId(doc.id)
      setDebtAge(doc.data().debtAge.toDate())

      try{
        setDob(doc.data().dob.toDate())
        // setDebtAge(doc.data().debtAge.toDate())
      } catch (e){

      }


      })
      setLoading(false)
    })  

  }, [])

  useEffect(() => {
    console.log(files)
  }, [files])


  async function handleDownloadUrl (source, path) {
    console.log("This is being run")
    const parts = path.split('/');
    const lastPart = parts[parts.length - 1]
    const [fileName] = lastPart.split('.')

    const image = await fetch(source)
    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)
  
    const link = document.createElement('a')
    link.href = imageURL
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  
  }

  
  async function handleDownload(path) {
    console.log(path)

    getDownloadURL(ref(storage, path))
    .then((url) => {
   
      console.log("Got dowload url")
      handleDownloadUrl(url, path)
  
    })
    .catch((error) => {
      // Handle any errors
    });

    // const storage = getStorage();
    // getDownloadURL(ref(storage, path))
    //   .then((url) => {
    //     // `url` is the download URL for 'images/stars.jpg'
    
    //     // This can be downloaded directly:
    //     const xhr = new XMLHttpRequest();
    //     xhr.responseType = 'blob';
    //     xhr.onload = (event) => {
    //       const blob = xhr.response;
    //     };
    //     xhr.open('GET', url);
    //     xhr.send();
    //     console.log('here')
    
    //   })
    //   .catch((error) => {
    //     // Handle any errors
    //   });

  }




  async function uploadFile(upload) {

    if (upload == null) return '';

    const path = `debtorFile/${currentUser.uid}/${upload.name + v4()}`

    const imageRef = ref(storage, path);

    uploadBytes(imageRef, upload).then((snapshot) => {


    });

    return path

  }



  return loading ? <PlasmicViewDebtor root={{ ref1 }} {...props} vertStack={{children: <></>}} /> : <PlasmicViewDebtor root={{ ref1 }} {...props}
  debtorName={{
    value: debtorName,
    onChange: (e) => {
      setDebtorName(e.target.value)
    }
  }}
  debtAmount={{
    children: <CurrencyInput ref={currencyInput} className="input"
    prefix="$"
    name="currencyInput"
    id="currencyInput"
    data-number-to-fixed="2"
    data-number-stepfactor="100"
    // value={value}
    placeholder="$ "
    // onChange={handleChange}
    // onBlur={handleOnBlur}
    allowDecimals
    decimalsLimit="2"
    defaultValue={initialDebtAmount}
    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
    disableAbbreviations
  />
  }}
  // debtAge={{
  //   value: debtAge,
  //   onChange: (e) => {
  //     setDebtAge(e.target.value)
  //   }
  // }}
  debtAge={{
    children: <DatePicker className="dateBox2" selected={debtAge} onChange={(date) => setDebtAge(date)} />
  }}
  phoneNumber={{
    value: phoneNumber,
    onChange: (e) => {
      setPhoneNumber(e.target.value)
    }
  }}
  email={{
    value: email,
    onChange: (e) => {
      setEmail(e.target.value)
    }
  }}
  address1={{
    value: address1,
    onChange: (e) => {
      setAddress1(e.target.value)
    }
  }}
  city={{
    value: city,
    onChange: (e) => {
      setCity(e.target.value)
    }
  }}
  state={{
    value: state,
    onChange: (e) => {
      setState(e.target.value)
    }
  }}
  zip={{
    value: zip,
    onChange: (e) => {
      setZip(e.target.value)
    }
  }}
  creditorName={{
    value: creditorName,
    onChange: (e) => {
      setCreditorName(e.target.value)
    }
  }}
  debtSituation={{
    value: debtSituation,
    onChange: (e) => {
      setDebtSituation(e.target.value)
    }
  }}
  productName={{
    value: productName,
    onChange: (e) => {
      setProductName(e.target.value)
    }
  }}
  uploadContractFile={{
    onClick: () => {

      console.log('tried')

      var input = document.createElement('input');
      input.type = 'file';

      input.onchange = e => {
        setUploadContractFile(e.target.files[0])
      }

      input.click();

    }
  }}
  downloadFiles={{
    wrap: (node) => files ? node : null,
    onClick: async () => {
        for(const file of files){
          await handleDownload(file)
        }
      }
  }}
  contractOr={{
    wrap: (node) => contractFile ? node : null,
  }}
  downloadInvoiceFile={{
    wrap: (node) => invoiceFile ? node : null,
    props: {
      onClick: async () => {

        handleDownload(invoiceFile)

      }
    }
  }}
  invoiceOr={{
    wrap: (node) => invoiceFile ? node : null,
  }}
  errorText={{
    wrap: (node) => error ? node : null,
  }}
  productError={{
    wrap: (node) => productNameError ? node : null,
  }}
  debtorNameError={{
    wrap: (node) => debtorNameError ? node : null,
  }}
  debtAmountError={{
    wrap: (node) => debtAmountError ? node : null,
  }}
  debtAgeError={{
    wrap: (node) => debtAgeError ? node : null,
  }}
  debtorPhoneError={{
    wrap: (node) => debtorPhoneError ? node : null,
  }}
  debtorEmailError={{
    wrap: (node) => debtorEmailError ? node : null,
  }}
  creditorNameError={{
    wrap: (node) => creditorNameError ? node : null,
  }}
  contractFileStack={{
    wrap: (node) => uploadContractFile ? node : null,
  }}
  dob={{
    children: <DatePicker className="dateBox2" selected={dob} onChange={(date) => setDob(date)} />
  }}
  contractFile={uploadContractFile ? uploadContractFile.name : null}
  submitButton={{
    onClick: async () => {
      if (!checkPermission("Edit Debtor")){
        showToast(false,"Permission Denied")
        return;
      }
      if (debtorName === '') {
        setDebtorNameError(true)
      } else {
        setDebtorNameError(false)
      }

      if(currencyInput.current.value === '') {
        setDebtAmountError(true)
      } else {
        setDebtAmountError(false)
      }


      if (debtAge === '') {
        setDebtAgeError(true)
      } else {
        setDebtAgeError(false)
      }


      if (productName === '') {
        setProductNameError(true)
      } else {
        setProductNameError(false)
      }

      if (creditorName === '') {
        setCreditorNameError(true)
      } else {
        setCreditorNameError(false)
      }

      if (phoneNumber === '' && email === '') {
        setDebtorPhoneError(true)
        setDebtorEmailError(true)
      } else {
        setDebtorPhoneError(false)
        setDebtorEmailError(false)
      }

  

      if (debtorName === '' || currencyInput.current.value === '' || debtAge === '' || productName === '' || (phoneNumber === '' && email === '') || creditorName === '') {
        setError(true)
      } else {
        setError(false)


        var id = v4()


        toast.success("Debtor successfully updated", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
          });

        window.addEventListener("beforeunload", handleBeforeUnload);

        const debtors = await updateDoc(doc(db, "debtors", docId), {
          user: currentUser.uid,
          debtorName: debtorName,
          debtAmount: currencyInput.current.value,
          debtAge: debtAge,
          phoneNumber: phoneNumber,
          email: email,
          address1: address1,
          city: city,
          state: state,
          zip: zip,
          creditorName: creditorName,
          debtSituation: debtSituation,
          contractFile: uploadContractFile ? (await uploadFile(uploadContractFile)) : null,
          date: serverTimestamp(),
          amountCollected: 0,
          numberOfEmails: 0,
          numberOfTexts: 0,
          numberOfCalls: 0,
          displayDate: formatDate,
          id: id,
          productName: productName,
          dob: dob
        })

        history.push(`/debtor/${id}`)

        window.removeEventListener("beforeunload", handleBeforeUnload);

        
        
        setLoading(false)

      }

  
    }
  }}
  />;
}

const ViewDebtor = React.forwardRef(ViewDebtor_);

export default ViewDebtor;
