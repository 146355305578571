// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, { useState, useEffect } from "react";
import { PlasmicClientsList } from "./plasmic/collect_wise_draft/PlasmicClientsList";
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Configure, useHits } from 'react-instantsearch-hooks-web';
import '@algolia/autocomplete-theme-classic';
import { useAuth } from "../contexts/AuthContext";
import ContractsListCard from "./ContractsListCard";
import { db, storage } from "../firebase"
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit, startAt, endAt, startAfter, endBefore, limitToLast } from "firebase/firestore";
import ClientsListCard from "./ClientsListCard";
import { Button, Modal, Form } from "react-bootstrap"
import AddClientModal from "./AddClientModal"
import { v4 } from "uuid";
import Moment from 'moment';
import LoadingSpinner from "./LoadingSpinner";
import LoadingSpinnerWithText from "./LoadingSpinnerWithText";
import axios from "axios";
import { config } from './constants'


const algoliaClient = algoliasearch('3YSA4177MW', '0019c18519fefc703571e7786ea74dad');


const searchClient = {

  ...algoliaClient,

  search(requests) {


    if (requests.every(({ params }) => !params.query)) {
      // setOpenContractSearch(false)
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }
    // setOpenContractSearch(true)
    return algoliaClient.search(requests);
  },
};


function CustomHits(props) {
  const { hits, results, sendEvent, query } = useHits();
  const { currentUser, clientHits, setClientHits, openClientSearch, setOpenClientSearch } = useAuth()


  if (typeof results.query === 'undefined') {
    setOpenClientSearch(false)
  } else {
    setOpenClientSearch(true)
  }


  setClientHits(hits)



  return (<>
  </>)

}


function ClientsList_(props, ref) {

  const { currentUser, clientHits, setClientHits, openClientSearch, checkPermission, clientSubmission, showToast } = useAuth()
  const [clients, setClients] = useState([])
  const [last, setLast] = useState({})
  const [first, setFirst] = useState({})
  const [showPagination, setShowPagination] = useState(false)
  const formatDate = Moment().format("MMM Do, YYYY");
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [clientLoading, setClientLoading] = useState(false)

  const handleClose = () => setShowModal(false);


  async function modalSubmit() {

    if (clientSubmission.contactName === '') {
      alert("Contact name is required")
    } else if (clientSubmission.email === '') {
      alert("Email is required")
    } else if (clientSubmission.company === '') {
      alert("Company name is required")
    } else {


      var clientName = ''

      if (clientSubmission.company === '') {
        clientName = clientSubmission.contactName
      } else {
        clientName = clientSubmission.company
      }


      setShowModal(false);

      setClientLoading(true)

      var docId = v4()
      const clientId = v4();
      var payload = {
        firstName: clientSubmission.contactName,
        lastName: clientName,
        email: v4() + clientSubmission.email,
        ipAddress: "99.99.99.99",
      }

      var resp = await axios({
        method: "POST",
        url: `${config.endpoint}/onboardingFunctions-createClientURL`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${await currentUser.getIdToken()}`
        },
        data: {
          payload: payload
        }
      })

      const params = {
        customer: {
          display_id: clientId,
          display_name: clientSubmission.contactName,
          company_name: clientSubmission.company,
          title: null,
          first_name: clientSubmission.contactName.split(" ")[0],
          middle_name: null,
          last_name:  clientSubmission.contactName.split(" ")[1] ? clientSubmission.contactName.split(" ")[1] : null,
          suffix: null,
          individual: null,
          project: null,
          addresses: [
            {
              id: null,
              type: 'primary',
              string: null,
              name: null,
              line1: null,
              line2: null,
              line3: null,
              line4: null,
              street_number: null,
              city: null,
              state: null,
              postal_code: null,
              country: null,
              latitude: null,
              longitude: null,
              county: null,
              contact_name: null,
              salutation: null,
              phone_number: null,
              fax: null,
              email: null,
              website: null,
              row_version: null
            }
          ],
          phone_numbers: [
            {
              id: null,
              country_code: null,
              area_code: null,
              number: '111-111-1111',
              extension: null,
              type: 'primary'
            }
          ],
          emails: [
            {
              id: '',
              email: clientSubmission.email,
              type: 'primary'
            }
          ],
          websites: [
            {
              id: null,
              url: 'http://example.com',
              type: 'primary'
            }
          ],
          bank_accounts: [
            {
              account_number: '123465',
              account_name: 'SPACEX LLC',
              account_type: 'credit_card',
              iban: 'CH2989144532982975332',
              bic: 'AUDSCHGGXXX',
              bsb_number: '062-001',
              branch_identifier: '001',
              bank_code: 'BNH',
              currency: 'USD'
            }
          ],
          notes: null,
          tax_rate: {
            id: null
          },
          tax_number: null,
          currency: "USD",
          account: null,
          parent: null,
          status: "active",
          row_version: null
        }
      }

      const response = await axios({
        method: "POST",
        url: `${config.endpoint}/apiDeckFunctions?consumerId=${currentUser.uid}&method=createCustomer`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${await currentUser.getIdToken()}`
        },
        data: params
      }).catch(err => {
        if(err.response.status == 403){
          alert(err.response.data.message)
        }
        return
      });

     


function getRandomLetter() {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const randomIndex = Math.floor(Math.random() * alphabet.length);
  return alphabet[randomIndex];
}

function generateInvoiceIdentifier(inputString) {
  // Generate a random letter
  const randomLetter = getRandomLetter();

  // Extract the first 7 characters of the input string
  const firstSevenCharacters = inputString.slice(0, 7);

  // Capitalize the first 8 characters
  const capitalizedString = firstSevenCharacters.toUpperCase();

  // Concatenate the random letter, capitalized part, and the rest of the string
  const finalString = randomLetter + capitalizedString

  return finalString;
}

      await setDoc(doc(db, "clients", docId), {
        company: clientName,
        contactName: clientSubmission.contactName,
        email: clientSubmission.email,
        notes: "",
        date: serverTimestamp(),
        user: currentUser.uid,
        clientId: clientId,
        displayDate: formatDate,
        amountSpent: 0,
        docId: docId,
        url: resp.data,
        api_deck_clientId: response ? response.data.id : null,
        invoiceNum: 1,
        invoice_identifier: (clientSubmission.invoiceId === "" || clientSubmission.invoiceId === " ") ? generateInvoiceIdentifier(clientId) : clientSubmission.invoiceId.slice(0, 8).toUpperCase()
      })

      clientSubmission.invoiceId = ""

      setClientLoading(false)

    }
  }


  useEffect(() => {

    const q = query(collection(db, "clients"), where("user", "==", currentUser.uid), orderBy("date", "desc"))

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tempDocs = [];
      querySnapshot.forEach((doc) => {
        tempDocs.push(doc)
      });

      if (tempDocs.length > 10) {
        setShowPagination(true)
      }


    });


  }, [])




  useEffect(() => {

    const q = query(collection(db, "clients"), where("user", "==", currentUser.uid), orderBy("date", "desc"), limit(10))

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tempData = [];
      const tempDocs = []
      querySnapshot.forEach((doc) => {
        tempData.push(doc.data())
        tempDocs.push(doc)
      });

      setClients(tempData)

      setLast(tempDocs[tempDocs.length - 1])
      setFirst(tempDocs[0])

      setLoading(false)


    });



  }, [])



  /// function for next page pagination
  function nextPage() {

    // if (contracts.length == 11){

    const q = query(collection(db, "clients"), where("user", "==", currentUser.uid), orderBy("date", "desc"), startAfter(last), limit(10))

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tempData = [];
      const tempDocs = []
      querySnapshot.forEach((doc) => {
        tempData.push(doc.data())
        tempDocs.push(doc)
      });

      if (tempDocs.length > 0) {
        setLast(tempDocs[tempDocs.length - 1])
        setFirst(tempDocs[0])
      }



      setClients(tempData)

    });


  }
  /// function for last page pagination

  function prevPage() {

    const q = query(collection(db, "clients"), where("user", "==", currentUser.uid), orderBy("date", "desc"), endBefore(first), limitToLast(10))

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tempData = [];
      const tempDocs = []
      querySnapshot.forEach((doc) => {
        tempData.push(doc.data())
        tempDocs.push(doc)
      });

      if (tempDocs.length > 0) {
        setLast(tempDocs[tempDocs.length - 1])
        setFirst(tempDocs[0])
      }

      setClients(tempData)

    });

  }




  return (
    <>
      {clientLoading ? <LoadingSpinnerWithText text={"Creating Client..."} /> :
        <PlasmicClientsList root={{ ref }} {...props}
          addClientButton={{
            onClick: () => {

              if(!checkPermission("Create Client")) {
                showToast(false,"Permission Denied")
                return;
              }
              setShowModal(true)
            }
          }}
          searchVertStack={{
            children:
              <>
                <InstantSearch searchClient={searchClient} indexName="Firebase_Client_Collection"  >
                  <Configure />
                  <SearchBox className="searchbox" placeholder="Search..." />
                  <CustomHits />
                </InstantSearch>
              </>
          }}
          clientsListStack={{
            children:
              <>
                {openClientSearch ? clientHits.map(x => {
                  if (x.user == currentUser.uid) {
                    return <ClientsListCard key={x.clientId} clientName={x.company} clientEmail={x.email} date={x.displayDate} id={x.clientId} />
                  }

                }) : clients.map(x => {
                  return <ClientsListCard key={x.clientId} clientName={x.company} clientEmail={x.email} date={x.displayDate} id={x.clientId} />
                })}
              </>
          }}

          paginationStack={{
            wrap: node => showPagination ? node : null
          }}
          nextButton={{
            onClick: () => nextPage()
          }}
          prevButton={{
            onClick: () => prevPage()
          }}
          vertStack={loading && {
            children: <LoadingSpinner />
          }}
        />}
      <Modal show={showModal} onHide={handleClose}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddClientModal />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={modalSubmit}>
            Add client
          </Button>
        </Modal.Footer>
      </Modal>
    </>)
}

const ClientsList = React.forwardRef(ClientsList_);

export default ClientsList;



// import * as React from "react";
// import { PlasmicDebtorsList } from "./plasmic/collect_wise_draft/PlasmicDebtorsList";

// function DebtorsList_(props, ref) {
//   // Use PlasmicDebtorsList to render this component as it was
//   // designed in Plasmic, by activating the appropriate variants,
//   // attaching the appropriate event handlers, etc.  You
//   // can also install whatever React hooks you need here to manage state or
//   // fetch data.
//   //
//   // Props you can pass into PlasmicDebtorsList are:
//   // 1. Variants you want to activate,
//   // 2. Contents for slots you want to fill,
//   // 3. Overrides for any named node in the component to attach behavior and data,
//   // 4. Props to set on the root node.
//   //
//   // By default, we are just piping all DebtorsListProps here, but feel free
//   // to do whatever works for you.
//   return <PlasmicDebtorsList root={{ ref }} {...props} />;
// }

// const DebtorsList = React.forwardRef(DebtorsList_);

// export default DebtorsList;