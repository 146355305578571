import BasePlugin from '@uppy/core/lib/BasePlugin.js'
import { v4 } from "uuid";
import { storage } from "../firebase"
import { TaskEvent, ref,  getDownloadURL, uploadBytesResumable, uploadTask } from "firebase/storage";


export default class FirebaseCloudStorage extends BasePlugin {
  constructor(uppy, opts) {
    super(uppy);
    this.currentUserID = opts.currentUserID
    this.type = "uploader";
    this.id = "FirebaseCloudStorage";
    this.title = "Firebase Cloud Storage";
    this.uploadFile = this.uploadFile.bind(this);
  }

  uploadFile(fileIds) {
    return Promise.all(
      fileIds.map(id => {
        return new Promise((resolve, reject) => {

            const file = this.uppy.getFile(id);
            //starting upload
            this.uppy.emit("upload-started", file);
            const refId = v4();
            const filePath = `debtorFile/${this.currentUserID}/${file.name}`
            const metaData = {
            contentType: file.type
          };
            const storageRef = ref(storage, filePath)
            console.log("This is the file being uploaded", file)
            // Create a new upload task with progress listeners
            const uploadTask = uploadBytesResumable(storageRef, file.data, metaData);

            uploadTask.catch(error => {
              console.log('error uploading')
            })

            uploadTask.on('state_changed', {
              'next': (snapshot) => {
                // Upload progress
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.uppy.emit("upload-progress", file, {
                  filePath: filePath,
                  uploader: this,
                  bytesUploaded: snapshot.bytesTransferred,
                  bytesTotal: snapshot.totalBytes,
                  progress: progress
                });
              },
              'error': (error) => {
                // Handle unsuccessful uploads
                this.uppy.emit("upload-error", file, error);
                reject(error)
              },
              'complete': () => {
                 // Handle successful uploads on complete
                 getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                  let lastOne = false
                  if(fileIds.indexOf(id) === (fileIds.length - 1)){
                    lastOne = true
                  }
                  file.downloadUrl = downloadURL; // Assigning the download URL to the file object
                  this.uppy.emit("upload-success", filePath, file, lastOne);
                }).catch((error) => {      
                 this.uppy.emit("upload-error", file, error);
                 reject(error)
                });
              }
            })
          })
        })
    )
      }

  install() {
    this.uppy.addUploader(this.uploadFile);
  }

  uninstall() {
    this.uppy.removeUploader(this.uploadFile);
  }
}