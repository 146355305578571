// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, { useState, useRef, useEffect } from "react";
import { PlasmicSubscriptionDetails } from "./plasmic/collect_wise_draft/PlasmicSubscriptionDetails";
import "../Input.css"
import CurrencyInput from "react-currency-input-field";
import DatePicker from "react-datepicker";
import { db, storage } from "../firebase"
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit, updateDoc } from "firebase/firestore";
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Configure, useHits } from 'react-instantsearch-hooks-web';
import SearchResults from "./SearchResults"
import AddNewClientThroughMenu from "./AddNewClientThroughMenu";
import { Button, Modal, Form } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import 'bootstrap/dist/css/bootstrap.min.css';
import AddClientModal from "./AddClientModal"
import { Link, useHistory } from "react-router-dom"
import { v4 } from "uuid";
import { useAuth } from "../contexts/AuthContext";
import Moment from 'moment';
import InvoiceFields from "./InvoiceFields"
import ClickAwayListener from 'react-click-away-listener';
import useLocalStorage from "../hooks/useLocalStorage";
import axios from "axios"
import LoadingSpinnerWithText from "./LoadingSpinnerWithText";
import { config } from './constants'

const algoliaClient = algoliasearch('FL3TQWNAKP', 'f67b3ce7c011000feed8a7898a75330b');

const searchClient = {

  ...algoliaClient,

  search(requests) {


    if (requests.every(({ params }) => !params.query)) {
      // setOpenContractSearch(false)
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }
    // setOpenContractSearch(true)
    return algoliaClient.search(requests);
  },
};

function CustomHits(props) {
  const { globalHits, setGlobalHits, setOpenClientSearch } = useAuth()
  const { hits, results, sendEvent } = useHits();

  setGlobalHits(hits)

  if (typeof results.query === 'undefined') {
    setOpenClientSearch(false)
  } else {
    setOpenClientSearch(true)
  }

  return (<>
  </>)
}


function SubscriptionDetails_(props, ref) {

  const [openSearchVertStack, setOpenSearchVertStack] = useState(false)
  const [showSearch, setShowSearch] = useState(true)
  const [companySelect, setCompanySelect] = useState('')
  const [emailSelect, setEmailSelect] = useState('')
  const [client, setClient] = useState({})
  const [showModal, setShowModal] = useState(false)
  const formatDate = Moment().format("MMM Do, YYYY");
  const [advancedOptions, setAdvancedOptions] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState({ ACH: true, Card: false, Wire: true, CardSurcharge: true })
  const [recurringInvoice, setRecurringInvoice] = useState(false)
  const [paymentReminders, setPaymentReminders] = useState(false)
  const [recurringNumber, setRecurringNumber] = useState('')
  const [reminderNumber, setReminderNumber] = useState('')
  const [automationOptions, setAutomationOptions] = useState({ enabled: false, number: 0, unit: '' })
  const [reminderOptions, setReminderOptions] = useState({ enabled: false, number: 0, unit: '' })
  const [loading, setLoading] = useState(false)
  const [achSwitch, setAchSwitch] = useState(true)
  const [cardSwitch, setCardSwitch] = useState(false)
  const [cardSurchargeSwitch, setCardSurchargeSwitch] = useState(true)
  const [clientsList, setClientsList] = useState([])
  const [cycle, setCycle] = useState('Monthly')

  const [error, setError] = useState(false)

  const [dueDate, setDueDate] = useState('')

  const [notes, setNotes] = useState('')

  const [cardApproved, setCardApproved] = useState(true)


  const history = useHistory()

  const { globalHits, setGlobalHits, clientSubmission, checkPermission, currentUser, invoiceFields, setInvoiceFields, invoiceBalance, invoiceSubmitParams,
    setInvoiceSubmitParams, openClientSearch, setOpenClientSearch, showToast } = useAuth()


  const handleClose = () => setShowModal(false);

  useEffect(() => {


    if (Object.keys(invoiceSubmitParams).length) {


      setShowSearch(false)
      setClient(invoiceSubmitParams.client)
      setDueDate(invoiceSubmitParams.dueDate)
      setCompanySelect(client.company)
      setEmailSelect(client.email)
      setNotes(invoiceSubmitParams.notes)
      setPaymentMethods(invoiceSubmitParams.paymentMethods)
      setAchSwitch(invoiceSubmitParams.paymentMethods.ACH)
      setCardSwitch(invoiceSubmitParams.paymentMethods.Card)
      setCardSurchargeSwitch(invoiceSubmitParams.paymentMethods.CardSurcharge)
      setCycle(invoiceSubmitParams.cycle)

      setPaymentReminders(invoiceSubmitParams.reminderOptions.enabled)
      setReminderNumber(invoiceSubmitParams.reminderOptions.number)
      setReminderOptions(invoiceSubmitParams.reminderOptions)

      setRecurringInvoice(invoiceSubmitParams.automationOptions.enabled)
      setRecurringNumber(invoiceSubmitParams.automationOptions.number)
      setAutomationOptions(invoiceSubmitParams.automationOptions)
      

    }



  }, [])


  useEffect(() => {

    const q = query(collection(db, "debtors"), where("user", "==", currentUser.uid), orderBy("date", "desc"), limit(5))

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tempData = [];
      querySnapshot.forEach((doc) => {
        tempData.push(doc.data())
      });

      setClientsList(tempData)

    });


  }, [])


  async function modalSubmit() {
    setOpenClientSearch(false)

    if (clientSubmission.contactName === '') {
      alert("Contact name is required")
    } else if (clientSubmission.email === '') {
      alert("Email is required")
    } else if (clientSubmission.company === '') {
      alert("Company name is required")
    } else {


      var clientName = ''

      if (clientSubmission.company === '') {
        clientName = clientSubmission.contactName
      } else {
        clientName = clientSubmission.company
      }



      setShowModal(false);

      setLoading(true)

      var docId = v4()
      const clientId = v4();
try {

  const params = {
    customer: {
      display_id: clientId,
      display_name: clientSubmission.contactName,
      company_name: clientSubmission.company,
      title: null,
      first_name: clientSubmission.contactName.split(" ")[0],
      middle_name: null,
      last_name:  clientSubmission.contactName.split(" ")[1] ? clientSubmission.contactName.split(" ")[1] : null,
      suffix: null,
      individual: null,
      project: null,
      addresses: [
        {
          id: null,
          type: 'primary',
          string: null,
          name: null,
          line1: null,
          line2: null,
          line3: null,
          line4: null,
          street_number: null,
          city: null,
          state: null,
          postal_code: null,
          country: null,
          latitude: null,
          longitude: null,
          county: null,
          contact_name: null,
          salutation: null,
          phone_number: null,
          fax: null,
          email: null,
          website: null,
          row_version: null
        }
      ],
      phone_numbers: [
        {
          id: null,
          country_code: null,
          area_code: null,
          number: '111-111-1111',
          extension: null,
          type: 'primary'
        }
      ],
      emails: [
        {
          id: '',
          email: clientSubmission.email,
          type: 'primary'
        }
      ],
      websites: [
        {
          id: null,
          url: 'http://example.com',
          type: 'primary'
        }
      ],
      bank_accounts: [
        {
          account_number: '123465',
          account_name: 'SPACEX LLC',
          account_type: 'credit_card',
          iban: 'CH2989144532982975332',
          bic: 'AUDSCHGGXXX',
          bsb_number: '062-001',
          branch_identifier: '001',
          bank_code: 'BNH',
          currency: 'USD'
        }
      ],
      notes: null,
      tax_rate: {
        id: null
      },
      tax_number: null,
      currency: "USD",
      account: null,
      parent: null,
      status: "active",
      row_version: null
    }
  }

  const response = await axios({
    method: "POST",
    url: `${config.endpoint}/apiDeckFunctions?consumerId=${currentUser.uid}&method=createCustomer`,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${await currentUser.getIdToken()}`
    },
    data: params
  }).catch(err => {
    if(err.response.status == 403){
      alert(err.response.data.message)
    }
    return
  });

  console.log(response)
  var payload = {
    firstName: clientSubmission.contactName,
    lastName: clientName,
    email: v4() + clientSubmission.email,
    ipAddress: "99.99.99.99",
  }


  var resp = await axios({
    method: "POST",
    url: `${config.endpoint}/onboardingFunctions-createClientURL`,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${await currentUser.getIdToken()}`
    },
    data: {
      payload: payload
    }
  })

  


  

  setCompanySelect(clientSubmission.company)
  setEmailSelect(clientSubmission.email)
  setClient({ company: clientSubmission.company, email: clientSubmission.email, contactName: clientSubmission.contactName, notes: "", date: serverTimestamp(), docId: docId, url: resp.data, api_deck_clientId: response ? response.data.id : null, invoiceNum: 1, invoice_identifier: (clientSubmission.invoiceId === "" || clientSubmission.invoiceId === " ") ? generateInvoiceIdentifier(clientId) : clientSubmission.invoiceId.slice(0, 8).toUpperCase() })

  setShowSearch(false)
  setGlobalHits([])
  setOpenSearchVertStack(false)

  function getRandomLetter() {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const randomIndex = Math.floor(Math.random() * alphabet.length);
    return alphabet[randomIndex];
  }
  
  function generateInvoiceIdentifier(inputString) {
    // Generate a random letter
    const randomLetter = getRandomLetter();
  
    // Extract the first 7 characters of the input string
    const firstSevenCharacters = inputString.slice(0, 7);
  
    // Capitalize the first 8 characters
    const capitalizedString = firstSevenCharacters.toUpperCase();
  
    // Concatenate the random letter, capitalized part, and the rest of the string
    const finalString = randomLetter + capitalizedString
  
    return finalString;
  }


  await setDoc(doc(db, "clients", docId), {
    company: clientName,
    contactName: clientSubmission.contactName,
    email: clientSubmission.email,
    notes: "",
    date: serverTimestamp(),
    user: currentUser.uid,
    clientId: clientId,
    displayDate: formatDate,
    amountSpent: 0,
    docId: docId,
    url: resp.data,
    api_deck_clientId: response ? response.data.id : null,
    invoiceNum: 1,
    invoice_identifier: (clientSubmission.invoiceId === "" || clientSubmission.invoiceId === " ") ? generateInvoiceIdentifier(clientId) : clientSubmission.invoiceId.slice(0, 8).toUpperCase()

  })

  clientSubmission.invoiceId = ""

  setLoading(false)

}
catch(err){
  console.log({err})
  setLoading(false)
  alert("Something Went Wrong")
}
    }
  }

  function handleSearchResultsClick(client) {
    setCompanySelect(client.debtorName)
    setEmailSelect(client.email === "" ? client.phoneNumber : client.email)

    // finish updating this
    setClient({
      company: client.debtorName, email: client.email === "" ? client.phoneNumber : client.email, contactName: client.contactName, notes: client.notes, date: client.date, docId: client.docId, url: client.url, clientId: client.clientId, api_deck_clientId: client.api_deck_clientId, invoiceNum : client.invoiceNum, invoice_identifier: client.invoice_identifier
    })


    setShowSearch(false)
    setGlobalHits([])
    setOpenSearchVertStack(false)
  }


  function handleAddClient() {

    if(!checkPermission("Create Client")) {
      showToast(false,"Permission Denied")
      return;
    }
    setOpenSearchVertStack(false)
    setShowModal(true)
  }


  const onClickAway = () => {
    setOpenSearchVertStack(false)
  }




  return (
    <>
   { loading ? <LoadingSpinnerWithText text={"Creating Client..."} /> : <PlasmicSubscriptionDetails root={{ ref }} {...props} 
  balanceDue={"Balance Due: " + invoiceBalance.toLocaleString('en-US', { style: 'currency', currency: 'USD', })}
  companySelected={companySelect} emailSelected={emailSelect}
  deleteSelection={{
    onClick: () => {
      setShowSearch(true)
    }
  }}
  searchRectangle={{
    root: {
      wrap: node => showSearch ? node : null
    },
    children:
      <>
        {showSearch &&
          <InstantSearch searchClient={searchClient} indexName="debts"  >
            <Configure hitsPerPage={5} />
            <SearchBox
              placeholder=""
            />
            {openSearchVertStack &&
              <CustomHits />}
          </InstantSearch> }
      </>,
    onClick: () => {
      setOpenSearchVertStack(true)
    }
  }}
  selectedClient={{
    wrap: node => !showSearch ? node : null
  }}
  menuSelectStack={{
    root: {
      wrap: node => openSearchVertStack ? node : null
    },
    children:
      <ClickAwayListener onClickAway={onClickAway}>
        <>
          <AddNewClientThroughMenu onClick={() => history.push("/submitdebts")} />
          {openClientSearch ? globalHits.map(x => {

            if (x.user === currentUser.uid) {
              return <SearchResults key={v4()} companyName={x.debtorName} emailAddress={x.email === "" ? x.phoneNumber : x.email} onClick={() => handleSearchResultsClick(x)} />
            }

          }) : clientsList.map(x => {

            return <SearchResults key={v4()} companyName={x.debtorName} emailAddress={x.email === "" ? x.phoneNumber : x.email} onClick={() => handleSearchResultsClick(x)} />

          })}
        </>
      </ClickAwayListener>
  }}
  subscriptionSelect={{
    value: cycle,
    onChange: (e) => {
      setCycle(e)
    }
  }}
  dueDate={{
    children: <DatePicker className="dateBox2" selected={dueDate} onChange={(date) => setDueDate(date)} />
  }}
  notes={{
    value: notes,
    onChange: (e) => {
      setNotes(e.target.value)
    }
  }}
  advancedOptionsInstance={{
    onClick: () => setAdvancedOptions(!advancedOptions),
    opened: advancedOptions
  }}
  advancedOptionsStack={{
    wrap: node => advancedOptions ? node : null
  }}
  achSwitch={{
    isChecked: achSwitch,
    onChange: (e) => {
      setAchSwitch(e)
      const temp = paymentMethods
      temp.ACH = e
      setPaymentMethods(temp)
    }
  }}
  cardSwitch={{
    isChecked: cardSwitch,
    onChange: (e) => {


      setCardSwitch(e)
      const temp = paymentMethods
      temp.Card = e
      setPaymentMethods(temp)

      if (e && cardSurchargeSwitch) {
        setCardSurchargeSwitch(false)
        const temp = paymentMethods
        temp.CardSurcharge = false
        setPaymentMethods(temp)
      }


    }
  }}
  cardSurchargeSwitch={{
    isChecked: cardSurchargeSwitch,
    onChange: (e) => {


      setCardSurchargeSwitch(e)
      const temp = paymentMethods
      temp.CardSurcharge = e
      setPaymentMethods(temp)

      if (e && cardSwitch) {
        setCardSwitch(false)
        const temp = paymentMethods
        temp.Card = false
        setPaymentMethods(temp)
      }



    }
  }}
  paymentReminderSwitch={{
    isChecked: paymentReminders,
    onChange: (e) => {
      setPaymentReminders(e)
      const temp = reminderOptions
      temp.enabled = !temp.enabled
      setReminderOptions(temp)
    }
  }}
  paymentReminderStack={{
    wrap: node => paymentReminders ? node : null
  }}
  paymentReminderStack2={{
    wrap: node => paymentReminders ? node : null
  }}
  paymentReminderText={{
    wrap: node => paymentReminders ? node : null
  }}
  recurringNumber={{
    value: recurringNumber,
    onChange: (e) => {
      setRecurringNumber(e.target.value)
    },
    onBlur: (e) => {
      const temp = automationOptions
      temp.number = e.target.value
      setAdvancedOptions(temp)
    }
  }}
  reminderNumber={{
    value: reminderNumber,
    onChange: (e) => {
      setReminderNumber(e.target.value)
    },
    onBlur: (e) => {
      const temp = reminderOptions
      temp.number = e.target.value
      setReminderOptions(temp)
    }
  }}
  recurringEscrowSelect={{
    onChange: (e) => {
      const temp = automationOptions
      temp.unit = e
      setAdvancedOptions(temp)
    }
  }}
  paymentReminderSelect={{
    onChange: (e) => {
      const temp = reminderOptions
      temp.unit = e
      setReminderOptions(temp)
    }
  }}
  invoiceStack={{
    children: invoiceFields.map((x, i) => {
      i += 1
      return <InvoiceFields key={x.id} id={x.id} number={i + "."} />
    })
  }}
  addItem={{
    onClick: () => setInvoiceFields([...invoiceFields, { item: '', quantity: 0, price: '', id: v4(), balanceDue: 0 }])
  }}
  sendButton={{
    onClick: () => {
     

      var incompleteFields = false

      for (var i = 0; i < invoiceFields.length; i++) {

        if (invoiceFields[i].item === '' || invoiceFields[i].quantity === 0 || invoiceFields[i].price === '') {

          incompleteFields = true
          break;

        }

      }

      if (!Object.keys(client).length || dueDate === '' || incompleteFields) {
        setError(true)
      } else {

        setError(false)

        alert("Payments have not been enabled on your account yet. Please contact your account manager or support to set up payments on your account.")

        // uncomment this when ready
        // setInvoiceSubmitParams({ client: client, dueDate: dueDate, notes: notes, paymentMethods: paymentMethods, reminderOptions: reminderOptions, automationOptions: automationOptions, cycle: cycle })
        // history.push("/subscription-preview")

      }


    }
  }}
  errorText={{
    wrap: node => error ? node : null
  }}
    /> }
    <Modal show={showModal} onHide={handleClose}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddClientModal />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={modalSubmit}>
            Add client 
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const SubscriptionDetails = React.forwardRef(SubscriptionDetails_);

export default SubscriptionDetails;
