// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useEffect} from "react";
import { useAuth } from "../contexts/AuthContext";
import { PlasmicDashboard } from "./plasmic/collect_wise_draft/PlasmicDashboard";
import { PlasmicDashboard4 } from "./plasmic/collect_wise_draft/PlasmicDashboard4";
import { PlasmicDashboard3 } from "./plasmic/collect_wise_draft/PlasmicDashboard3";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Link, useHistory } from "react-router-dom"
import { db, storage } from "../firebase"
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit, updateDoc } from "firebase/firestore"; 
import Moment from 'moment'
import { te } from "date-fns/locale";
import LoadingSpinner3 from "./LoadingSpinner3";
import DashboardPaymentsCard from "./DashboardPaymentsCard";
import DashboardContractsCard from "./DashboardContractsCard";
import { format } from "date-fns"


function Dashboard_(props, ref) {
  const { currentUser, timeSelectPostClick, setTimeSelectPostClick, onboarded, currentTimeSelect, setCurrentTimeSelect } = useAuth()
  const [data, setData] = useState([{name: 0, uv: 0}, {name: 0, uv: 0}, {name: 0, uv: 0}, {name: 0, uv: 0},{name: 0, uv: 0},{name: 0, uv: 0},{name: 0, uv: 0},{name: 0, uv: 0},{name: 0, uv: 0},{name: 0, uv: 0}, ])
  const [accountBalance, setAccountBalance] = useState(0)
  const [escrowBalance, setEscrowBalance] = useState(0)
  const [payouts, setPayouts] = useState(0)
  const [totalBalance, setTotalBalance] = useState(0)
  const [startDate, setStartDate] = useState(Moment().subtract(1, 'day').format("MMM Do, YYYY"))
  const [loading, setLoading] = useState(true)
  const [payments, setPayments] = useState([])
  const [contracts, setContracts] = useState([])

  
  const [timeSelectOpen, setTimeSelectOpen] = useState(false)
  const history = useHistory()

  var today = new Date()
  var todayFormat = Moment().format("MMM Do, YYYY");


  var yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  var yesterdayFormat = Moment().subtract(1, 'day').format("MMM Do, YYYY")

  var lastWeek = new Date()
  lastWeek.setDate(lastWeek.getDate() - 7)
  var lastWeekFormat = Moment().subtract(1, 'week').format("MMM Do, YYYY");

  var lastMonth = new Date()
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  var lastMonthFormat = Moment().subtract(1, 'month').format("MMM Do, YYYY");

  var last3Months = new Date()
  last3Months.setMonth(last3Months.getMonth() - 3);
  var last3MonthsFormat = Moment().subtract(3, 'month').format("MMM Do, YYYY");

  var lastYear = new Date()
  lastYear.setFullYear(lastYear.getFullYear() - 1) 
  var lastYearFormat = Moment().subtract(1, 'year').format("MMM Do, YYYY");


  function CustomTooltip({ payload, label, active }) {
 
    return (
      <div className="custom-tooltip">
        <p className="desc"></p>
      </div>
    );
  
  }


  function sortByToday(timeData){



    if (timeData.length > 0){

      var temp = []
    
     for (var i = 0; i < 24; i++){
      temp.push({name: 'NA', uv: 0})
     }
    


     var sinceNumber = Math.round(Moment.duration(today.getTime()).asHours() - 23)


    for (var k = 0; k < timeData.length; k++){
    

    var index = Math.round(Moment.duration(timeData[k].date.getTime()).asHours()) - sinceNumber


    temp[index].uv+=timeData[k].amount
    


    }
    
    setData(temp)
    
      } 


// if (timeData.length > 0){

//   var temp = []

//  for (var i = 0; i < 24; i++){
//   temp.push({name: 'NA', uv: 0})
//  }

// for (var k = 0; k < timeData.length; k++){

// var hour = timeData[k].date.getHours()

// temp[hour].uv+=timeData[k].amount


// }

// setData(temp)

//   } 

}

  


  function sortByLastWeek(timeData){

    if (timeData.length > 0){

      var temp = []
    
     for (var i = 0; i < 7; i++){
      temp.push({name: 'NA', uv: 0})
     }
    


     var sinceNumber = Math.round(Moment.duration(today.getTime()).asDays() - 6)


    for (var k = 0; k < timeData.length; k++){
    

    var index = Math.round(Moment.duration(timeData[k].date.getTime()).asDays()) - sinceNumber


    temp[index].uv+=timeData[k].amount
    


    }
    
    setData(temp)
    
      } 
    
  }


  function sortByLastMonth(timeData){

    if (timeData.length > 0){

      var temp = []
    
     for (var i = 0; i < 31; i++){
      temp.push({name: 'NA', uv: 0})
     }
    

     var sinceNumber = Math.round(Moment.duration(today.getTime()).asDays() - 30)

    for (var k = 0; k < timeData.length; k++){
    

      var index = Math.round(Moment.duration(timeData[k].date.getTime()).asDays()) - sinceNumber


      temp[index].uv+=timeData[k].amount
    

    }
    
    setData(temp)
    
      }
    
  }


  function sortByLast3Months(timeData){


    if (timeData.length > 0){

      var temp = []
    
     for (var i = 0; i < 93; i++){
      temp.push({name: 'NA', uv: 0})
     }
    

     var sinceNumber = Math.round(Moment.duration(today.getTime()).asDays() - 92)

    for (var k = 0; k < timeData.length; k++){
    

      var index = Math.round(Moment.duration(timeData[k].date.getTime()).asDays()) - sinceNumber


      temp[index].uv+=timeData[k].amount
    
      
    }
    
    setData(temp)
    
      }


    
  }

  function sortByLastYear(timeData){

   
    if (timeData.length > 0){

      var temp = []
    
     for (var i = 0; i < 12; i++){
      temp.push({name: 'NA', uv: 0})
     }
    

     var sinceNumber = Math.round(Moment.duration(today.getTime()).asMonths() - 11)

    for (var k = 0; k < timeData.length; k++){
    

      var index = Math.round(Moment.duration(timeData[k].date.getTime()).asMonths()) - sinceNumber


      temp[index].uv+=timeData[k].amount
    
      
    }
    
    setData(temp)
    
      }


    
  }


useEffect(() => {

  const q = query(collection(db, "userInfo"), where("user", "==", currentUser.uid) )

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
  querySnapshot.forEach((doc) => {


    try {

      if (doc.data().onboarding.confirmIdentityStarted){
        window.dataLayer.push({'event': 'create-button-clicked'})
      }
      
    } catch (e){

    }

  });

})

}, [])




useEffect(() => {
    // test
  // window.dataLayer.push({'event': 'create-button-clicked'})

  const q = query(collection(db, "payments"), where("user", "==", currentUser.uid), orderBy("date", "desc"), limit(3) )

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
  const tempData = [];
  querySnapshot.forEach((doc) => {
    tempData.push(doc.data())
  });

setPayments(tempData)

})


const q1 = query(collection(db, "debtors"), where("user", "==", currentUser.uid), orderBy("date", "desc"), limit(3) )

const unsubscribe1 = onSnapshot(q1, (querySnapshot1) => {
const tempData = [];
querySnapshot1.forEach((doc) => {
  tempData.push(doc.data())
});

setContracts(tempData)

})


  setLoading(false)


  }, [])



  useEffect(() => {

      const q = query(collection(db, "payments"), where("user", "==", currentUser.uid), where("status", "==", "Paid"), orderBy("datePaidNum"))


    var tempData=[]

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      

      if (currentTimeSelect === "Today"){

        querySnapshot.forEach((doc) => {


       if (doc.data().datePaidNum.toDate() > yesterday){
        
        tempData.push({ amount: doc.data().amountNum, type: doc.data().fundsLocation, date: doc.data().datePaidNum.toDate() })
       }


      })

      sortByToday(tempData)

    } else if (currentTimeSelect === "Last Week"){
      setStartDate(lastWeekFormat)

      querySnapshot.forEach((doc) => {

        if (doc.data().datePaidNum.toDate() > lastWeek){
          tempData.push({ amount: doc.data().amountNum, type: doc.data().fundsLocation, date: doc.data().datePaidNum.toDate()})
         }

      })

      sortByLastWeek(tempData)

    } else if (currentTimeSelect === "Last Month"){
      setStartDate(lastMonthFormat)

      querySnapshot.forEach((doc) => {

        if (doc.data().datePaidNum.toDate() > lastMonth){
          tempData.push({ amount: doc.data().amountNum, type: doc.data().fundsLocation, date: doc.data().datePaidNum.toDate()})
         }

      })

      sortByLastMonth(tempData)

    } else if (currentTimeSelect === "Last 3 Months"){
      setStartDate(last3MonthsFormat)

      querySnapshot.forEach((doc) => {

        if (doc.data().datePaidNum.toDate() > last3Months){
          tempData.push({ amount: doc.data().amountNum, type: doc.data().fundsLocation, date: doc.data().datePaidNum.toDate()})
         }

      })

      sortByLast3Months(tempData)

    } else if (currentTimeSelect === "Last Year"){
      setStartDate(lastYearFormat)

      querySnapshot.forEach((doc) => {

        if (doc.data().datePaidNum.toDate() > lastYear){
          tempData.push({ amount: doc.data().amountNum, type: doc.data().fundsLocation, date: doc.data().datePaidNum.toDate()})
         }

      })

      sortByLastYear(tempData)

    }

    var totalBalance = 0
    var accountBalance = 0
    var escrowBalance = 0
    var payouts = 0

        for (var i = 0; i < tempData.length; i++){
          totalBalance+=tempData[i].amount
  
          if (tempData[i].type == "Account"){
            accountBalance+=tempData[i].amount
          } else if (tempData[i].type == "Escrow"){
            escrowBalance+=tempData[i].amount
          } else if (tempData[i].type == "Payout"){
            payouts+=tempData[i].amount
          }
  
        }

        setTotalBalance(totalBalance)
        setAccountBalance(accountBalance)
        setEscrowBalance(escrowBalance)
        setPayouts(payouts)

    } )

  }, [currentTimeSelect])

  

  return <PlasmicDashboard root={{ ref }} {...props}
  debtors={"Debtors"}
  totalCollected={"Total Collected"}
  vertStack={loading && {
    children: <LoadingSpinner3/>
  }}
  paymentCardStack={{
    children: payments.map( x => {
      return <DashboardPaymentsCard amount={x.oldAmount ? x.oldAmount : x.amountNum.toLocaleString('en-US', {style: 'currency', currency: 'USD', })} client={x.clientCompany} paidStatus={x.status} date={format(x.date.toDate(), "MMM do")} paymentVoided={x.paymentVoided}/>
    })
  }}
  contractCardStack={{
    children: contracts.map( x => {
      return <DashboardContractsCard amount={x.debtAmount} client={x.debtorName} status={x.status} date={format(x.date.toDate(), "MMM do")}/>
    })
  }}
  accountBalance={accountBalance.toLocaleString('en-US', {style: 'currency', currency: 'USD', }) } 
  escrowBalance={escrowBalance.toLocaleString('en-US', {style: 'currency', currency: 'USD', }) } 
  payouts={payouts.toLocaleString('en-US', {style: 'currency', currency: 'USD', }) }
  totalBalance={totalBalance.toLocaleString('en-US', {style: 'currency', currency: 'USD', }) }
  endDate={todayFormat}
  startDate={startDate}
  amountStack={{
    wrap: node => !timeSelectPostClick ? node : null,
  }}
  graphStack={{
    wrap: node => !timeSelectPostClick ? node : null,
    props: {
      children:
    <>
    <ResponsiveContainer width="100%" height="98%">
    <LineChart width={600} height={300} data={data}>
    <Tooltip content={<CustomTooltip />}/>
  <Line connectNulls type="monotone" dataKey="uv" stroke="#3555FF" fill="#3555FF" strokeWidth={2} dot={false}/>  
  </LineChart>
    </ResponsiveContainer>
    </>
    }
  }}
  graphLine={{
    wrap: node => !timeSelectPostClick ? node : null,
  }} 
  viewDetails={{
    onClick: () => history.push('/paymentslist')
  }}
  viewDetails2={{
    onClick: () => {
    
      if (currentUser.uid === "cg0KYF8bHvSuyK7BFwPjCrwm0Dp2"){
        history.push('/alldebtors')
      } else {
        history.push('/debtorslist')
      }
      
    
    }
  }}
  /// one idea to fix this without having to go into the techincals of fixing the free vs auto is to just craete a variant of the graphStack that uses negative margins whenever the 
  // postClick is true
  timeSelectStack={{
    postClick: timeSelectPostClick
  }}
  
  />;
}

const Dashboard = React.forwardRef(Dashboard_);

export default Dashboard;
