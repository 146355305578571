// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState} from "react";
import { PlasmicLogin2 } from "./plasmic/collect_wise_draft/PlasmicLogin2";
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import validator from 'validator'
import LoadingSpinner from "./LoadingSpinner";

function Login2_(props, ref) {
  const [emailContent, setEmailContent] = useState('')
  const [passwordContent, setPasswordContent] = useState('')
  const [invalidEmailError, setInvalidEmailError] = useState(false)
  const [incorrectLoginError, setIncorrectLoginError] = useState(false)
  const [customError, setCustomError] = useState(false)
  const { login, currentUser } = useAuth()
  const [errorContent, setErrorContent] = useState('Failed to login')
  const [loading, setLoading] = useState(false)
  const history = useHistory()


  async function handleSubmit(){
    setInvalidEmailError(false)
    setIncorrectLoginError(false)
    setCustomError(false)
    
    if (!(validator.isEmail(emailContent))){
      setInvalidEmailError(true)
    } else{
    
    try {
      var email = emailContent
      var password = passwordContent
    
      setLoading(true)
      await login(email, password)
    
      history.push("/")

    } catch (e){
      setLoading(false)
    
      if (e.code === "auth/user-not-found"){
        setIncorrectLoginError(true)
      } else{
        setCustomError(true)
      }
    
    }
    
    }
    
    }
    


  return loading ? <LoadingSpinner/> : <PlasmicLogin2 root={{ ref }} {...props} 
  loginButton={{
    onClick: () => handleSubmit(),
    disabled: loading
  }}
  loginEmailInput={{
    danger: invalidEmailError,
    value: emailContent,
    onChange: (e) => {
      setEmailContent(e.target.value);
    }

  }}
  loginPasswordInput={{
    value: passwordContent,
    onChange: (e) => {
      setPasswordContent(e.target.value);
    }

  }}
  invalidEmailText={{
    wrap: node => invalidEmailError ? node : null
  }}
  incorrectLoginText={{
    wrap: node => incorrectLoginError ? node : null
  }}
  customErrorInstance={{
    wrap: node => customError ? node : null,
    props: {
      customErrorText: errorContent
    }
  }}
  signupText={{
   onClick: () => history.push("/signup") 
  }}
  forgotPassword={{
    onClick: () => history.push("/forgotpassword")
  }}
  />;
}

const Login2 = React.forwardRef(Login2_);

export default Login2;
