import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyC53pxD8Zf29MdJw4d4STrSSL0vXuSWQNQ",
  authDomain: "collectwise-dev.firebaseapp.com",
  projectId: "collectwise-dev",
  storageBucket: "collectwise-dev.appspot.com",
  messagingSenderId: "135836786014",
  appId: "1:135836786014:web:a932cd566df19fbbeb03cf",
  measurementId: "G-TY06R9XLWP"
};

const app = initializeApp(firebaseConfig);



export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export default app;