// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, { useEffect, useState } from "react";
import { PlasmicGetStarted } from "./plasmic/collect_wise_draft/PlasmicGetStarted";
import {useHistory} from "react-router-dom"
import { useAuth } from '../contexts/AuthContext';
import { v4 } from "uuid";
import { collection, addDoc, doc, setDoc, serverTimestamp, updateDoc, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase"


function GetStarted_(props, ref) {
 const history = useHistory()
 const { isVerified, currentUser } = useAuth();
  const [merchantName, setMerchantName] = useState("")

 const handleBeforeUnload = (e) => {
  e.preventDefault();
  const message =
    "Are you sure you want to leave? All provided data will be lost.";
  e.returnValue = message;
  return message;
}


  return <PlasmicGetStarted root={{ ref }} {...props} 
  merchantName={{
    value: merchantName,
    onChange: (e) => {
      setMerchantName(e.target.value)
    }
  }}
  nextButton={{
    onClick: async () => {
      
      window.addEventListener("beforeunload", handleBeforeUnload);

      history.push("/")

      const q = query(
        collection(db, "userInfo"),
        where("user", "==", currentUser.uid)
      );

      var userInfoDocId = ""

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        userInfoDocId = doc.id
      })

      updateDoc(doc(db, 'userInfo', userInfoDocId), {
        merchantName: merchantName
      })

      window.removeEventListener("beforeunload", handleBeforeUnload);


    }
  }} />;
}

const GetStarted = React.forwardRef(GetStarted_);

export default GetStarted;
