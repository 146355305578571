// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useEffect}  from "react";
import { PlasmicOnboardingReviewBiz } from "./plasmic/collect_wise_draft/PlasmicOnboardingReviewBiz";
import {useAuth} from "../contexts/AuthContext"
import axios from "axios"
import {db} from "../firebase"
import { collection, addDoc, doc, setDoc, serverTimestamp, updateDoc, query, where, getDocs } from "firebase/firestore";
import {useHistory} from 'react-router-dom'
import { v4 } from "uuid";
import LoadingSpinnerWithText from "./LoadingSpinnerWithText";
import LoadingSpinner2WithText from "./LoadingSpinner2WithText";
import { nextTick } from "process";
import { config } from './constants'


/// ToDo: Test out if the industry codes works with the onboarding submission


/// test this and then add the same one to the page for personal users. 


function OnboardingReviewBiz_(props, ref) {
  const {setupDetails, businessDetails, controllers, publicToken, metaData, currentUser, setTempUrl, testLoad, setTestLoad, onboardModal, setOnboardModal} = useAuth()
  const [loading, setLoading] = useState(false)
  const [edgeLoading, setEdgeLoading] = useState(false)
  const history = useHistory()

  const [freeCheck, setFreeCheck] = useState(false)
  const [starterCheck, setStarterCheck] = useState(false)
  const [premiumCheck, setPremiumCheck] = useState(false)
  const [growthCheck, setGrowthCheck] = useState(false)
  const [newPlan, setNewPlan] = useState('')  
  const [errorText, setErrorText] = useState(false)
  const [confirmed, setConfirmed] = useState(true)
  const [pushs, setPush] = useState('')

  const GETTRX_URL = config.GETTRX_URL

  const FLAT_RATE_OFFERID = config.FLAT_RATE_OFFERID

  const INTERCHANGE_PLUS_OFFERID = config.INTERCHANGE_PLUS_OFFERID

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };

  useEffect(() => {

    window.addEventListener("beforeunload", handleBeforeUnload);

    const script = document.createElement('script')
    script.id = 'edgeagree-sdk'
    script.src = `${GETTRX_URL}/sdk/edgepay-agree.2.0.1.js`
    script.async = true
      
      document.body.appendChild(script)


      return () => {
        document.body.removeChild(script)
      }      
  
  }, [])


  useEffect(() => {

    const script = document.createElement('script')
    script.src = "https://cdn.withpersona.com/dist/persona-v4.7.1.js"
    script.async = true
      
      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }      
  
  }, [])


  var states = [
    ['Arizona', 'AZ'],
    ['Alabama', 'AL'],
    ['Alaska', 'AK'],
    ['Arkansas', 'AR'],
    ['California', 'CA'],
    ['Colorado', 'CO'],
    ['Connecticut', 'CT'],
    ['Delaware', 'DE'],
    ['Florida', 'FL'],
    ['Georgia', 'GA'],
    ['Hawaii', 'HI'],
    ['Idaho', 'ID'],
    ['Illinois', 'IL'],
    ['Indiana', 'IN'],
    ['Iowa', 'IA'],
    ['Kansas', 'KS'],
    ['Kentucky', 'KY'],
    ['Louisiana', 'LA'],
    ['Maine', 'ME'],
    ['Maryland', 'MD'],
    ['Massachusetts', 'MA'],
    ['Michigan', 'MI'],
    ['Minnesota', 'MN'],
    ['Mississippi', 'MS'],
    ['Missouri', 'MO'],
    ['Montana', 'MT'],
    ['Nebraska', 'NE'],
    ['Nevada', 'NV'],
    ['New Hampshire', 'NH'],
    ['New Jersey', 'NJ'],
    ['New Mexico', 'NM'],
    ['New York', 'NY'],
    ['North Carolina', 'NC'],
    ['North Dakota', 'ND'],
    ['Ohio', 'OH'],
    ['Oklahoma', 'OK'],
    ['Oregon', 'OR'],
    ['Pennsylvania', 'PA'],
    ['Rhode Island', 'RI'],
    ['South Carolina', 'SC'],
    ['South Dakota', 'SD'],
    ['Tennessee', 'TN'],
    ['Texas', 'TX'],
    ['Utah', 'UT'],
    ['Vermont', 'VT'],
    ['Virginia', 'VA'],
    ['Washington', 'WA'],
    ['West Virginia', 'WV'],
    ['Wisconsin', 'WI'],
    ['Wyoming', 'WY'],
    ['Guam', 'GU'],
];



  


async function handleBeneficialOwners(customerUrlJSON) {

  var owners = []
  var allOwnersVerified = true


  for (var i = 0; i < controllers.length; i++){
    if (controllers[i].controllerType === 'owner' || controllers[i].controllerType === 'both'){
     

  var reqBody = JSON.stringify({
    firstName: controllers[i].firstName,
    lastName: controllers[i].lastName,
    dateOfBirth: controllers[i].dob.getFullYear() + "-" + ("0" + (Number(controllers[i].dob.getMonth())+ 1)).slice(-2) + "-" + ("0" + controllers[i].dob.getDate()).slice(-2),
    ssn: controllers[i].ssn,
    address: {
      address1: controllers[i].address1,
      city: controllers[i].city,
      stateProvinceRegion: controllers[i].state,
      country: controllers[i].country,
      postalCode: controllers[i].zip
    }
  });


  // try making ownerInfo a json object containing the owner url and status

  
  const resOwnerInfo = await axios({
    method: "POST",
    url: `${config.endpoint}/onboardingFunctions-createBeneficialOwner`,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${await currentUser.getIdToken()}`
    },
    data: {
    reqBody: reqBody,
    customerUrl: customerUrlJSON
    }
  })

  var ownerUrl = resOwnerInfo.data

  
 

  var ownerUrlJSON = JSON.stringify({
    ownerUrl: ownerUrl
  })

  const resOwnerVerification = await axios({
    method: "POST",
    url: `${config.endpoint}/onboardingFunctions-getOwnerVerificationStatus`,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${await currentUser.getIdToken()}`
    },
    data: {
    ownerUrl: ownerUrlJSON
    }
  })
  
var ownerVerification = resOwnerVerification.data
  

  if (ownerVerification === 'document' || ownerVerification === 'incomplete'){
    allOwnersVerified = false; 

  } 


  owners.push({ownerUrl: ownerUrl, ownerVerification: ownerVerification, name: controllers[i].firstName + " " + controllers[i].lastName })


    }
  }

// returns a JSON containing a boolean indicating if allOwnersVerified, as well as an array that has each owner's url and individual verification status

return ({owners: owners, allOwnersVerified: allOwnersVerified})


}


async function handleDwollaBoarding(seon, ip) {

  var controller = {}


  for (var i = 0; i < controllers.length; i++){
    if (controllers[i].controllerType === 'manager' || controllers[i].controllerType === 'both'){
      controller = controllers[i]
      break;
    }
  }

  var businessType = ''

  switch(setupDetails.businessStructure){
    case "single_member_llc":
      businessType = 'llc'
      break;
    case "multi_member_llc":
      businessType = 'llc'
      break;
    case "private_corporation":
      businessType = 'corporation'
      break;
    case "private_partnership":
      businessType = 'partnership'
      break;
  }
 

  var reqBody = JSON.stringify({
    firstName: businessDetails.firstName,
    lastName: businessDetails.lastName,
    email: currentUser.email,
    // email: "sean1107obrien+998320101235510@gmail.com",
    type: "business",
    address1: businessDetails.address1,
    city: businessDetails.city,
    state: businessDetails.state,
    postalCode: businessDetails.zip,
    controller: {
      firstName: controller.firstName,
      lastName: controller.lastName,
      title: controller.jobTitle,
      dateOfBirth: controller.dob.getFullYear() + "-" + ("0" + (Number(controller.dob.getMonth())+ 1)).slice(-2) + "-" + ("0" + controller.dob.getDate()).slice(-2),
      ssn: controller.ssn.slice(-4),
      address: {
        address1: controller.address1,
        city: controller.city,
        stateProvinceRegion: controller.state,
        postalCode: controller.zip,
        country: controller.country,
      },
    },
    businessClassification: businessDetails.industry,
    businessType: businessType,
    businessName: businessDetails.legalBusinessName,
    ein: businessDetails.ein,
  });

 
  
  const resCustomerUrl = await axios({
    method: "POST",
    url: `${config.endpoint}/onboardingFunctions-createBusinessWithController`,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${await currentUser.getIdToken()}`
    },
    data: {
    reqBody: reqBody
    }
  })
  
  var customerUrl = resCustomerUrl.data
 

  var customerUrlJSON = JSON.stringify({
    customerUrl: customerUrl
  })

  const resVerificationStatus = await axios({
    method: "POST",
    url: `${config.endpoint}/onboardingFunctions-getVerificationStatus`,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${await currentUser.getIdToken()}`
    },
    data: {
      customerUrl: customerUrlJSON
    }
  })


  var businessVerificationStatus = resVerificationStatus.data.status



  var tokenResponseJSON = JSON.stringify({
    account_id: metaData.account_id,
    bankName: metaData.account.name,
    public_token_object : {
      public_token : publicToken
    },
    customerUrl: customerUrl,
  })


  const resTokenResponse = await axios({
    method: "POST",
    url: `${config.endpoint}/achFunctions-tokenExchange2`,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${await currentUser.getIdToken()}`
    },
    data: {
      tokenResponseJSON: tokenResponseJSON
    }
  })


  var tokenResponse = resTokenResponse.data

  
  /// function to handle benefecial owners code. We need to implement protected routes based on this. 

  var beneficialOwnersStatus = await handleBeneficialOwners(customerUrlJSON)


  const resCertifyCustomer = await axios({
    method: "POST",
    url: `${config.endpoint}/onboardingFunctions-certifyCustomerOwnership`,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${await currentUser.getIdToken()}`
    },
    data: {
      customerUrl: customerUrlJSON
    }
  })

  if (businessVerificationStatus === 'suspended') {

    history.push("/account-unapproved")

  } else {

    var owners = []

    var ownerCount = 0

    for (var i = 0; i < controllers.length; i++) {

      if (controllers[i].controllerType === "both" || controllers[i].controllerType === "owner"){

        ownerCount++

        owners.push({
          "FirstName": controllers[i].firstName,
          "LastName": controllers[i].lastName,
          "SSN": controllers[i].ssn.replace(/-/g, ""),
          "DateOfBirth": ("0" + (Number(controllers[i].dob.getMonth())+ 1)).slice(-2) + "-" + ("0" + controllers[i].dob.getDate()).slice(-2) + "-" + controllers[i].dob.getFullYear(),
          "Email":  controllers[i].email,
          "Address": controllers[i].address1,
          "City": controllers[i].city,
          "State": controllers[i].state,
          "Zip": controllers[i].zip,
          // may need to fix this for owners in different countries
          "Country": controllers[i].country === "US" ? "USA" : controllers[i].country,
          "Title": "Owner",
         "Percentage": controllers[i].ownership + ""
        })

      }

    }


    const propay = await axios({
      method: "POST",
      url: `${config.endpoint}/onboardingFunctions-propayBizBoarding`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${await currentUser.getIdToken()}`
      },
      data: {
      metaData: metaData,
      public_token_object : {
        public_token : publicToken
      },
      firstName: controller.firstName,
      lastName: controller.lastName,
      legalBusinessName: businessDetails.legalBusinessName,
      displayBusinessName: businessDetails.displayBusinessName,
      dob: ("0" + (Number(controller.dob.getMonth())+ 1)).slice(-2) + "-" + ("0" + controller.dob.getDate()).slice(-2) + "-" + controller.dob.getFullYear(),
      ein: businessDetails.ein,
      ssn: controller.ssn,
      email: currentUser.email,
      phoneNumber: controller.phoneNumber.replace(/[^\x00-\x7F]/g, ""),
      website: businessDetails.website,
      productDescription: businessDetails.productDescription,
      monthlyVolume: Number(businessDetails.monthlyVolume.substring(1).replace(/,/g, "")),
      averageTicket: Number(businessDetails.averageTicket.substring(1).replace(/,/g, "")),
      highestTicket: Number(businessDetails.highestTicket.substring(1).replace(/,/g, "")),
      address1: businessDetails.address1,
      city: businessDetails.city,
      state: businessDetails.state,
      zip: businessDetails.zip,
      owners: owners,
      ownerCount: ownerCount,
      controllerAddress: {
        "Address1": controller.address1,
        "City": controller.city,
        "State": controller.state,
        "Country": "USA",
        "Zip": controller.zip
        },
      ip: ip,
      businessType: businessType,
      timestamp: new Date(),
      suite: businessDetails.suite
      }
    })


    /// TODO; figure out why there was a 65 status with that request (the manual one got it too, so maybe it's something to do with sandbox), implement the following below, add protected route for propayApproval, implement propay boarding for biz accounts


  var docId = v4()

  
  const q = query(collection(db, "verifications"), where("user", "==", currentUser.uid))
 
  const querySnapshot = await getDocs(q);


  await setDoc(doc(db, "userInfo", docId), {
    user: currentUser.uid,
    email: currentUser.email,
    firstName: businessDetails.firstName,
    fundingSource: tokenResponse,
    verificationStatus: businessVerificationStatus,
    // if the identity verification document doesn't exist, then that means the identity has not been verified yet
    identityVerification: querySnapshot.empty ? false : true,
    accountMetaData: metaData,
    dwollaUrl: customerUrl,
    merchantName: businessDetails.displayBusinessName,
    accountType: setupDetails.accountType,
    owners: beneficialOwnersStatus.owners,
    allOwnersVerified: beneficialOwnersStatus.allOwnersVerified,
    industryCode: businessDetails.industry,
    businessType: setupDetails.businessType,
    onboarded: true,
    invoiceNum: 1,
    state: businessDetails.state,
    city: businessDetails.city,
    country: setupDetails.country,
    plan: newPlan,
    date: new Date(),
    customPrice: 0,
    customLimit: 0,
    monthlyAmount: 0,
    cardApproval: false,
    zip: businessDetails.zip,
    commodityCode: businessDetails.commodityCode,
    lastName: businessDetails.lastName,
    address: businessDetails.address1,
    phone: controller.phoneNumber,
    productDescription: businessDetails.productDescription,
    propayApproval: propay.data.Status === '00' ? true : false,
    propayCode: propay.data.Status,
    /// may also need to add password or additional info depending on what the onboarding team says
    propayNumber: propay.data.AccountNumber,
    heartlandPublicKey : "",
    heartlandSecretKey : "",
    fraudCount: 0
  });

  console.log(1)

  if (propay.data.Status === '66') {

    // handle the situation where user needs to provide additional docs to propay
    // send an email to yourself (or to the user) about uploading the provided docs if they haven't already
    await axios({
      method: "POST",
      url: `${config.endpoint}/sendInternalEmail`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${await currentUser.getIdToken()}`
      },
      data: {
        message: `Hi Sean,\n\nThe merchant with email ${currentUser.email} just onboarded with a ProPay status of ${propay.data.Status}`
      }
    })

    if (businessVerificationStatus === 'verified') {

      history.push("/onboarding-businessdetails-applicationreceived");

      return;

    }
    
  } else if (propay.data.Status !== '00') {
    /// handle status for nonsuccessfull API request, like maybe sending an email to you or displaying an alert message

    await axios({
      method: "POST",
      url: `${config.endpoint}/sendInternalEmail`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${await currentUser.getIdToken()}`
      },
      data: {
        message: `Hi Sean,\n\nThe merchant with email ${currentUser.email} just onboarded with a ProPay status of ${propay.data.Status}`
      }
    })

    if (businessVerificationStatus === 'verified') {

      history.push("/onboarding-businessdetails-applicationreceived");

      return;

    }
    
  }

  console.log(2)

  if (businessVerificationStatus === 'document'){



    var linkNames = resVerificationStatus.data._links

 
    if (linkNames.hasOwnProperty("verify-with-document")) {

      setTempUrl({customerUrl: customerUrl, docId: docId, verifyType: "verify-with-document", owners: beneficialOwnersStatus.owners})

        history.push("/onboarding-businessdetails-document")

        updateDoc(doc(db, 'userInfo', docId), {
          verifyType: "verify-with-document"
        })


    } else if (linkNames.hasOwnProperty("verify-business-with-document")) {

      setTempUrl({customerUrl: customerUrl, docId: docId, verifyType: "verify-business-with-document", owners: beneficialOwnersStatus.owners})

        history.push("/onboarding-businessdetails-document")

        updateDoc(doc(db, 'userInfo', docId), {
          verifyType: "verify-business-with-document"
        })

    } else if (linkNames.hasOwnProperty("verify-controller-and-business-with-document")) {

      setTempUrl({customerUrl: customerUrl, docId: docId, verifyType: "verify-controller-and-business-with-document", owners: beneficialOwnersStatus.owners})


        history.push("/onboarding-businessdetails-document")

        updateDoc(doc(db, 'userInfo', docId), {
          verifyType: "verify-controller-and-business-with-document"
        })

    }

  } else if (businessVerificationStatus === 'retry') {

    setTempUrl({customerUrl: customerUrl, docId: docId, controller: controller, owners: beneficialOwnersStatus.owners})
    
    console.log(3)

      history.push("/onboarding-businessdetails-retry")


  } else {


    if (beneficialOwnersStatus.allOwnersVerified){

        history.push("/business-approved")


    } else {

    

      for (var i = 0; i < beneficialOwnersStatus.owners.length; i++){
       
       

        if (beneficialOwnersStatus.owners[i].ownerVerification !== "verified"){

         

          setTempUrl({ownerUrl: beneficialOwnersStatus.owners[i].ownerUrl, name: beneficialOwnersStatus.owners[i].name, docId: docId, owners: beneficialOwnersStatus.owners, customerUrl: customerUrl })

          if (beneficialOwnersStatus.owners[i].ownerVerification === "incomplete"){
            
       
              history.push("/verify-owners")
          


            break;
          } else {

              history.push("/verify-owners-document")

            break;
          }

          
        

        }

      } 

     

    }
   


  }

  window.removeEventListener("beforeunload", handleBeforeUnload);


}
}

 

  return (
  <>
   { loading ? <LoadingSpinnerWithText text={'Processing Application - this may take up to 20 seconds'}/> : <PlasmicOnboardingReviewBiz root={{ ref }} {...props} 
  vertStack={ onboardModal ? { children: <></>} : edgeLoading && {
      children: <LoadingSpinner2WithText text={'Validating Information'}/>
    }
  }
  freeCheck={{
    isChecked: freeCheck,
    onChange: (e) => {
      setNewPlan('Free')
      setFreeCheck(!freeCheck)
      setStarterCheck(false)
      setPremiumCheck(false)
      setGrowthCheck(false)
    },
  }}
  starterCheck={{
    isChecked: starterCheck,
    onChange: (e) => {
      setNewPlan('Starter')
      setStarterCheck(!starterCheck)
      setPremiumCheck(false)
      setGrowthCheck(false)
      setFreeCheck(false)
    },
  }}
  premiumCheck={{
    isChecked: premiumCheck,
    onChange: (e) => {
      setNewPlan('Premium')
      setPremiumCheck(!premiumCheck)
      setStarterCheck(false)
      setGrowthCheck(false)
      setFreeCheck(false)
    },
  }}
  growthCheck={{
    isChecked: growthCheck,
    onChange: (e) => {
      setNewPlan('Growth')
      setGrowthCheck(!growthCheck)
      setStarterCheck(false)
      setPremiumCheck(false)
      setFreeCheck(false)
    },
  }}
  errorText={{
    wrap: node => errorText ? node : null
  }}
  submitButton={{
    onClick: async () => {
    

      if (newPlan === ''){
        setErrorText(true)
      } else {

      setEdgeLoading(true)



      var controller = {}

      var businessPrinciples = []

      for (var i = 0; i < controllers.length; i++){
        if (controllers[i].controllerType === 'manager' || controllers[i].controllerType === 'both'){
          controller = controllers[i]
          break;
        }
      }


      for (var i = 0; i < controllers.length; i++){
        
       // set business principles

      }

// starts here

// adjust to the person that needs to manually link their bank account.
if(currentUser.email === "sidney@mishe.co") {

  const ip = await axios.get('https://geolocation-db.com/json/')

  var businessType = ''

  switch(setupDetails.businessStructure){
    case "single_member_llc":
      businessType = 'llc'
      break;
    case "multi_member_llc":
      businessType = 'llc'
      break;
    case "private_corporation":
      businessType = 'corporation'
      break;
    case "private_partnership":
      businessType = 'partnership'
      break;
  }

  var controller = {}

  for (var i = 0; i < controllers.length; i++){
    if (controllers[i].controllerType === 'manager' || controllers[i].controllerType === 'both'){
      controller = controllers[i]
      break;
    }
  }


  var owners = []

  var ownerCount = 0

  for (var i = 0; i < controllers.length; i++) {

    if (controllers[i].controllerType === "both" || controllers[i].controllerType === "owner"){

      ownerCount++

      owners.push({
        "FirstName": controllers[i].firstName,
        "LastName": controllers[i].lastName,
        "SSN": controllers[i].ssn.replace(/-/g, ""),
        "DateOfBirth": ("0" + (Number(controllers[i].dob.getMonth())+ 1)).slice(-2) + "-" + ("0" + controllers[i].dob.getDate()).slice(-2) + "-" + controllers[i].dob.getFullYear(),
        "Email":  controllers[i].email,
        "Address": controllers[i].address1,
        "City": controllers[i].city,
        "State": controllers[i].state,
        "Zip": controllers[i].zip,
        // may need to fix this for owners in different countries
        "Country": controllers[i].country === "US" ? "USA" : controllers[i].country,
        "Title": "Owner",
       "Percentage": controllers[i].ownership + ""
      })

    }

  }

  setLoading(true)

console.log({
  // just manually set the publicToken auth in bankingdetails to an object that contains these fields
accountNum: publicToken.accountNum,
routingNum: publicToken.routingNum,
bankName: publicToken.bankName,
accountType: publicToken.accountType,
//
firstName: controller.firstName,
lastName: controller.lastName,
legalBusinessName: businessDetails.legalBusinessName,
displayBusinessName: businessDetails.displayBusinessName,
dob: ("0" + (Number(controller.dob.getMonth())+ 1)).slice(-2) + "-" + ("0" + controller.dob.getDate()).slice(-2) + "-" + controller.dob.getFullYear(),
ein: businessDetails.ein,
ssn: controller.ssn,
email: currentUser.email,
phoneNumber: controller.phoneNumber.replace(/[^\x00-\x7F]/g, ""),
website: businessDetails.website,
productDescription: businessDetails.productDescription,
monthlyVolume: Number(businessDetails.monthlyVolume.substring(1).replace(/,/g, "")),
averageTicket: Number(businessDetails.averageTicket.substring(1).replace(/,/g, "")),
highestTicket: Number(businessDetails.highestTicket.substring(1).replace(/,/g, "")),
address1: businessDetails.address1,
city: businessDetails.city,
state: businessDetails.state,
zip: businessDetails.zip,
owners: owners,
ownerCount: ownerCount,
controllerAddress: {
  "Address1": controller.address1,
  "City": controller.city,
  "State": controller.state,
  "Country": "USA",
  "Zip": controller.zip
  },
ip: ip.data.IPv4,
businessType: businessType,
timestamp: new Date(),
suite: businessDetails.suite
}
)

  
  const propay = await axios({
    method: "POST",
    url: `${config.endpoint}/onboardingFunctions-propayBizBoardingAlternate`,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${await currentUser.getIdToken()}`
    },
    data: {
      // just manually set the publicToken auth in bankingdetails to an object that contains these fields
    accountNum: publicToken.accountNum,
    routingNum: publicToken.routingNum,
    bankName: publicToken.bankName,
    accountType: publicToken.accountType,
    //
    firstName: controller.firstName,
    lastName: controller.lastName,
    legalBusinessName: businessDetails.legalBusinessName,
    displayBusinessName: businessDetails.displayBusinessName,
    dob: ("0" + (Number(controller.dob.getMonth())+ 1)).slice(-2) + "-" + ("0" + controller.dob.getDate()).slice(-2) + "-" + controller.dob.getFullYear(),
    ein: businessDetails.ein,
    ssn: controller.ssn,
    email: currentUser.email,
    phoneNumber: controller.phoneNumber.replace(/[^\x00-\x7F]/g, ""),
    website: businessDetails.website,
    productDescription: businessDetails.productDescription,
    monthlyVolume: Number(businessDetails.monthlyVolume.substring(1).replace(/,/g, "")),
    averageTicket: Number(businessDetails.averageTicket.substring(1).replace(/,/g, "")),
    highestTicket: Number(businessDetails.highestTicket.substring(1).replace(/,/g, "")),
    address1: businessDetails.address1,
    city: businessDetails.city,
    state: businessDetails.state,
    zip: businessDetails.zip,
    owners: owners,
    ownerCount: ownerCount,
    controllerAddress: {
      "Address1": controller.address1,
      "City": controller.city,
      "State": controller.state,
      "Country": "USA",
      "Zip": controller.zip
      },
    ip: ip.data.IPv4,
    businessType: businessType,
    timestamp: new Date(),
    suite: businessDetails.suite
    }
  })

  var docId = v4()

  await setDoc(doc(db, "userInfo", docId), {
    user: currentUser.uid,
    email: currentUser.email,
    firstName: businessDetails.firstName,
    verificationStatus: "verified",
    // if the identity verification document doesn't exist, then that means the identity has not been verified yet
    identityVerification: true,
    accountMetaData: "NA",
    merchantName: businessDetails.displayBusinessName,
    accountType: setupDetails.accountType,
    industryCode: businessDetails.industry,
    businessType: setupDetails.businessType,
    onboarded: true,
    invoiceNum: 1,
    state: businessDetails.state,
    city: businessDetails.city,
    country: setupDetails.country,
    plan: newPlan,
    date: new Date(),
    customPrice: 0,
    customLimit: 0,
    monthlyAmount: 0,
    cardApproval: false,
    zip: businessDetails.zip,
    commodityCode: businessDetails.commodityCode,
    lastName: businessDetails.lastName,
    address: businessDetails.address1,
    phone: controller.phoneNumber,
    productDescription: businessDetails.productDescription,
    propayApproval: propay.data.Status === '00' ? true : false,
    propayCode: propay.data.Status,
    /// may also need to add password or additional info depending on what the onboarding team says
    propayNumber: propay.data.AccountNumber,
    heartlandPublicKey : "",
    heartlandSecretKey : "",
    fraudCount: 0
  });

  setLoading(false)

  history.push("/onboarding-businessdetails-applicationreceived");

  return;

}



const client = new window.Persona.Client({
  templateId: 'itmpl_k9AtjjCDLrTrBKWjz9oWXc5J',
  environment: config.version,
  // sandbox:
  // environmentId: 'env_JWYAhrDUhbE3J5L1YBmBaEmu',
  // prefill: {
  //   nameFirst: personalDetails.firstName,
  //   nameLast: personalDetails.lastName,
  //   birthdate: personalDetails.dob.getFullYear() + "-" + ("0" + (Number(personalDetails.dob.getMonth())+1)).slice(-2) + "-" + ("0" + personalDetails.dob.getDate()).slice(-2),
  //   addressStreet1: personalDetails.address1,
  //   addressCity: personalDetails.city,
  //   // addressSubdivision: "California",
  //   addressPostalCode: personalDetails.zip,
  //   countryCode: "US",
  //   // phoneNumber: "415-415-4154",
  //   emailAddress: currentUser.email
  // },
  onReady: () => {
    setEdgeLoading(false)
    setOnboardModal(true)
    client.open()
  },
  onComplete: async ({ inquiryId, status, fields }) => {

    var match = false

    // logic for verifying it
    for (var i = 0; i < controllers.length; i++){
        
      if(fields.birthdate.value === controllers[i].dob.getFullYear() + "-" + ("0" + (Number(controllers[i].dob.getMonth())+1)).slice(-2) + "-" + ("0" + controllers[i].dob.getDate()).slice(-2)){
        match = true
      }
    
     }
    

    if (status === "failed") {
      history.push("/onboarding-businessdetails-applicationreceived");
      
      setLoading(true)
      setOnboardModal(false)
    } 
    // need to switch back when navigating to production
    /// don't uncomment until you've verified this works
    // else if(!match){
      
    //   history.push("/onboarding-businessdetails-applicationreceived");
    //   // change this wording a little bit for the business users since ID will have to match the manager of the business. 
    //   // also send them an email here if this happens to explain in further detail
    //   alert("The ID that you provided does not match the manager or owner of your business. If this was a mistake, then please try signing up again (with a different email address), and make sure that the manager or owner of your business is the one uploading their ID.")

    //   setLoading(true)
    //   setOnboardModal(false)

    // } 
    else {  

      
      const q = query(collection(db, "userInfo"), where("user", "==", currentUser.uid))

      const querySnapshot = await getDocs(q);
      
      if (!(querySnapshot.empty)){
        // if document is already created, then simply update the identity verification
        await updateDoc(doc(db, "userInfo", querySnapshot.docs[0].id), {
          identityVerification: true
        });
      } else {
        // if document hasn't been created yet, then add an identity verification document for it to record when the user doc is created
        await addDoc(collection(db, "verifications"), {
          user: currentUser.uid,
          identityVerification: true
        })
      }
      
      setLoading(true)
      setOnboardModal(false)
    }
  },
  onCancel: () => {
    client.open()
  }
});



const date = new Date()

const session_id = v4()

try {

  const ip = await axios.get('https://geolocation-db.com/json/')

  handleDwollaBoarding("APPROVED", ip.data.IPv4)

} catch(e) {

  const ip = await axios.get('https://geolocation-db.com/json/')

  handleDwollaBoarding("APPROVED", "98.0.242.106")

}


/// this should hopefully work
// window.seon.config({
// host: "seondf.com",
// session_id: session_id,
// audio_fingerprint: true,
// canvas_fingerprint: true,
// webgl_fingerprint: true,
// onSuccess: function(message) {
//   console.log("success", message);
// },
// onError: function(message) {
//   console.log("error", message);
// }
// });

// const ip = await axios.get('https://geolocation-db.com/json/')
// window.seon.getBase64Session(async function(data) {
// if (data) {
//   console.log("Session payload", data);
// } else {
//   console.log("Failed to retrieve session data.");
// }



// const seon = await axios({
//   method: "POST",
//   url: `${config.endpoint}/seonFunctions-seonRegister`,
//   headers: {
//     "Content-Type": "application/json",
//     "Authorization": `Bearer ${await currentUser.getIdToken()}`
//   },
//   data: {
//     ip: ip.data.IPv4,
//     email: currentUser.email,
//     email_domain: currentUser.email.split('@').pop(),
//     user_fullname: controller.firstName + " " + controller.lastName,
//     user_firstname: controller.firstName,
//     user_lastname: controller.lastName,
//     user_id: currentUser.uid,
//     user_created: Math.floor(date.getTime() / 1000),
//     user_dob: controller.dob.getFullYear() + "-" + ("0" + (Number(controller.dob.getMonth())+1)).slice(-2) + "-" + ("0" + controller.dob.getDate()).slice(-2),
//     user_country: 'US',
//     user_city: controller.city,
//     user_region: controller.state,
//     user_zip: controller.zip,
//     user_street: controller.address1,
//     session_id: session_id,
//     session: data,
//     phone_number: controller.phoneNumber.replace(/[^\x00-\x7F]/g, "").replace(/-|\s/g, '')[0] === '1' || controller.phoneNumber.replace(/[^\x00-\x7F]/g, "").replace(/-|\s/g, '')[0] === '+' ? controller.phoneNumber.replace(/[^\x00-\x7F]/g, "").replace(/-|\s/g, '') : "1" + controller.phoneNumber.replace(/[^\x00-\x7F]/g, "").replace(/-|\s/g, ''),
//     payment_plan: newPlan === "Free" ? "Free" : "Paid"
//   }
// })

// console.log(seon.data)


// if (seon.data.data.state === "DECLINE"){
// history.push("/account-unapproved")
// } else{
// // consider implementing a state for when you should do manual review, but now this is fine. 
// handleDwollaBoarding(seon.data.data.state, ip.data.IPv4)
// }

// });

//ends here

}

    }
  }}
  /> }
  </>
  )

}

const OnboardingReviewBiz = React.forwardRef(OnboardingReviewBiz_);

export default OnboardingReviewBiz;
