// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicAddRoleModal } from "./plasmic/collect_wise_draft/PlasmicAddRoleModal";
import { useState } from "react";
import { useAuth } from "../contexts/AuthContext"
import axios from "axios";
import { config } from "./constants";
import LoadingSpinner from "./LoadingSpinner";

function AddRoleModal_(props, ref) {


  const { permissions, showToast, setShowModal } = props;
  const [selectedPermissions, setselectedPermissions] = useState( [])
  const [roleName, setroleName] = useState("")
  const [loading, setloading] = useState(false)
  const { currentUser, permissionModal } = useAuth();



  const handleOnChange = (value) => {

    setselectedPermissions(value)
  }


  const handleAddRole = async () => {

    if (!roleName) {
      showToast(false, "Please Enter Role Name")
      return;
    }

    if (selectedPermissions.length <= 0) {
      showToast(false, "Please Select atleast One Permission")
      return;
    }
    try {
      setloading(true);

      await axios({
        method: "POST",
        url: `${config.endpoint}/addNewRole`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${await currentUser.getIdToken()}`
        },
        data: {
          role: {
            name: roleName,
            permissions: selectedPermissions
          },
          uid: currentUser?.uid
        }
      })
      setroleName("");
      setShowModal(false);
      showToast(true, "Role Added Successfully")


    } catch (error) {
      console.log(error)
      showToast(false, "Something went wrong")

    }

    setloading(false);



  }

  return <React.Fragment>
    {loading ? <LoadingSpinner styles={{ height: "260px" }} />
      : <PlasmicAddRoleModal root={{ ref }} {...props}

        roleName={{
          value: roleName,
          onChange: (e) => setroleName(e.target.value)
        }}

        addRoleBtn={{
          onClick: () => handleAddRole()
        }}

        permissionSelectionStack={{
          value: selectedPermissions,
          options: permissions?.map((value)=>({ label: value.name, value: value.name})),
          onChange: (val) => handleOnChange(val)
        }}

        permissionChipsStack={{
          children: <></>
        }}


      />
    }
  </React.Fragment>
}

const AddRoleModal = React.forwardRef(AddRoleModal_);

export default AddRoleModal;