// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useEffect, useContext} from "react";
import { PlasmicLoginPage } from "./plasmic/collect_wise_draft/PlasmicLoginPage";
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import validator from 'validator'

function LoginPage_(props, ref) {
  const [emailContent, setEmailContent] = useState('')
  const [passwordContent, setPasswordContent] = useState('')
  const [invalidEmailError, setInvalidEmailError] = useState(false)
  const [incorrectLoginError, setIncorrectLoginError] = useState(false)
  const [customError, setCustomError] = useState(false)
  const { login, currentUser } = useAuth()
  const [errorContent, setErrorContent] = useState('Failed to login')
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  // Use PlasmicLoginPage to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicLoginPage are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all LoginPageProps here, but feel free
  // to do whatever works for you.
async function handleSubmit(){
setInvalidEmailError(false)
setIncorrectLoginError(false)
setCustomError(false)

if (!(validator.isEmail(emailContent))){
  setInvalidEmailError(true)
} else{

try{
  var email = emailContent
  var password = passwordContent

  setLoading(true)
  await login(email, password)
  // if (currentUser.emailVerified){
  //   history.push("/")
  // } else {
  //   history.push("/verify-email")
  // }

  history.push("/")
} catch (e){
  setLoading(false)

  if (e.code === "auth/user-not-found"){
    setIncorrectLoginError(true)
  } else{
    setCustomError(true)
  }

}


}

}


  //figure out why it looks weird when inputting into a Plasmic form
  return <PlasmicLoginPage 
  loginButton={{
    onClick: () => handleSubmit(),
    disabled: loading
  }}
  loginEmailInput={{
    danger: invalidEmailError,
    value: emailContent,
    onChange: (e) => {
      setEmailContent(e.target.value);
    }

  }}
  loginPasswordInput={{
    value: passwordContent,
    onChange: (e) => {
      setPasswordContent(e.target.value);
    }

  }}
  invalidEmailText={{
    wrap: node => invalidEmailError ? node : null
  }}
  incorrectLoginText={{
    wrap: node => incorrectLoginError ? node : null
  }}
  customErrorInstance={{
    wrap: node => customError ? node : null,
    props: {
      customErrorText: errorContent
    }
  }}
  signupText={{
   onClick: () => history.push("/signup") 
  }}
  forgotPassword={{
    onClick: () => history.push("/forgotpassword")
  }}
  />;
}

const LoginPage = React.forwardRef(LoginPage_);

export default LoginPage;
