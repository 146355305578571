import * as React from "react";
import { PlasmicSelectBusinessType } from "./plasmic/collect_wise_draft/PlasmicSelectBusinessType";
import Option from "./SelectBusinessType__Option";
import OptionGroup from "./SelectBusinessType__OptionGroup";

function SelectBusinessType_(props, ref) {
  const { plasmicProps, state } = PlasmicSelectBusinessType.useBehavior(
    props,
    ref
  );

  return <PlasmicSelectBusinessType {...plasmicProps} />;
}

const SelectBusinessType = React.forwardRef(SelectBusinessType_);

export default Object.assign(SelectBusinessType, {
  Option,
  OptionGroup,
  __plumeType: "select"
});
