// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, { useState, useEffect, useCallback } from "react";
import { PlasmicBillingSettings } from "./plasmic/collect_wise_draft/PlasmicBillingSettings";
import { db, storage } from "../firebase";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  serverTimestamp,
  getDoc,
  query,
  where,
  getDocs,
  onSnapshot,
  orderBy,
  limit,
  updateDoc,
} from "firebase/firestore";
import LoadingSpinner from "./LoadingSpinner";
import { useAuth } from "../contexts/AuthContext";
import {
  usePlaidLink,
  PlaidLinkOptions,
  PlaidLinkOnSuccess,
} from "react-plaid-link";
import axios from "axios";
import { v4 } from "uuid";
import { config } from "./constants";
import { ApideckVault } from "@apideck/vault-js";
import LoadingSpinner4 from "./LoadingSpinner4";
import InvitedStatusCard from "./InvitedStatusCard"
import AcceptedStatusCard from "./AcceptedStatusCard"
import { Button, Modal } from "react-bootstrap"
import InviteUserCard from "./InviteUserCard"
import InviteUserModal from "./InviteUserModal"
import { ToastContainer, toast } from 'react-toastify';
import BillingLink from "./BillingLink";
import AddBankManuallyModal from "./AddBankManuallyModal";


function BillingSettings_(props, ref) {

  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false)
  const [user, setuser] = useState()
  const [loading2, setloading2] = useState(false)
  const { currentUser, authUser ,checkPermission,showToast} = useAuth();
  const [linkToken, setLinkToken] = useState("")
  const [userInfoDocId, setUserInfoDocId] = useState("")
  const [addBankModal, setAddBankModal] = useState(false)
  const [merchantName, setMerchantName] = useState("")



  useEffect(() => {


    if (!currentUser) return setloading2(false);


    const q = query(
      collection(db, 'userInfo'),
      where('user', '==', currentUser.uid)
    );



    onSnapshot(q, (snapshot) => {


      if (snapshot.empty) return setloading2(false);


      const userInfo = snapshot.docs[0].data();
      setMerchantName(userInfo.merchantName)
      setUserInfoDocId(snapshot.docs[0].id)
      setuser(userInfo)

      setloading2(false)
      setLoading(false);

      console.log(user)

    })
  }, [])
  

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  }

 

  const handleClose = () => setShowModal(false)

  
  return ( linkToken !== "" ?  <BillingLink
    linkToken={linkToken}
    setLinkToken={setLinkToken}
    userInfoDocId={userInfoDocId}
  /> :
    <React.Fragment>
       {<PlasmicBillingSettings root={{ ref }} {...props} 
       vertStack = { loading && {
        children: <LoadingSpinner4 />
       }}
       bankName = {user ? (user.bankName ? user.bankName : "Not Linked") : "Not Linked"}
        linkBank = {{
          onClick: async () => {
            // setLoading(true)

            // const response = await axios({
            //   method: 'POST',
            //   url: `${config.endpoint}/paymentFunctions-getPlaidLinkToken`,
            //   data: {
            //     user: currentUser.uid
            //   }
            //   })
              
            //   console.log(response.data)

            //   setLinkToken(response.data)

              // setLoading(false)

              setAddBankModal(true)

              
          }
        }}
        inviteUsersBtn = {{
                onClick: () => {
                  if(authUser?.isChild){
                    showToast(false,"Main Admin can only invite other users.")
                    return;
                  }else {
                    setShowModal(true)
                  }
                }
              }}
              userListStack = {{
                children :<> 
               {loading2 ? <LoadingSpinner styles = {{height:"100px"}}/> :
                user?.invites && user?.invites.map((e) => {
                  return <InviteUserCard key={e.email} userName = {e.email} statusCard = {e.status ? <AcceptedStatusCard/> : <InvitedStatusCard/>}/> 
                })}
                </>
              }}
              inviteUsersStack = {{
                wrap: node => node
              }}
        /> }
   {  <Modal show={showModal} onHide={handleClose}
    backdrop="static"
    aria-labelledby="contained-modal-title-vcenter"
    centered>
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">Invite User</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <InviteUserModal setShowModal = {setShowModal} showToast={showToast} user= {user}/>
    </Modal.Body>
  </Modal> 
  }
    { <Modal show={addBankModal} onHide={() => setAddBankModal(false)}
    backdrop="static"
    aria-labelledby="contained-modal-title-vcenter"
    centered>
    <Modal.Body>
      <AddBankManuallyModal setAddBankModal={setAddBankModal} user= {user} userInfoDocId={userInfoDocId} merchantName={merchantName}/>
    </Modal.Body>
  </Modal> 
  }
  </React.Fragment>
  );


}

const BillingSettings = React.forwardRef(BillingSettings_);

export default BillingSettings;
