// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useEffect} from "react";
import { PlasmicTestNewPage } from "./plasmic/collect_wise_draft/PlasmicTestNewPage";
import { db } from "../firebase"
import { v4 } from "uuid";
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit } from "firebase/firestore"; 
import { useAuth } from "../contexts/AuthContext";
import axios from "axios"
import {useHistory} from "react-router-dom"
import Moment from 'moment'

function TestNewPage_(props, ref) {
  const [content, setContent] = useState('')
  const [dataItems, setDataItems] = useState([])
  const { currentUser } = useAuth()
  const formatDate = Moment().format('MMM Do, YYYY')
  const history = useHistory()

async function handleSubmit(){
 
var tempContent = content

  setContent('')

  const escrowsubmisionsInstance = await addDoc(collection(db, "escrowsubmissions"), {
    title: "sample title",
    price: "sample price"
   })
    
    
   await addDoc(collection(escrowsubmisionsInstance, "Milestone Entries"), {
    milestoneTitle: "sample milestone title",
    milestonePrice: "sample milestone price",
    dueDate: "sample milestone due date"
   })
   

//   const docRef = doc(db, "customers", "sample");


//   const colRef = collection(docRef, "checkout_sessions")

//   await addDoc(colRef, {
//   price: "sddd",
//   and: "dsdds",
//   more: "shit",
// });

  // await addDoc(collection(db, "cities"), {
  //   contractTitle: tempContent,
  //   price: "$1000",
  //   user: currentUser.uid,
  //   date: serverTimestamp()
  // });

  // await addDoc(collection(db, "cities"), {
  //   name: "Tokyo",
  //   content: tempContent,
  //   user: currentUser.uid,
  //   date: serverTimestamp()
  // });

  
  
}


async function getData(){

const docRef = doc(db, "cities", "LA");
const docSnap = await getDoc(docRef);
console.log(docSnap.data().name)


const q = query(collection(db, "cities"), where("user", "==", currentUser.uid))

const querySnapshot = await getDocs(q);
console.log("QuereySnapshot: " + querySnapshot)

querySnapshot.forEach((doc) => {
  
  console.log(doc.id, " => ", doc.data());
});

}



useEffect(() => {
  const q = query(collection(db, "cities"), where("user", "==", currentUser.uid), orderBy("date"), limit(3))
  const tempData=[]
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const tempContent = [];
    querySnapshot.forEach((doc) => {
      tempData.push({id: doc.id, data: doc.data() })
        
    });
    
    setDataItems(tempData)
  });

}, [])


async function addItems(){

const idObject = JSON.stringify({
  id: currentUser.uid
})

 await axios({
  method: "POST",
  url: "https://us-central1-astrius-dev.cloudfunctions.net/setCustomClaims",
  data: {
    uid: idObject
  }
 })

 history.push("/")

}




function getData() {

console.log(currentUser)

  currentUser.getIdTokenResult()
  .then((idTokenResult) => {

    console.log(idTokenResult.claims.onboarded)


  }).catch((error) => {
    console.log(error)
  })

}


function handleAdTest() {

  
  window.dataLayer.push({'event': 'button1-click'})


}

async function testPro() {

  const payments = await addDoc(collection(db, "payments"), {
    api_deck_invoiceId: null,
    user: currentUser.uid,
    userEmail: currentUser.email,
    type: "Subscription Payment",
    amount: "$500.00",
    amountNum: 500,
    paymentId: v4(),
    status: "Not Paid",
    client: JSON.stringify({"company":"Sample","email":"sean1107obrien+sample@gmail.com","contactName":"Sample","notes":"","date":{"seconds":1691864967,"nanoseconds":792000000},"docId":"28782b3a-1bea-4335-8c1e-3276ad86df56","url":"https://api-sandbox.dwolla.com/customers/8a685c39-3b04-4c06-bdca-8f40dc469b1d","clientId":"7eb03a3c-4c74-456a-b0d9-bee28458d3d8","api_deck_clientId":null,"invoiceNum":7,"invoice_identifier":"V7EB03A3"}),
    clientCompany: "Sean OBrien",
    dateCreated: formatDate,
    paymentDataId: v4(),
    datePaid: "NA",
    paymentMethod: "NA",
    paymentNotes: "",
    date: serverTimestamp(),
    clientEmail: "sean1107obrien@gmail.com",
    clientUrl: "NA",
    datePaidNum: "NA",
    fundsLocation: "NA",
    paymentSuccess: false,
    clientDocId: "sswwweew2esde",
    invoiceNum: "1",
    invoice_identifier: null,
    fundingDestination: "",
    milestoneOrContract: "NA",
    merchantName: "Lending Corp",
    invoiceFields: [{ item: "Loan", quantity: 1, price: "500", id: v4(), balanceDue: 500 }],
    dueDate: new Date(),
    userInfoDocId: "edks2232",
    reminderOptions: { enabled: false, number: 0, unit: '' },
    automationOptions: { enabled: false, number: 0, unit: '' },
    paymentMethods: { ACH: true, Card: true, Wire: true, CardSurcharge: false },
    paymentVoided: false,
    voidDate: "NA",
    contractTitle: "NA",
    merchantId:
      "",
    terminalId:
      "",
    zip: "07757",
    yourToken: v4(),
    ip: "2121",
    heartlandPublicKey: "pkapi_cert_2pdYMRFD3WvFwSDDtT",
    plan: "Free",
    firstName: "Sean",
    lastName: "OBrien",
    api_deck_ledger_id: null,
    api_deck_supplier_id: null,
    api_deck_bank_id: null,
    /// two new fields for subscription payments as opposed to regular invoice payments
    subscriptionCycle: "Monthly",
    subscriptionEnabled: false,
    logoUrl: "",
    color: "",
    // products: products
  });

}


  return (
  <>
  <button onClick={testPro}>testPro</button>
<button onClick={addItems}>hello</button>
<button onClick={getData}>Get data</button>
<button onClick={handleAdTest}>Test it</button>
​<a href="#" name="button1" style={{display: "none"}} id="pivotButton" onclick="dataLayer.push({'event': 'button1-click'});">Button 1</a>
  </> )
}

const TestNewPage = React.forwardRef(TestNewPage_);

export default TestNewPage;
