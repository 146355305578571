import * as React from "react";
import { PlasmicSelectCountry__Overlay } from "./plasmic/collect_wise_draft/PlasmicSelectCountry__Overlay";

function SelectCountry__Overlay_(props, ref) {
  const { plasmicProps } = PlasmicSelectCountry__Overlay.useBehavior(
    props,
    ref
  );

  return <PlasmicSelectCountry__Overlay {...plasmicProps} />;
}

const SelectCountry__Overlay = React.forwardRef(SelectCountry__Overlay_);

export default Object.assign(SelectCountry__Overlay, {
  __plumeType: "triggered-overlay"
});
