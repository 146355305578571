import * as React from "react";
import { PlasmicSelectBizStructure__OptionGroup } from "./plasmic/collect_wise_draft/PlasmicSelectBizStructure__OptionGroup";

function SelectBizStructure__OptionGroup(props) {
  const { plasmicProps } =
    PlasmicSelectBizStructure__OptionGroup.useBehavior(props);
  return <PlasmicSelectBizStructure__OptionGroup {...plasmicProps} />;
}

export default Object.assign(SelectBizStructure__OptionGroup, {
  __plumeType: "select-option-group"
});
