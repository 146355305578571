import React, { useEffect, useState } from "react";
import "filepond/dist/filepond.min.css";
import { config } from "./constants";
import { useAuth } from "../contexts/AuthContext";
import { Dashboard } from "@uppy/react";
import Uppy from "@uppy/core";
import FirebaseCloudStorage from "./FirebaseCloudStorageUploader";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import "@uppy/progress-bar/dist/style.min.css";
import LoadingSpinner4 from "./LoadingSpinner4WithText";
import { toast } from "react-toastify";
import { hasVariant } from "@plasmicapp/react-web";

function FileUploader(props) {
  const { currentUser } = useAuth();
  const [token, setToken] = useState(null);
  const [loadingFiles, setLoadingFiles] = useState([]);
  const files = []; //state is GAYYYYYYY
  const [successFiles, setSuccessFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const uppy = new Uppy()
    .use(FirebaseCloudStorage, { currentUserID: currentUser.uid })
    .on("upload-progress", (file, progress) => {
      setLoading(true);

      console.log("Upload progress:", progress);
     
    })
    .on("upload-success", async (filePath, file, lastOne) => {
      files.push(filePath);
      console.log("Uploaded files", files);

      if (lastOne) {
        // Append new files to existing prop files
        const updatedFiles = (props.files || []).concat(files);
        console.log("Updated files", updatedFiles);
        props.setFiles(updatedFiles);
        console.log("Prop files", props.files)
        setLoading(false);

        if (props.showModal) {
          props.setShowModal(false);
        }

        toast.success("Files Uploaded Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    })
    .on("upload-error", (file, error) => {
      console.error("Upload error:", error);
   
    });

    const dashboardStyle = {
        width: '100%',
        height: '100%',
        flex: '1',
        minHeight: '0' 
      };

      return loading ? (
        <LoadingSpinner4 text={"Uploading Files. Please Wait"} />
      ) : (
       // <div style={{ width: '100%', height: '100%', position: 'relative' }}>
          <Dashboard height={120} uppy={uppy} proudlyDisplayPoweredByUppy={false} style={dashboardStyle} />
      //  </div>
      );
    }

export default FileUploader;
