import * as React from "react";
import { PlasmicSelectCountry } from "./plasmic/collect_wise_draft/PlasmicSelectCountry";
import Option from "./SelectCountry__Option";
import OptionGroup from "./SelectCountry__OptionGroup";

function SelectCountry_(props, ref) {
  const { plasmicProps, state } = PlasmicSelectCountry.useBehavior(props, ref);
  return <PlasmicSelectCountry {...plasmicProps} />;
}

const SelectCountry = React.forwardRef(SelectCountry_);

export default Object.assign(SelectCountry, {
  Option,
  OptionGroup,
  __plumeType: "select"
});
