import React, { useEffect, useState } from "react";
import {
  ClientIcon,
  Download,
  CreditCard,
  Bank,
  Lock,
  CreditCardBlue,
  BankBlue,
} from "./assets";
import moment from "moment";
import "./paymentCard.css";

const PaymentCard = (props) => {
  const {
    pageData,
    pageType,
    handleBankTransfer,
    handleModalOpen,
    component,
    handleDownloadPDF,
  } = props;
  const [cardIndex, setCardIndex] = useState(0);
  const [payNowToggle, setPayNowToggle] = useState(false);
  const cards = [
    {
      icon: CreditCard,
      title: "Card Payment",
      description: "Input your credit or debit card information",
    },
    {
      icon: Bank,
      title: "Bank Transfer",
      description: "Input your bank account information",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'instant'
    });
  },[]);

  const handlePay = () => {
    if (pageType == "ACH") handleBankTransfer();
    else if (pageType == "Card") handleModalOpen();
    else {
      if (cardIndex) handleBankTransfer();
      else handleModalOpen();
    }
  };

  const handlePageTypeComponent = () => {
    switch (pageType) {
      case `ACH`:
        return (
          <div className="paymentBox">
            <div className="methodTitle">Payment Method</div>
            <div className="paymentBoxBody">
              <div className="paymentBoxSubContainer">
                <div
                  style={{
                    color: `${pageData?.color ? pageData?.color : "#3555FF"}`,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M3.33333 6.00009V11.3334M6.33333 6.00009V11.3334M9.66667 6.00009V11.3334M12.6667 6.00009V11.3334M2 12.4001L2 12.9334C2 13.3068 2 13.4935 2.07266 13.6361C2.13658 13.7615 2.23856 13.8635 2.36401 13.9274C2.50661 14.0001 2.6933 14.0001 3.06667 14.0001H12.9333C13.3067 14.0001 13.4934 14.0001 13.636 13.9274C13.7614 13.8635 13.8634 13.7615 13.9273 13.6361C14 13.4935 14 13.3068 14 12.9334V12.4001C14 12.0267 14 11.84 13.9273 11.6974C13.8634 11.572 13.7614 11.47 13.636 11.4061C13.4934 11.3334 13.3067 11.3334 12.9333 11.3334H3.06667C2.6933 11.3334 2.50661 11.3334 2.36401 11.4061C2.23857 11.47 2.13658 11.572 2.07266 11.6974C2 11.84 2 12.0267 2 12.4001ZM7.76861 2.05152L2.83527 3.14781C2.53723 3.21404 2.38821 3.24716 2.27698 3.3273C2.17886 3.39798 2.10182 3.49402 2.0541 3.60514C2 3.73111 2 3.88377 2 4.18908L2 4.93343C2 5.3068 2 5.49348 2.07266 5.63609C2.13658 5.76153 2.23856 5.86352 2.36401 5.92743C2.50661 6.00009 2.6933 6.00009 3.06667 6.00009H12.9333C13.3067 6.00009 13.4934 6.00009 13.636 5.92743C13.7614 5.86352 13.8634 5.76153 13.9273 5.63609C14 5.49348 14 5.3068 14 4.93343V4.18908C14 3.88377 14 3.73111 13.9459 3.60514C13.8982 3.49403 13.8211 3.39798 13.723 3.3273C13.6118 3.24716 13.4628 3.21404 13.1647 3.14781L8.23139 2.05152C8.14504 2.03233 8.10187 2.02273 8.05824 2.01891C8.01949 2.01551 7.98051 2.01551 7.94176 2.01891C7.89813 2.02273 7.85496 2.03233 7.76861 2.05152Z"
                      stroke="currentColor"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div
                  className="paymentSubTitle"
                  style={{
                    color: `${pageData?.color ? pageData?.color : "#3555FF"}`,
                  }}
                >
                  Bank Transfer (Free)
                </div>
              </div>
              <div className="paymentBoxDescription">
                {cards[1]?.description}
              </div>
            </div>
          </div>
        );
      case `Card`:
        return (
          <div className="paymentBox">
            <div className="methodTitle">Payment Method</div>
            <div className="paymentBoxBody">
              <div className="paymentBoxSubContainer">
                <div
                  style={{
                    color: `${pageData?.color ? pageData?.color : "#3555FF"}`,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <g id="credit-card-01">
                      <path
                        id="Icon"
                        d="M14.6667 6.66658H1.33337M1.33337 5.46659L1.33337 10.5333C1.33337 11.28 1.33337 11.6534 1.4787 11.9386C1.60653 12.1895 1.8105 12.3934 2.06139 12.5213C2.3466 12.6666 2.71997 12.6666 3.46671 12.6666L12.5334 12.6666C13.2801 12.6666 13.6535 12.6666 13.9387 12.5213C14.1896 12.3934 14.3936 12.1895 14.5214 11.9386C14.6667 11.6534 14.6667 11.28 14.6667 10.5333V5.46659C14.6667 4.71985 14.6667 4.34648 14.5214 4.06127C14.3936 3.81038 14.1896 3.60641 13.9387 3.47858C13.6535 3.33325 13.2801 3.33325 12.5334 3.33325L3.46671 3.33325C2.71997 3.33325 2.3466 3.33325 2.06139 3.47858C1.8105 3.60641 1.60653 3.81038 1.4787 4.06126C1.33337 4.34648 1.33337 4.71985 1.33337 5.46659Z"
                        stroke="currentColor"
                        stroke-width="1.33333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                </div>
                <div
                  className="paymentSubTitle"
                  style={{
                    color: `${pageData?.color ? pageData?.color : "#3555FF"}`,
                  }}
                >
                  Card Payment
                </div>
              </div>
              <div className="paymentBoxDescription">
                {cards[0]?.description}
              </div>
            </div>
          </div>
        );
      case `CardSurcharge`:
        return (
          <div className="card-wrapper">
            {cards.map((card, index) => (
              <div
                className={`cardContainer ${
                  cardIndex === index ? "selectedCard" : "unselectedCard"
                }`}
                style={{
                  border:
                    cardIndex === index &&
                    `2px solid ${
                      pageData?.color ? pageData?.color : "#3555FF"
                    }`,
                  background:
                    cardIndex === index &&
                    `${pageData?.color ? pageData?.color + "08" : "#3555FF" + "08"  }`,
                }}
                key={index}
                onClick={() => setCardIndex(index)}
              >
                <div className="cardSubcontainer">
                  <div
                    className="cardImage"
                    style={{
                      border: `4px solid ${
                        pageData?.color ? pageData?.color : "#3555FF"
                      }`,
                      background: `${
                        pageData?.color ? pageData?.color : "#3555FF"
                      }`,
                    }}
                  >
                    <img src={card?.icon} alt="Credit Card" width={16} />
                  </div>
                  <div
                    className={`cardTitle`}
                    style={{
                      color:
                        cardIndex === index
                          ? `${pageData?.color ? pageData?.color : "#3555FF"}`
                          : "",
                    }}
                  >
                    {card?.title}
                    {!index ? " (2.9% Fee)" : " (Free)"}
                  </div>
                  <div className="cardDescription">{card?.description} </div>
                </div>

                <div class="round">
                  <input type="checkbox" checked={cardIndex === index} />
                  <label
                    for="checkbox"
                    style={{
                      border:
                        cardIndex === index &&
                        `2px solid ${
                          pageData?.color ? pageData?.color : "#3555FF"
                        }`,
                      background:
                        cardIndex === index &&
                        `${pageData?.color ? pageData?.color : "#3555FF"}`,
                    }}
                    // onClick={() => setCardIndex(index)}
                  ></label>
                </div>
              </div>
            ))}
          </div>
        );
      default:
        return (
          <div className="card-wrapper">
            {cards.map((card, index) => (
              <div
                className={`cardContainer ${
                  cardIndex === index ? "selectedCard" : "unselectedCard"
                }`}
                style={{
                  border:
                    cardIndex === index &&
                    `2px solid ${
                      pageData?.color ? pageData?.color : "#3555FF"
                    }`,
                  background:
                    cardIndex === index &&
                    `${pageData?.color ? pageData?.color + "08" : "#3555FF" + "08"}`,
                }}
                key={index}
                onClick={() => setCardIndex(index)}
              >
                <div className="cardSubcontainer">
                  <div
                    className="cardImage"
                    style={{
                      border: `4px solid ${
                        pageData?.color ? pageData?.color : "#3555FF"
                      }`,
                      background: `${
                        pageData?.color ? pageData?.color : "#3555FF"
                      }`,
                    }}
                  >
                    <img src={card?.icon} alt="Credit Card" width={16} />
                  </div>
                  <div
                    className={`cardTitle ${
                      cardIndex === index ? "selectedCardTitleColor" : ""
                    }`}
                  >
                    {card?.title} {index ? "(ACH)" : ""}
                  </div>
                  <div className="cardDescription">{card?.description} </div>
                </div>

                <div class="round">
                  <input type="checkbox" checked={cardIndex === index} />
                  <label
                    for="checkbox"
                    style={{
                      border:
                        cardIndex === index &&
                        `2px solid ${
                          pageData?.color ? pageData?.color : "#3555FF"
                        }`,
                      background:
                        cardIndex === index &&
                        `${pageData?.color ? pageData?.color : "#3555FF"}`,
                    }}
                    // onClick={() => setCardIndex(index)}
                  ></label>
                </div>
              </div>
            ))}
          </div>
        );
    }
  };

  const handleAmountText = () => {
    if (
      pageData?.subscription_cycle === "Monthly" ||
      pageData?.subscriptionCycle === "Monthly"
    )
      return pageData?.amount + "/month";
    else if (
      pageData?.subscription_cycle === "Weekly" ||
      pageData?.subscriptionCycle === "Weekly"
    )
      return pageData?.amount + "/week";
    else return pageData?.amount;
  };

  return (
    <div
      className={`paymentCardContainer ${
        component === "preview" ? "paymentCardPreviewContainer" : ""
      }`}
    >
      <div className="subContainer">
        <div className="paymentCardHead">
          {pageData?.logoUrl && (
            <div className="companyLogo">
              <img
              src={pageData?.logoUrl}
              alt="client Logo"
              width={32}
              height={32}
            />
            </div>
          )}
          <div>
            <div className="companyName">
              {pageData?.merchantName}
            </div>
            <div className="paymentCardCategory">{"Debt Payment"}</div>
          </div>
        </div>
        {/* Payment Amount Section */}
        <div>
          <div className="paymentAmount">
            {pageData?.type == "Subscription Payment"
              ? handleAmountText()
              : pageData?.amount}
          </div>
          <div className="paymentAmountHeading">
            <div className="paymentAmountHeadingText">Payment Amount</div>
            <div
              className="downloadContainer"
              onClick={() => component !== "preview" && handleDownloadPDF()}
            >
              <img src={Download} alt="Download" width={16} />
              <div className="downloadText">Download</div>
            </div>
          </div>
        </div>
        {/* Divider */}
        <div className="paymentCardDivider" />
        {/* Summary Section */}
        <div className="summary">
          <div className="summaryText">Summary</div>
          {pageData?.type == "Subscription Payment" ? (
            <div className="summaryMain">
              <div className="summarySectionRow">
                <div className="summarySubTextHead">{"Start Date"}</div>
                <div className="summarySubTextValue">
                  {" "}
                  {moment(pageData?.date).format("LL")?.split(",")[0]}
                </div>
              </div>
              <div className="summarySectionRow">
                <div className="summarySubTextHead">{"Billing Cycle"}</div>
                <div className="summarySubTextValue">
                  {pageData?.subscription_cycle || pageData?.subscriptionCycle}
                </div>
              </div>
            </div>
          ) : (
            <div className="summaryMain">
              <div className="summarySectionRow">
                <div className="summarySubTextHead">
                  {pageData?.type == "Invoice Payment"
                    ? "Invoice"
                    : "Start Date"}
                </div>
                <div className="summarySubTextValue">
                  {" "}
                  {pageData?.type == "Invoice Payment"
                    ? "#" + pageData?.invoiceNum
                    : moment(pageData?.date).format("LL")}
                </div>
              </div>
              <div className="summarySectionRow">
                <div className="summarySubTextHead">
                  {pageData?.type == "Invoice Payment"
                    ? "Due Date"
                    : "Billing Cycle"}
                </div>
                <div className="summarySubTextValue">
                  {pageData?.type == "Invoice Payment"
                    ? moment(pageData?.dueDate).format("LL")
                    : pageData?.subscription_cycle ||
                      pageData?.subscriptionCycle}
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Divider */}
        <div className="paymentCardDivider" />
        {/* Card Selection Section */}
        {handlePageTypeComponent()}

        {/* Pay Now Button */}
        <div
          className="payNow"
          style={{
            border: `1px solid ${
              pageData?.color ? payNowToggle ? pageData?.color + "d4" : pageData?.color : "#3555FF"
            }`,
            background: `${pageData?.color ? payNowToggle ? pageData?.color + "d4" : pageData?.color : payNowToggle ? "#3555FFd4" : "#3555FF"}`,
          }}
          onMouseEnter={() => setPayNowToggle(true)}
          onMouseLeave={() => setPayNowToggle(false)}
          onClick={handlePay}
        >
          <div>
            <img src={Lock} alt="lock" width={20} />
          </div>
          <div className="payNowText">
            {" "}
            {pageType == "ACH"
              ? "Pay via Bank Transfer"
              : pageType == "Card"
              ? "Pay with Card"
              : "Pay " + pageData?.amount}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentCard;
