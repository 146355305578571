import * as React from "react";
import { PlasmicSubscriptionSelect__Overlay } from "./plasmic/collect_wise_draft/PlasmicSubscriptionSelect__Overlay";

function SubscriptionSelect__Overlay_(props, ref) {
  const { plasmicProps } = PlasmicSubscriptionSelect__Overlay.useBehavior(
    props,
    ref
  );

  return <PlasmicSubscriptionSelect__Overlay {...plasmicProps} />;
}

const SubscriptionSelect__Overlay = React.forwardRef(
  SubscriptionSelect__Overlay_
);

export default Object.assign(SubscriptionSelect__Overlay, {
  __plumeType: "triggered-overlay"
});
