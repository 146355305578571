// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicInviteUserModal } from "./plasmic/collect_wise_draft/PlasmicInviteUserModal";
import { useState } from "react";
import LoadingSpinner from "./LoadingSpinner";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext"
import { config } from "./constants";
import validator from 'validator';
import Form from 'react-bootstrap/Form';
import { useHistory } from 'react-router-dom';


function InviteUserModal_(props, ref) {

  const [email, setemail] = useState("")
  const [showLoader, setshowLoader] = useState(false)
  const { currentUser, setPermissionModal } = useAuth();
  const [selectedRole, setselectedRole] = useState("")
  const history = useHistory()

  const { setShowModal, showToast, docId, user } = props;

  const handleInvite = async () => {

    // if (user?.invites && user?.invites.length >= 1){
    //   showToast(false, "Cannot invite more than 1 person");
    //   return;
    // }

    if (!email || !selectedRole) {
      showToast(false, "Please fill all fields");
      return;
    }
    if (!(validator.isEmail(email))) {
      showToast(false, "Invalid email format")
      return;
    }

    setshowLoader(true);

    try {
      const response = await axios({
        method: "POST",
        url: `${config.endpoint}/inviteUser`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${await currentUser.getIdToken()}`
        },
        data: {
          email,
          role : selectedRole,
          uid: currentUser.uid,
        }
      })
      showToast(true, "Email sent");
      setShowModal(false);

    } catch (error) {
      showToast(false, "Something went wrong");

    }
    setshowLoader(false);
  }

  const handleRoleSelect = (e) =>{

    setselectedRole(e.target.value)
  }

  return <React.Fragment>
    {showLoader ? <LoadingSpinner styles={{ height: "100px" }} /> :
      <PlasmicInviteUserModal root={{ ref }} {...props}
        emailInput={{
          value: email,
          onChange: (e) => setemail(e.target.value)
        }}

        sendInviteBtn={{
          onClick: () => handleInvite()
        }}
        
        addRoleBtn = {{
          onClick : () => {
            setPermissionModal(true)
            history.push("/roles")
          }
        }}

        selectRoleStack = {{
          children : <>
          <Form.Select aria-label="Default select example" onChange={handleRoleSelect} value={selectedRole}>
            <option value="" disabled>Select</option>
            {user.user_defined_roles &&
            user.user_defined_roles.map((p) => {
              return <option key={p.name} value={p.name}>{p.name}</option>
            }
            )
            }

          </Form.Select>
        </>
        }}

      />}
  </React.Fragment>
}

const InviteUserModal = React.forwardRef(InviteUserModal_);

export default InviteUserModal;
