// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicPermissionChip } from "./plasmic/collect_wise_draft/PlasmicPermissionChip";

function PermissionChip_(props, ref) {


  const { showCloseBtn, value, handleRemoveChip } = props;

  return <React.Fragment>
    <div style={{ marginBottom: "5px" }}>
      {
        showCloseBtn ?
          <PlasmicPermissionChip root={{ ref }} {...props}
            chipText={{
              children: <p style={{ margin: 0, color: "white" }}>{value}</p>
            }}
            clearChip={{
              onClick: () => handleRemoveChip(value)
            }}
          />
          :
          <PlasmicPermissionChip root={{ ref }} {...props}
            chipStack={{
              children: <p style={{ margin: 0, color: "white", padding: "0px 15px" }}>{value}</p>
            }}

          />
      }
    </div>
  </React.Fragment>
}

const PermissionChip = React.forwardRef(PermissionChip_);

export default PermissionChip;
