import * as React from "react";
import { PlasmicSelectGender2__Overlay } from "./plasmic/collect_wise_draft/PlasmicSelectGender2__Overlay";

function SelectGender2__Overlay_(props, ref) {
  const { plasmicProps } = PlasmicSelectGender2__Overlay.useBehavior(
    props,
    ref
  );

  return <PlasmicSelectGender2__Overlay {...plasmicProps} />;
}

const SelectGender2__Overlay = React.forwardRef(SelectGender2__Overlay_);

export default Object.assign(SelectGender2__Overlay, {
  __plumeType: "triggered-overlay"
});
