// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, { useState, useEffect, useRef } from "react";
import { PlasmicPaymentInstance } from "./plasmic/collect_wise_draft/PlasmicPaymentInstance";
import { useParams } from "react-router-dom";
import { db, storage } from "../firebase";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  updateDoc,
  serverTimestamp,
  getDoc,
  query,
  where,
  getDocs,
  onSnapshot,
  orderBy,
  limit,
} from "firebase/firestore";
import PaidStatusCard from "./PaidStatusCard";
import NotPaidStatusCard from "./NotPaidStatusCard";
import VoidStatusCard from "./VoidStatusCard"
import LoadingSpinner from "./LoadingSpinner";
import axios from "axios";
import { v4 } from "uuid";
import Moment from "moment";
import { useAuth } from "../contexts/AuthContext";
import { config } from "./constants";
import { ToastContainer, toast } from 'react-toastify';
import { Button, Modal, Form } from "react-bootstrap"
import 'react-toastify/dist/ReactToastify.css';
import EditSubscription from "./EditSubscription";

// if you notice a problem with an excessive amount of reads/writes coming from this, then change the way you save data in the notes to an onChange or onSubmit

function PaymentInstance_(props, ref) {
  let { paymentDataId } = useParams();
  const [screenData, setScreenData] = useState({});
  const { currentUser, checkPermission, showToast } = useAuth();
  const [notes, setNotes] = useState("");
  const [edit, setEdit] = useState(false);
  const [docId, setDocId] = useState("");
  const [loading, setLoading] = useState(true);
  const [amount, setAmount] = useState("");
  const [automations, setAutomations] = useState(false);
  const [paymentId, setPaymentId] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [subscriptionEnabled, setSubscriptionEnabled] = useState(true)
  const [subscriptionAmount, setSubscriptionAmount] = useState(0)
  const [subscriptionCycle, setSubscriptionCycle] = useState("")
  
  const currencyRef = useRef(null)


  const formatDate = Moment().format("MMM Do, YYYY")

  const handleClose = () => setShowModal(false)


  function formatInvoiceNum() {

    return screenData.invoice_identifier + "-" + String(screenData.invoiceNum).padStart(4, '0');

  }


  async function modalSubmit() {


    if (currencyRef.current.value === ""){
      currencyRef.current.value = "$" + subscriptionAmount
    }
 


    if (screenData.child) {


      const docRef = doc(db, "payments", screenData.parentPaymentDocId);
      const docSnapshot = await getDoc(docRef);
      

      await updateDoc(doc(db, "payments", screenData.parentPaymentDocId), {
        // oldAmount should be equal to the old amount in that particular payment instance
        oldAmount: docSnapshot.data().oldAmount ? docSnapshot.data().oldAmount : docSnapshot.data().amount,
        amountNum:  Number(currencyRef.current.value.substring(1).replace(/,/g, "")),
        amount: Number(currencyRef.current.value.substring(1).replace(/,/g, "")).toLocaleString('en-US', {style: 'currency', currency: 'USD', }),
        subscriptionCycle: subscriptionCycle,
        subscriptionEnabled: JSON.parse(subscriptionEnabled),
      })


      const q = query(
        collection(db, "payments"),
        where("parentPaymentDocId", "==", screenData.parentPaymentDocId),
        where("user", "==", currentUser.uid),
      );

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(async (docSnap) => {
     

        await updateDoc(doc(db, "payments", docSnap.id), {
          // oldAmount should be equal to the old amount in that particular payment instance
          oldAmount: docSnap.data().oldAmount ? docSnap.data().oldAmount : docSnap.data().amount,
          amountNum:  Number(currencyRef.current.value.substring(1).replace(/,/g, "")),
          amount: Number(currencyRef.current.value.substring(1).replace(/,/g, "")).toLocaleString('en-US', {style: 'currency', currency: 'USD', }),
          subscriptionCycle: subscriptionCycle,
          subscriptionEnabled: JSON.parse(subscriptionEnabled),
        })


      })    


    } else {

      await updateDoc(doc(db, "payments", docId), {
        oldAmount: screenData.oldAmount ? screenData.oldAmount : screenData.amountNum.toLocaleString('en-US', {style: 'currency', currency: 'USD', }),
        amountNum:  Number(currencyRef.current.value.substring(1).replace(/,/g, "")),
        amount: Number(currencyRef.current.value.substring(1).replace(/,/g, "")).toLocaleString('en-US', {style: 'currency', currency: 'USD', }),
        subscriptionCycle: subscriptionCycle,
        subscriptionEnabled: JSON.parse(subscriptionEnabled),
      })

    }


      setShowModal(false)

      if(!(JSON.parse(subscriptionEnabled))) {

        toast.success("This subscription has been disabled", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
          });

      } else {

        toast.success("This subscription has been successfully updated", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
          });

      }

  }


  function downloadPDF(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "receipt.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    setLoading(false);
    downloadLink.click();
  }

  function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  useEffect(() => {

    const q = query(
      collection(db, "payments"),
      where("paymentDataId", "==", paymentDataId),
      where("user", "==", currentUser.uid)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {

        setSubscriptionCycle(doc.data().type !== "Subscription Payment" ? null : doc.data().subscriptionCycle)
        setSubscriptionAmount(doc.data().amountNum)
        setAmount(doc.data().oldAmount ? (doc.data().oldAmount + " USD") : doc.data().amount + " USD");
        setNotes(doc.data().notes);
        setDocId(doc.id);
        setAutomations(doc.data().automationOptions.enabled);
        setScreenData(doc.data());
        setPaymentId(doc.data().paymentId)
      });
    });

    delay(200).then(() => setLoading(false));

  }, []);

  async function addNotes() {
    await updateDoc(doc(db, "payments", docId), {
      notes: notes,
    });
  }

  async function handleReceipt() {
    setLoading(true);

    var items = [];

    if (screenData.type === "Milestone Payment") {
      items.push({
        item: screenData.milestoneOrContract,
        description: "",
        quantity: 1,
        amount: screenData.amountNum * 100,
      });
    } else {
      var invoiceFields = screenData.invoiceFields;

      for (var i = 0; i < invoiceFields.length; i++) {
        items.push({
          item: invoiceFields[i].item,
          description: "",
          quantity: invoiceFields[i].quantity,
          amount: invoiceFields[i].balanceDue * 100,
        });
      }
    }

    const receipt = await axios({
      method: "POST",
      url: `${config.endpoint}/sendReceipt`,
      data: {
        userInfoDocId: screenData.userInfoDocId,
        clientCompany: screenData.clientCompany,
        clientEmail: screenData.clientEmail,
        items: items,
        amount: screenData.amountNum * 100,
      },
    });

    downloadPDF(receipt.data);
  }


  async function markAsPaid() {

    setLoading(true)

    try {
    
        await updateDoc(doc(db, "payments", docId), {
          paymentSuccess: true,
          fundsLocation: "Payout",
          datePaid: formatDate,
          datePaidNum: new Date(),
          status: "Paid",
          paymentSuccess: true,
        })

        const debtorDocRef = doc(db, 'debtors', screenData.debtorDocId);

        const debtorDoc = await getDoc(debtorDocRef)

        await updateDoc(doc(db, "debtors", screenData.debtorDocId), {
          amountCollected: screenData.amountNum + debtorDoc.data().amountCollected,
          status: "Paid Creditor Directly"
        })

        // create an area that auto-charges the client the contigency fee if gets marked as paid

    } catch (error) {
      console.log(error);
    }

    setLoading(false)

  }

  async function handleRefund() {
    /// need to do this still and the best way is probably just with an ACH transfer from the merchant to the client
    if(screenData.api_deck_payment_id && screenData.api_deck_invoiceId){
      const paymentUpdateParams = {
        id: screenData.api_deck_payment_id,
        payment: {
          status: "deleted",
          transaction_date: new Date(),
          total_amount: screenData?.amountNum,
          payment_method: screenData.paymentMethod,
        },
      };
      const paymentUpdateResponse = await axios({
        method: "PUT",
        url: `${config.endpoint}/apiDeckFunctions?consumerId=${currentUser.uid}&method=updatePayment`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await currentUser.getIdToken()}`,
        },
        data: paymentUpdateParams,
      }).catch(err => {
        if(err.response.status == 403){
          alert(err.response.data.message)
        }
        return
      });


      const params = {
        id: screenData.api_deck_invoiceId,
        invoice: {
          status: "deleted",
        },
      };
      const response = await axios({
        method: "PUT",
        url: `${config.endpoint}/apiDeckFunctions?consumerId=${currentUser.uid}&method=updateInvoice`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await currentUser.getIdToken()}`,
        },
        data: params,
      }).catch(err => {
        if(err.response.status == 403){
          alert(err.response.data.message)
        }
        return
      });
    }

    
    if (screenData.paymentMethod === "Card") {
      setLoading(true);

      const resp = await axios({
        method: "POST",
        url: `${config.endpoint}/propayFunctions-refund`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await currentUser.getIdToken()}`,
        },
        data: {
          transactionId: screenData.transactionId,
          userInfoDocId: screenData.userInfoDocId,
          amount: screenData.surchargedAmount,
        },
      });

      console.log(resp.data);

      setLoading(false);

      alert("Refund was successful");
    } else if (screenData.paymentMethod === "ACH") {
      setLoading(true);

      try {
        const resp = await axios({
          method: "POST",
          url: `${config.endpoint}/achFunctions-sendRefund`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await currentUser.getIdToken()}`,
          },
          data: {
            fundingSource: screenData.fundingDestination,
            fundingDestination: screenData.clientFundingSource,
            amountNum: screenData.amountNum,
          },
        });

        setLoading(false);
        alert("Refund was successful");
      } catch (e) {
        setLoading(false);
        alert(
          "Refund was not successful. Please ensure that you have enough in your current balance to refund this transaction."
        );
      }
    }
  }


  async function voidPayment() {

    
    if(!checkPermission("Mark Payment Void")){
      showToast(false,"Permission Denied")
      return;
    }

    let temp = screenData.reminderOptions;

    temp.enabled = false;

    if(screenData.api_deck_invoiceId){
      const params = {
        id: screenData.api_deck_invoiceId,
        invoice: {
          status: "void",
        },
      };
      const response = await axios({
        method: "PUT",
        url: `${config.endpoint}/apiDeckFunctions?consumerId=${currentUser.uid}&method=updateInvoice`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await currentUser.getIdToken()}`,
        },
        data: params,
      }).catch(err => {
        if(err.response.status == 403){
          alert(err.response.data.message)
        }
        return
      })
    }

    if (screenData.type === "Invoice Payment") {
      await updateDoc(doc(db, "payments", docId), {
        paymentVoided: true,
        voidDate: formatDate,
        reminderOptions: temp,
      });
    } else {
      setLoading(true);

      const q = query(
        collection(db, "escrowPayments"),
        where("paymentId", "==", screenData.paymentId),
        where("user", "==", screenData.user)
      );

      const querySnapshot = await getDocs(q);

      let escrowDocId = "";

      querySnapshot.forEach((doc) => {
        escrowDocId = doc.id;
      });

      await updateDoc(doc(db, "payments", docId), {
        paymentVoided: true,
        voidDate: formatDate,
        reminderOptions: temp,
      });

      await updateDoc(doc(db, "escrowPayments", escrowDocId), {
        paymentVoided: true,
        voidDate: formatDate,
      });

      setLoading(false);
    }
  }

  async function stopAutomation() {
    var temp = screenData.automationOptions;

    temp.enabled = false;

    await updateDoc(doc(db, "payments", docId), {
      automationOptions: temp,
    });

    toast.success("This automation has been turned off", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
      });

  }


  async function stopSubscription() {

    await updateDoc(doc(db, "payments", docId), {
      subscriptionEnabled: false,
    })

    toast.success("This subscription has been turned off", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
      })

  }

  async function getPaymentLink() {

    let paymentlink= ''
    if (screenData.type === "Milestone Payment")
    {
      paymentlink= `https://app.collectwise.org/milestonepayments/${paymentId}`
      navigator.clipboard.writeText(paymentlink)
    } else if (screenData.type === "Subscription Payment"){
      paymentlink= `https://app.collectwise.org/subscriptionpayments/${paymentId}`
      navigator.clipboard.writeText(paymentlink)
     } else {
       paymentlink= `https://app.collectwise.org/invoicepayments/${paymentId}`
       navigator.clipboard.writeText(paymentlink)
    }
    toast.success("Payment Link copied to clipboard", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
      });

  }




  return (
    <>
    <React.Fragment>
    <ToastContainer />
    <PlasmicPaymentInstance
      root={{ ref }}
      {...props}
      paymentType={screenData.type === "Invoice Payment" ? "Debt Payment" : screenData.type}
      paymentAmount={amount}
      createdDate1={screenData.dateCreated}
      createdDate2={screenData.dateCreated}
      paymentSuccessDate={screenData.datePaid}
      clientCompany={screenData.clientCompany}
      paymentMethod={screenData.paymentMethod}
      paymentLink={{
        onClick: () => getPaymentLink()
      }}
      paymentStatusStack={{
        children:
         screenData.paymentVoided ? <VoidStatusCard/> : screenData.status === "Paid" ? (
            <PaidStatusCard />
          ) : (
            <NotPaidStatusCard />
          ),
      }}
      editText={{
        onClick: () => {
          setEdit(!edit);
        },
      }}
      paymentNotes={{
        value: notes,
        placeholder: "Enter here",
        onChange: (e) => {
          setNotes(e.target.value);
        },
        onMouseLeave: (e) => {
          addNotes();
        },
      }}
      vertStack={
        (!Object.keys(screenData).length || loading) && {
          children: <LoadingSpinner />,
        }
      }
      receiptButton={{
        wrap: (node) => (screenData.status === "Paid" ? node : null),
        props: {
          onClick: () => handleReceipt(),
        },
      }}
      refundButton={{
        wrap: (node) => (screenData.status === "Paid" ? node : null),
        props: {
          onClick: () => handleRefund(),
        },
      }}
      markAsPaid={{
        wrap: (node) => (screenData.status === "Paid" ? null : node),
        props: {
          onClick: () => markAsPaid(),
        },
      }}
      voidPayment={{
        wrap: (node) =>
          screenData.status === "Paid" || screenData.paymentVoided
            ? null
            : node,
        props: {
          onClick: () => voidPayment(),
        },
      }}
      stopAutomation={{
        wrap: (node) => (automations ? node : null),
        props: {
          onClick: () => stopAutomation(),
        },
      }}
      editSubscription={{
        wrap: (node) => (screenData.type !== "Subscription Payment" ? null : screenData.subscriptionEnabled ? node : null),
        props: {
          onClick: () => setShowModal(true),
        },
      }}
    />
    </React.Fragment>
    <Modal show={showModal} onHide={handleClose}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Edit Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditSubscription subscriptionEnabled={subscriptionEnabled} setSubscriptionEnabled={setSubscriptionEnabled} subscriptionAmount={subscriptionAmount} subscriptionCycle={subscriptionCycle} setSubscriptionCycle={setSubscriptionCycle} currencyRef={currencyRef}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={modalSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const PaymentInstance = React.forwardRef(PaymentInstance_);

export default PaymentInstance;