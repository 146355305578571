// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicDeveloperModal } from "./plasmic/collect_wise_draft/PlasmicDeveloperModal";
import LoadingSpinner from "./LoadingSpinner";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext"
import { config } from "./constants";
import validator from 'validator';

function DeveloperModal_(props, ref) {

  const { currentUser } = useAuth();
  const [clientKey, setclientKey] = React.useState("")
  const [devClientKey, setdevClientKey] = React.useState("")
  const [webHookKey, setwebHookKey] = React.useState("")
  const [devWebHookKey, setdevWebHookKey] = React.useState("")
  const [showLoader, setshowLoader] = React.useState(false)

  const { setKeysFromModal, docId, showToast, setShowModal } = props;

  const isValidURL = (url) => {
    return validator.isURL(url, {
      require_protocol: true, // Require the URL to have a protocol (http/https).
      require_tld: true,      // Require the URL to have a top-level domain (e.g., com, org, net).
      require_host: true,     // Require the URL to have a host/domain name.
    });
  }

  const handleSave = async () => {
    try {

      if (!clientKey || !webHookKey || !devClientKey || !devWebHookKey) {
        showToast(false, "Please fill all fields")
        return;
      }
      
      if( !isValidURL(webHookKey) || !isValidURL(devWebHookKey) ) {
        showToast(false, "Please enter valid webhook url")
        return;
      }

      setshowLoader(true);

      const response = await axios({
        method: "POST",
        url: `${config.endpoint}/saveApplicationKeys`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${await currentUser.getIdToken()}`
        },
        data: {
          clientSecret: clientKey,
          webHookKey,
          devClientSecret : devClientKey,
          devWebHookKey,
          docId
        }
      })

      setKeysFromModal({ clientKey, webHookKey, devClientKey, devWebHookKey })
      showToast(true, "Keys saved successfully")
      setShowModal(false)

    } catch (error) {
      console.log(error)
      showToast(false, "Something went wrong")
    }
    setshowLoader(false);

  }



  return <React.Fragment>

    {showLoader ? <LoadingSpinner styles={{ height: "250px" }} />
      : <PlasmicDeveloperModal root={{ ref }} {...props}
        clientKey={{
          value: clientKey,
          onChange: (e) => {
            setclientKey(e.target.value)
          },

        }}

        devClientKey = {{
          value : devClientKey,
          onChange : e => setdevClientKey(e.target.value)
        }}

        webHookKey={{
          value: webHookKey,
          onChange: (e) => {
            setwebHookKey(e.target.value)
          },

        }}

        devWebHookKey = {{
          value : devWebHookKey,
          onChange : e => setdevWebHookKey(e.target.value)
        }}



        saveBtn={{
          onClick: () => {
            handleSave();
          }
        }}
      />
    }
  </React.Fragment>

}

const DeveloperModal = React.forwardRef(DeveloperModal_);

export default DeveloperModal;
