// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useEffect, useMemo} from "react";
import { PlasmicMilestoneEntry } from "./plasmic/collect_wise_draft/PlasmicMilestoneEntry";
import "../Input.css"
import CurrencyInput from "react-currency-input-field";
import DatePicker from "react-datepicker";
import { useAuth } from "../contexts/AuthContext";
import "react-datepicker/dist/react-datepicker.css";
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit } from "firebase/firestore"; 

function MilestoneEntry_(props, ref) {

const [titleContent, setTitleContent] = useState('')
const [startDate, setStartDate] = useState();
const [price, setPrice] = useState()

const {handleEscrowSubmit, milestones, handleSetMilestones} = useAuth()


useEffect(() => {



  for (var i = 0; i < milestones.length; i++){
    
    if (milestones[i].id === props.id){
      setTitleContent(milestones[i].description)

      setStartDate(milestones[i].dueDate)
      
      if (milestones[i].amount !== ''){
        setPrice(Number(milestones[i].amount.replace(/[^0-9\.-]+/g,"")))
      }
      

      break;
    }
    
    }


}, [])


function updateTitle(){
  
var temp = milestones

for (var i = 0; i < temp.length; i++){
  
if (temp[i].id === props.id){
  temp[i].description = titleContent
  break;
}

}

handleSetMilestones(temp)


}


function updateDate(){
  
  var temp = milestones
  
  for (var i = 0; i < temp.length; i++){
    
  if (temp[i].id === props.id){
    temp[i].dueDate = startDate
    break;
  }
  
  }
  
  handleSetMilestones(temp)

  
  }

  function updateAmount(value){
  
    var temp = milestones
    
    for (var i = 0; i < temp.length; i++){
      
    if (temp[i].id === props.id){
      
      temp[i].amount = value
      break;
    }
    
    }
    
    handleSetMilestones(temp)

    
    }


  return <PlasmicMilestoneEntry root={{
    ref
   }} {...props} milestoneInput={{
    value: titleContent,
    placeholder: "Enter here...",
    onChange: (e) => {
      setTitleContent(e.target.value)
    },
    onBlur: (e) => {
      updateTitle()
    }
  }}
  depositRectangle={{
    children: <CurrencyInput className="input"
    prefix="$"
    name="currencyInput"
    id="currencyInput"
    data-number-to-fixed="2"
    data-number-stepfactor="100"
    value={price}
    onValueChange={(e) => {
      setPrice(e)
    }}
    // value={value}
    placeholder="$ "
    // onChange={handleChange}
    // onBlur={handleOnBlur}
    allowDecimals
    decimalsLimit="2"
    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
    disableAbbreviations
    onBlur={(e) => {
      updateAmount(e.target.value)
   
    }}
  />
  }}
  dateBox={{
    children: <DatePicker className="dateBox2" selected={startDate} onChange={
      (date) => {
        setStartDate(date)
      }
    }
    onCalendarClose={() => {
      updateDate()
    }}
    />
  }}
  deleteMilestone={{
    onClick: () => handleSetMilestones(milestones.filter((x) => x.id !== props.id)),
    firstOne: props.number === "1."
    
  }}
  />;
}

// you can possibly change the value of the milestone entry array by looping through the array until you've found the object matching the id of the Milestone entry, and
// then just change the values of that object to the right input. You can do this maybe with a focus button or something like that, which is triggered when the mouse is not on the
// element anymore. 
//You can do this by creating a global state that is either true or false, and then performing these operations when that changes.
// you can have this setGlobalState inside of the handleEscrowSubmit button and have an await on it to make sure the values change first. 

const MilestoneEntry = React.forwardRef(MilestoneEntry_);

export default MilestoneEntry;
