// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicFolderUploaderHeader } from "./plasmic/collect_wise_draft/PlasmicFolderUploaderHeader";

function FolderUploaderHeader_(props, ref) {

  return <PlasmicFolderUploaderHeader root={{ ref }} {...props} 
  closeButton={{
    onClick: () => {
      props.setShowModal(false)
    }
  }}
  />;
}

const FolderUploaderHeader = React.forwardRef(FolderUploaderHeader_);

export default FolderUploaderHeader;
