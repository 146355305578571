// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState} from "react";
import ClickAwayListener from 'react-click-away-listener';
import { PlasmicCreateNewStack } from "./plasmic/collect_wise_draft/PlasmicCreateNewStack";

function CreateNewStack_(props, ref) {
  const [show, setShow] = useState(false)

  async function triggerEvent() {

    window.dataLayer.push({'event': 'create-button-clicked'})
    
    /// add any new code such as making a call via the API to enrich FB's data

  }

  const onClickAway = () => {
    setShow(false);
  }

  return <ClickAwayListener onClickAway={onClickAway}>
    <PlasmicCreateNewStack
  root={{ ref }} {...props} createNewButton={{
    onClick: () => {
      setShow(!show)
      // triggerEvent()
    }
  }}
  createNewElement={{
    postClick: show
  }}
  />
  </ClickAwayListener>;
}

const CreateNewStack = React.forwardRef(CreateNewStack_);

export default (CreateNewStack);
