import * as React from "react";
import { PlasmicSelectBizStructure } from "./plasmic/collect_wise_draft/PlasmicSelectBizStructure";
import Option from "./SelectBizStructure__Option";
import OptionGroup from "./SelectBizStructure__OptionGroup";

function SelectBizStructure_(props, ref) {
  const { plasmicProps, state } = PlasmicSelectBizStructure.useBehavior(
    props,
    ref
  );

  return <PlasmicSelectBizStructure {...plasmicProps} />;
}

const SelectBizStructure = React.forwardRef(SelectBizStructure_);

export default Object.assign(SelectBizStructure, {
  Option,
  OptionGroup,
  __plumeType: "select"
});
