// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useEffect, useMemo, useCallback, useRef} from "react";
import { PlasmicClientInstance } from "./plasmic/collect_wise_draft/PlasmicClientInstance";
import {useParams} from "react-router-dom"
import { db, storage } from "../firebase"
import { collection, addDoc, doc, setDoc, updateDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit } from "firebase/firestore"; 
import LoadingSpinner from "./LoadingSpinner";
import {useAuth} from "../contexts/AuthContext"
import ClientPaymentsCard from "./ClientPaymentsCard"
import { Button, Modal, Form } from "react-bootstrap"
import UpdateClientModal from "./UpdateClientModal"
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';


function ClientInstance_(props, ref) {
  const [screenData, setScreenData] = useState({})
  const [loading, setLoading] = useState(true)
  const [amount, setAmount] = useState(0)
  let { id } = useParams()
  const [notes, setNotes] = useState("")
  const [docId, setDocId] = useState("")
  const [paymentsData, setPaymentsData] = useState([])
  const [clientEmail, setClientEmail] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [contactFields, setContactFields] = useState([{name: '', phone: '', email: '', id: ""}])
  const history = useHistory()

  const {currentUser, clientSubmission, setClientSubmission, checkPermission, showToast} = useAuth()


  const handleClose = () => setShowModal(false);


  async function modalSubmit() {

    if (clientSubmission.contactName === '') {
      alert("Contact name is required")
    } else if (clientSubmission.email === '') {
      alert("Email is required")
    } else if (clientSubmission.company === '') {
      alert("Company name is required")
    } else {

      await updateDoc(doc(db, "clients", docId), {
        contactName: clientSubmission.contactName,
        email: clientSubmission.email,
        company: clientSubmission.company,
        invoice_identifier: clientSubmission.invoiceId.slice(0, 8).toUpperCase()
      })

      setShowModal(false)

    }
 
  }


  useEffect(() => {


    console.log('id:')
    console.log(id)

    const q = query(collection(db, "debtors"), where("id", "==", id), where("user", "==", currentUser.uid))

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
    querySnapshot.forEach((doc) => {
        
      console.log(doc.data())

      if (doc.data().contactFields) {
        setContactFields(doc.data().contactFields)
      }
    
        
        setAmount(doc.data().amountCollected)

        setScreenData(doc.data())
        setNotes(doc.data().notes)
        setDocId(doc.id)


      })
      setLoading(false)
    })

  
    }, [])



    useEffect(() => {

      if (Object.keys(screenData).length != 0 && screenData.debtorDocId) {
        
        const q1 = query(collection(db, "payments"), where("user", "==", currentUser.uid), orderBy("date", "desc"))
      
        const unsubscribe = onSnapshot(q1, (querySnapshot) => {
          var tempData = []
          querySnapshot.forEach((doc) => {

            if (doc.data().debtorDocId && (doc.data().debtorDocId === screenData.debtorDocId)){
              tempData.push(doc.data())
            }

          })
          setPaymentsData(tempData)
        })

      }
      
    }, [screenData])




    async function addNotes() {

      // await updateDoc(doc(db, "clients", docId), {
      //   notes: notes
      // } )
      
      }

  

  return (
  <>
  <PlasmicClientInstance root={{ ref }} {...props} debtorName={screenData.debtorName} debtorContact={ (screenData.email || screenData.phoneNumber) ? (screenData.email === "" ? screenData.phoneNumber : screenData.email) : (contactFields[0].email === "" ? contactFields[0].phone : contactFields[0].email) } amount={amount.toLocaleString('en-US', {style: 'currency', currency: 'USD', })} 
  emailsSent={(screenData.numberOfEmails + screenData.numberOfTexts) + ""}
  phoneCallsMade={screenData.numberOfCalls + ""}
  dateCreated={screenData.displayDate}
  vertStack={loading && {
    children: <></>
  }}
  viewInfo={{
    onClick: () => {
      if (!checkPermission("View Debtors")){
        showToast(false,"Permission Denied")
        return;
      }
      history.push(`/viewdebtor/${id}`)
    }
  }}
  editClient={{
    onClick : () => {

      if (!checkPermission("Edit Client")){
        showToast(false,"Permission Denied")
        return;
      }

      setClientSubmission({company: screenData.company, contactName: screenData.contactName, email: screenData.email, invoiceId: screenData.invoice_identifier ? screenData.invoice_identifier : ''})

      setShowModal(true)
    }
  }}
  paymentNotes={{
    value: notes,
    placeholder: "Enter here",
    onChange: (e) => {
     setNotes(e.target.value)
    },
    onMouseLeave: (e) => {
      addNotes()
    }
  }}
  paymentsStack={{
    children: 
    <>
    { paymentsData.map( x => {
      
      return <ClientPaymentsCard amount={x.oldAmount ? x.oldAmount : x.amount} status={x.status} paymentType={x.type === "Invoice Payment" ? "Debt Payment" : x.type} paymentDate={x.dateCreated} id={x.paymentDataId}/>
    })}
    </>
   
  }}
  />
   <Modal show={showModal} onHide={handleClose}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Edit Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdateClientModal />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={modalSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
  </>
  )


}


const ClientInstance = React.forwardRef(ClientInstance_);

export default ClientInstance;