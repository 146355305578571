// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useEffect, useRef} from "react";
import { PlasmicTopBar } from "./plasmic/collect_wise_draft/PlasmicTopBar";
import ReactDOM from "react-dom";
import { useAuth } from "../contexts/AuthContext";
import onClickOutside from "react-onclickoutside";
import { Link, useHistory } from "react-router-dom"
import { Button, Modal, Form } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import 'bootstrap/dist/css/bootstrap.min.css';


function TopBar_(props, ref) {
  const history = useHistory()
  // const [isOpen, setIsOpen] = useState(false)
  const { currentUser, isOpen, setOpen } = useAuth()
  const [open1, setOpenModal] = useState(false)
  const [show, setShow] = useState(false);


  return (
  <>
  <PlasmicTopBar  {...props} 
  settingsButton={{
    onClick: () => history.push("/settings/billing")
  }}
  profileStackInstance={{
    postClick: true,
  
  }}
  userNameText={currentUser.email}  
  />
  </>
  );
}


const TopBar = React.forwardRef(TopBar_);

export default TopBar_;


