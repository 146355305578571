import * as React from "react";
import { PlasmicAutomationSelect } from "./plasmic/collect_wise_draft/PlasmicAutomationSelect";
import Option from "./AutomationSelect__Option";
import OptionGroup from "./AutomationSelect__OptionGroup";

function AutomationSelect_(props, ref) {
  const { plasmicProps, state } = PlasmicAutomationSelect.useBehavior(
    props,
    ref
  );

  return <PlasmicAutomationSelect {...plasmicProps} />;
}

const AutomationSelect = React.forwardRef(AutomationSelect_);

export default Object.assign(AutomationSelect, {
  Option,
  OptionGroup,
  __plumeType: "select"
});
