// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useEffect} from "react";
import { PlasmicOnboardingBusinessDetailsDocument } from "./plasmic/collect_wise_draft/PlasmicOnboardingBusinessDetailsDocument";
import axios from "axios"
import LoadingSpinner2 from "./LoadingSpinner2";
import LoadingSpinner from "./LoadingSpinner";
import { useHistory } from "react-router-dom";
import {useAuth} from "../contexts/AuthContext"
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit } from "firebase/firestore"; 
import {db} from "../firebase"
import { config } from './constants'

function OnboardingBusinessDetailsDocument_(props, ref) {
 
  const hiddenFileInput = React.useRef(null);
  const hiddenFileInput2 = React.useRef(null);

  const {tempUrl, setTempUrl, currentUser, onboardModal, setOnboardModal} = useAuth()
  const [licenseCheck, setLicenseCheck] = useState(false)
  const [idCheck, setIdCheck] = useState(false)
  const [type, setType] = useState('')
  const [error, setError] = useState(false)
  const [controllerContentError, setControllerContentError] = useState(false)
  const [businessContentError, setBusinessContentError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [uploadControllerSuccess, setUploadControllerSuccess] = useState(false)
  const [uploadBusinessSuccess, setUploadBusinessSuccess] = useState(false)
  const [submitError, setSubmitError] = useState(false)


  const history = useHistory()

useEffect(() => {

if (!Object.keys(tempUrl).length){
setLoading(true)


const q = query( collection(db, "userInfo"), where("user", "==", currentUser.uid) )

const unsubscribe = onSnapshot(q, (querySnapshot) => {
  
  querySnapshot.forEach((doc) => {
    
    setTempUrl({customerUrl: doc.data().dwollaUrl, docId: doc.id, verifyType: doc.data().verifyType, owners: doc.data().owners})
     
    console.log(tempUrl)

  });

})

setLoading(false)
}

}, [])



  const handleChange = async (event) => {
    const formData = new FormData();
    const file = event.target.files[0];
    const fileName = event.target.files[0].name

    function convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
       reader.onerror = reject;
      });
    }

   
    formData.append("file", event.target.files[0], {
      filename: event.target.files[0].name,
      contentType: event.target.files[0].type,
      knownLength: event.target.files[0].size,
    });


  


if (!(event.target.files[0].type === 'image/png' || event.target.files[0].type === 'image/jpeg' )){
  setControllerContentError(true)
} else {
  
  const b64 = await convertFileToBase64(file);

  const data = {
    file: b64.split(`data:${event.target.files[0].type};base64,`)[1],
    filename: event.target.files[0].name,
    contentType: event.target.files[0].type,
    knownLength: event.target.files[0].size,
    customerUrl: tempUrl.customerUrl,
    documentType: type,
  };



  try {

    setLoading(true)
    setError(false)
    setControllerContentError(false)

    const status =  await axios({
      method: "POST",
      url: `${config.endpoint}/onboardingFunctions-handleBizDocument`,
      data,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${await currentUser.getIdToken()}`
      }
    })
    
    setLoading(false)

    setUploadControllerSuccess(true)    

  } catch (e){
    setLoading(false)
    alert("An error occured while uploading your file. Please ensure that your file meets all of the requirements and try again.")
  }


}

  };


  const handleChange2 = async (event) => {
    const formData = new FormData();
    const file = event.target.files[0];
    const fileName = event.target.files[0].name

    function convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
       reader.onerror = reject;
      });
    }

   
    formData.append("file", event.target.files[0], {
      filename: event.target.files[0].name,
      contentType: event.target.files[0].type,
      knownLength: event.target.files[0].size,
    });



if (!(event.target.files[0].type === 'image/png' || event.target.files[0].type === 'image/jpeg' )){
  setBusinessContentError(true)
} else {
  
  const b64 = await convertFileToBase64(file);

  const data = {
    file: b64.split(`data:${event.target.files[0].type};base64,`)[1],
    filename: event.target.files[0].name,
    contentType: event.target.files[0].type,
    knownLength: event.target.files[0].size,
    customerUrl: tempUrl.customerUrl,
    documentType: "other"
  };



  try {

    setLoading(true)
    setBusinessContentError(false)

    const status =  await axios({
      method: "POST",
      url: `${config.endpoint}/onboardingFunctions-handleBizDocument`,
      data,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${await currentUser.getIdToken()}`
      }
    })
    
    setLoading(false)

    setUploadBusinessSuccess(true)

  } catch (e){
    setLoading(false)
    alert("An error occured while uploading your file. Please ensure that your file meets all of the requirements and try again.")
  }


}

  };




  return (
  <> 
  { onboardModal ? <LoadingSpinner/> : <PlasmicOnboardingBusinessDetailsDocument root={{ ref }} {...props}  vertStack={ loading && {
    children: <LoadingSpinner2/>
  }} 
  controllerStack={{
    wrap: node => tempUrl.verifyType === "verify-with-document" || tempUrl.verifyType === "verify-controller-and-business-with-document" ? node : null
  }}
  businessStack={{
    wrap: node => tempUrl.verifyType === "verify-business-with-document" || tempUrl.verifyType === "verify-controller-and-business-with-document" ? node : null
  }}
  uploadController={{
    onClick: () =>  {

      if (type === ''){
        setError(true)
      } else {
        hiddenFileInput.current.click()
      }
      
    }
  }}
  uploadControllerSuccess={{
    wrap: node => uploadControllerSuccess ? node : null,
  }}
  licenseCheck={{
    isChecked: licenseCheck,
    onChange: (e) => {
      setType('license')
      setLicenseCheck(!licenseCheck)
      setIdCheck(false)
    },
  }}
  idCheck={{
    isChecked: idCheck,
    onChange: (e) => {
      setType('idCard')
      setIdCheck(!idCheck)
      setLicenseCheck(false)
    },
  }}
  errorText={{
    wrap: node => error ? node : null
  }}
  controllerContentError={{
    wrap: node => controllerContentError ? node : null
  }}
  businessContentError={{
    wrap: node => businessContentError ? node : null
  }}
  uploadBusiness={{
    onClick: () => hiddenFileInput2.current.click()
  }}
  uploadBusinessSuccess={{
    wrap: node => uploadBusinessSuccess ? node : null,
  }}
  submitError={{
    wrap: node => submitError ? node : null,
  }}
  submitButton={{
    onClick: () => {

      setLoading(true)

      if (tempUrl.verifyType === "verify-with-document" ){

        if (uploadControllerSuccess){

          var proceed = true

          for (var i = 0; i < tempUrl.owners.length; i++) {
           
           

            if (tempUrl.owners[i].ownerVerification !== "verified"){

              var proceed = false
  
              setTempUrl({ownerUrl: tempUrl.owners[i].ownerUrl, name: tempUrl.owners[i].name, docId: tempUrl.docId, owners: tempUrl.owners, customerUrl: tempUrl.customerUrl })

              if (tempUrl.owners[i].ownerVerification === "incomplete"){
                history.push("/verify-owners")
               
                break
              } else {
                history.push("/verify-owners-document")
                break
              }

            }

          } 

          if (proceed){
            history.push("/onboarding-businessdetails-documentreceived")
          }


        } else {
          setSubmitError(true)
        }
       

      } else if (tempUrl.verifyType === "verify-business-with-document"){

        if (uploadBusinessSuccess){


          var proceed = true

          for (var i = 0; i < tempUrl.owners.length; i++) {
           
            if (tempUrl.owners[i].ownerVerification !== "verified"){

              var proceed = false
  
              setTempUrl({ownerUrl: tempUrl.owners[i].ownerUrl, name: tempUrl.owners[i].name, docId: tempUrl.docId, owners: tempUrl.owners, customerUrl: tempUrl.customerUrl })

              if (tempUrl.owners[i].ownerVerification === "incomplete"){
                history.push("/verify-owners")
                break
              } else {
                history.push("/verify-owners-document")
                break
              }

            }

          } 

          if (proceed){
            history.push("/onboarding-businessdetails-documentreceived")
          }


        } else {
          setSubmitError(true)
        }


      } else if (tempUrl.verifyType === "verify-controller-and-business-with-document") {

        if (uploadControllerSuccess && uploadBusinessSuccess){

          var proceed = true

          for (var i = 0; i < tempUrl.owners.length; i++) {
           
            if (tempUrl.owners[i].ownerVerification !== "verified"){

              var proceed = false
  
              setTempUrl({ownerUrl: tempUrl.owners[i].ownerUrl, name: tempUrl.owners[i].name, docId: tempUrl.docId, owners: tempUrl.owners, customerUrl: tempUrl.customerUrl })

              if (tempUrl.owners[i].ownerVerification === "incomplete"){
                history.push("/verify-owners")
                break
              } else {
                history.push("/verify-owners-document")
                break
              }

            }

          } 

          if (proceed){
            history.push("/onboarding-businessdetails-documentreceived")
          }

          
        } else {
          setSubmitError(true)
        }

      }

    }
  }}
  /> }
  <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{display: 'none'}}
      /> 
  <input
        type="file"
        ref={hiddenFileInput2}
        onChange={handleChange2}
        style={{display: 'none'}}
      /> 
  </>)
}

const OnboardingBusinessDetailsDocument = React.forwardRef(
  OnboardingBusinessDetailsDocument_
);

export default OnboardingBusinessDetailsDocument;
