// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useRef, useEffect} from "react";
import { PlasmicViewDebtorMasterUser } from "./plasmic/collect_wise_draft/PlasmicViewDebtorMasterUser";
import CurrencyInput from "react-currency-input-field";
import "../Input.css"
import { db, storage } from "../firebase"
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit, updateDoc } from "firebase/firestore";
import { v4 } from "uuid";
import { ref, uploadBytes, getDownloadURL, uploadBytesResumable, listAll, list, getStorage} from "firebase/storage";
import { useAuth } from "../contexts/AuthContext";
import LoadingSpinner from "./LoadingSpinner";
import Moment from 'moment';
import { useHistory } from 'react-router-dom';
import {useParams} from "react-router-dom"
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from 'react-toastify';
import CreateSubscriptionModal from "./CreateSubscriptionModal"
import CreateSettlementModal from "./CreateSettlementModal";
import CreditorPayoutModal from "./CreditorPayoutModal"
import { Button, Modal } from "react-bootstrap"
import axios from "axios";
import { config } from './constants'


function MasterViewDebtor_(props, ref1) {

  const [debtorName, setDebtorName] = useState('')
  const [debtAge, setDebtAge] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [address1, setAddress1] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [creditorName, setCreditorName] = useState('')
  const [debtSituation, setDebtSituation] = useState('')
  const [error, setError] = useState('')
  const [productNameError, setProductNameError] = useState('')
  const [debtorNameError, setDebtorNameError] = useState('')
  const [debtAmountError, setDebtAmountError] = useState('')
  const [debtAgeError, setDebtAgeError] = useState('')
  const [debtorPhoneError, setDebtorPhoneError] = useState('')
  const [debtorEmailError, setDebtorEmailError] = useState('')
  const [creditorNameError, setCreditorNameError] = useState('')
  const [uploadContractFile, setUploadContractFile] = useState(null)
  const [uploadInvoiceFile, setUploadInvoiceFile] = useState(null)
  const [uploadOtherFile, setUploadOtherFile] = useState(null)
  const [contractFile, setContractFile] = useState(null)
  const [invoiceFile, setInvoiceFile] = useState(null)
  const [otherFile, setOtherFile] = useState(null)
  const { currentUser, showToast, checkPermission } = useAuth()
  const [initialDebtAmount, setInitialDebtAmount] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [showSettleModal, setShowSettleModal] = useState(false)
  const [showCreditorModal, setShowCreditorModal] = useState(false)
  const [loading, setLoading] = useState(true)
  let { id } = useParams()
  const formatDate = Moment().format("MMM Do, YYYY")
  const history = useHistory()
  const [productName, setProductName] = useState('')
  const [dob, setDob] = useState('')
  const [docId, setDocId] = useState('')
  const [debtorData, setDebtorData] = useState()
  const [collectionsEmail, setCollectionsEmail] = useState("")
  const [numberOfDebtors, setNumberofDebtors] = useState(0)
  const [merchantName, setMerchantName] = useState('')
  const [userInfoDocId, setUserInfoDocId] = useState('')
  const [userInfo, setUserInfo] = useState('')
  const [isAgency, setIsAgency] = useState(false)
 
const currencyInput = useRef(null)



const handleBeforeUnload = (e) => {
  e.preventDefault();
  const message =
    "Are you sure you want to leave? All provided data will be lost.";
  e.returnValue = message;
  return message;
}


const handleClose = () => setShowModal(false)



  useEffect( async () => {


     const q = query(collection(db, "debtors"), where("id", "==", id) )

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
    querySnapshot.forEach( async (doc) => {
        
      setDebtorData(doc.data())
      setInitialDebtAmount(Number(doc.data().debtAmount.substring(1).replace(/,/g, "")))
      setDebtorName(doc.data().debtorName)

      setPhoneNumber(doc.data().contactFields ? doc.data().contactFields[0].phone : doc.data().phoneNumber)
      setEmail(doc.data().contactFields ? doc.data().contactFields[0].email : doc.data().email)

      setAddress1(doc.data().address1)
      setCity(doc.data().city)
      setState(doc.data().state)
      setZip(doc.data().zip)
      setCreditorName(doc.data().creditorName)
      setDebtSituation(doc.data().debtSituation)
      setProductName(doc.data().productName)
      setContractFile(doc.data().contractFile)
      setInvoiceFile(doc.data().invoiceFile)
      setOtherFile(doc.data().otherFile)
      setDocId(doc.id)

      try {
      setDebtAge(doc.data().debtAge.toDate())
      } catch (e){

      }

      
      try{
        setDob(doc.data().dob.toDate())
      } catch (e){

      }

      setLoading(false)


      const q2 = query(
        collection(db, "userInfo"),
        where("user", "==", doc.data().user)
      )
  
      const querySnapshot2 = await getDocs(q2);

      querySnapshot2.forEach((doc2) => {

        setMerchantName(doc2.data().merchantName)
        setCollectionsEmail(doc2.data().collectionsEmail ? doc2.data().collectionsEmail : "")
        setUserInfoDocId(doc2.id)
        setUserInfo(doc2.data())
        setIsAgency(doc2.data().isAgency ? doc2.data().isAgency : false)

      })

      const q3 = query(
        collection(db, "debtors"),
        where("user", "==", doc.data().user)
      )

      const querySnapshot3 = await getDocs(q3);

      setNumberofDebtors(querySnapshot3.size)


      })
      
    })
  
    

  }, [])


  async function handleDownloadUrl (source, path) {

    const parts = path.split('/');
    const lastPart = parts[parts.length - 1]
    const [fileName] = lastPart.split('.')

    const image = await fetch(source)
    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)
  
    const link = document.createElement('a')
    link.href = imageURL
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  
  }

  
  async function handleDownload(path) {

    console.log(path)

    getDownloadURL(ref(storage, path))
    .then((url) => {
   
  
      handleDownloadUrl(url, path)
  
    })
    .catch((error) => {
      // Handle any errors
    });

    // const storage = getStorage();
    // getDownloadURL(ref(storage, path))
    //   .then((url) => {
    //     // `url` is the download URL for 'images/stars.jpg'
    
    //     // This can be downloaded directly:
    //     const xhr = new XMLHttpRequest();
    //     xhr.responseType = 'blob';
    //     xhr.onload = (event) => {
    //       const blob = xhr.response;
    //     };
    //     xhr.open('GET', url);
    //     xhr.send();
    //     console.log('here')
    
    //   })
    //   .catch((error) => {
    //     // Handle any errors
    //   });

  }




  async function uploadFile(upload) {

    if (upload == null) return '';

    const path = `debtorFile/${currentUser.uid}/${upload.name + v4()}`

    const imageRef = ref(storage, path);

    uploadBytes(imageRef, upload).then((snapshot) => {


    });

    return path

  }



  return loading ? 
  <PlasmicViewDebtorMasterUser root={{ ref1 }} {...props} vertStack={{children: <LoadingSpinner/>}} /> : 
  <>
  <PlasmicViewDebtorMasterUser root={{ ref1 }} {...props}
  createSubscriptionButton={{
    onClick: () => {
      setShowModal(true)
    }
  }}
  createSettlementButton={{
    onClick: () => {
      setShowSettleModal(true)
    }
  }}
  sendCreditorPayout={{
    onClick: () => {
      setShowCreditorModal(true)
    }
  }}
  debtorName={{
    value: debtorName,
    onChange: (e) => {
      setDebtorName(e.target.value)
    }
  }}
  debtAmount={{
    children: <CurrencyInput ref={currencyInput} className="input"
    prefix="$"
    name="currencyInput"
    id="currencyInput"
    data-number-to-fixed="2"
    data-number-stepfactor="100"
    // value={value}
    placeholder="$ "
    // onChange={handleChange}
    // onBlur={handleOnBlur}
    allowDecimals
    decimalsLimit="2"
    defaultValue={initialDebtAmount}
    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
    disableAbbreviations
  />
  }}
  debtAge={{
    children: <DatePicker className="dateBox2" selected={debtAge} onChange={(date) => setDebtAge(date)} />
  }}
  phoneNumber={{
    value: phoneNumber,
    onChange: (e) => {
      setPhoneNumber(e.target.value)
    }
  }}
  email={{
    value: email,
    onChange: (e) => {
      setEmail(e.target.value)
    }
  }}
  address1={{
    value: address1,
    onChange: (e) => {
      setAddress1(e.target.value)
    }
  }}
  city={{
    value: city,
    onChange: (e) => {
      setCity(e.target.value)
    }
  }}
  state={{
    value: state,
    onChange: (e) => {
      setState(e.target.value)
    }
  }}
  zip={{
    value: zip,
    onChange: (e) => {
      setZip(e.target.value)
    }
  }}
  creditorName={{
    value: creditorName,
    onChange: (e) => {
      setCreditorName(e.target.value)
    }
  }}
  debtSituation={{
    value: debtSituation,
    onChange: (e) => {
      setDebtSituation(e.target.value)
    }
  }}
  productName={{
    value: productName,
    onChange: (e) => {
      setProductName(e.target.value)
    }
  }}
  uploadContractFile={{
    onClick: () => {

      console.log('tried')

      var input = document.createElement('input');
      input.type = 'file';

      input.onchange = e => {
        setUploadContractFile(e.target.files[0])
      }

      input.click();

    }
  }}
  downloadContractFile={{
    wrap: (node) => contractFile ? node : null,
    props: {
      onClick: async () => {

        await handleDownload(contractFile)

      }
    }
  }}
  contractOr={{
    wrap: (node) => contractFile ? node : null,
  }}
  downloadInvoiceFile={{
    wrap: (node) => invoiceFile ? node : null,
    props: {
      onClick: async () => {

        handleDownload(invoiceFile)

      }
    }
  }}
  invoiceOr={{
    wrap: (node) => invoiceFile ? node : null,
  }}
  downloadOtherFile={{
    wrap: (node) => otherFile ? node : null,
    props: {
      onClick: async () => {

        handleDownload(otherFile)
       
      }
    }
  }}
  otherOr={{
    wrap: (node) => otherFile ? node : null,
  }}
  uploadInvoiceFile={{
    onClick: () => {

      var input = document.createElement('input');
      input.type = 'file';

      input.onchange = e => {
        setUploadInvoiceFile(e.target.files[0])
      }

      input.click();

    }
  }}
  uploadOtherFile={{
    onClick: () => {

      var input = document.createElement('input');
      input.type = 'file';

      input.onchange = e => {
        setUploadOtherFile(e.target.files[0])
      }

      input.click();

    }
  }}
  errorText={{
    wrap: (node) => error ? node : null,
  }}
  productError={{
    wrap: (node) => productNameError ? node : null,
  }}
  debtorNameError={{
    wrap: (node) => debtorNameError ? node : null,
  }}
  debtAmountError={{
    wrap: (node) => debtAmountError ? node : null,
  }}
  debtAgeError={{
    wrap: (node) => debtAgeError ? node : null,
  }}
  debtorPhoneError={{
    wrap: (node) => debtorPhoneError ? node : null,
  }}
  debtorEmailError={{
    wrap: (node) => debtorEmailError ? node : null,
  }}
  creditorNameError={{
    wrap: (node) => creditorNameError ? node : null,
  }}
  contractFileStack={{
    wrap: (node) => uploadContractFile ? node : null,
  }}
  invoieFileStack={{
    wrap: (node) => uploadInvoiceFile ? node : null,
  }}
  otherFileStack={{
    wrap: (node) => uploadOtherFile ? node : null,
  }}
  dob={{
    children: <DatePicker className="dateBox2" selected={dob} onChange={(date) => setDob(date)} />
  }}
  step1={collectionsEmail === "" ? "Click" : ("Email: " + collectionsEmail) }
  step2={(numberOfDebtors > 5) ? "Link the email account with Sendgrid's custom SMTP" : "Link the email account with standard connection"}
  createEmail={{
    // add in the userInfo doc to the state so you can determine this
    wrap: node => collectionsEmail === "" ? node : null,
    props: {
     onClick: async () => {

      setLoading(true)

      var resp = await axios({
        method: "POST",
        url: `${config.endpoint}/crmFunctions-createCollectionEmail`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${await currentUser.getIdToken()}`
        },
        data: {
          creditorName: merchantName,
          debtSituation: debtSituation,
          productName: productName,
          user: debtorData.user
        }
      })

      window.location.reload()

      setLoading(false)

    }
  }
  }}
  step3={userInfo.autoEnrollDebtors ? "Debtors are automatically enrolled in workflows" : "Click"}
  enrollDebtors={{
    wrap: node => userInfo.autoEnrollDebtors ? null : node,
    props: {
    onClick: async () => {

      setLoading(true)

      var resp = await axios({
        method: "POST",
        url: `${config.endpoint}/crmFunctions-enrollInWorkflow`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${await currentUser.getIdToken()}`
        },
        data: {
          collectionsEmail: collectionsEmail,
          merchantName: merchantName,
          userInfoDocId: userInfoDocId,
          isAgency: isAgency
        }
      })

      setLoading(false)

    }
  }
  }}
  creditorName2={"Creditor: " + merchantName}
  creditorName3={"Creditor: " + merchantName}
  contractFile={uploadContractFile ? uploadContractFile.name : null}
  invoiceFile={uploadInvoiceFile ? uploadInvoiceFile.name : null}
  otherFile={uploadOtherFile ? uploadOtherFile.name : null}
  submitButton={{
    onClick: async () => {
      if (!checkPermission("Edit Debtor")){
        showToast(false,"Permission Denied")
        return;
      }
      if (debtorName === '') {
        setDebtorNameError(true)
      } else {
        setDebtorNameError(false)
      }

      if(currencyInput.current.value === '') {
        setDebtAmountError(true)
      } else {
        setDebtAmountError(false)
      }


      if (debtAge === '') {
        setDebtAgeError(true)
      } else {
        setDebtAgeError(false)
      }


      if (productName === '') {
        setProductNameError(true)
      } else {
        setProductNameError(false)
      }

      if (creditorName === '') {
        setCreditorNameError(true)
      } else {
        setCreditorNameError(false)
      }

      if (phoneNumber === '' && email === '') {
        setDebtorPhoneError(true)
        setDebtorEmailError(true)
      } else {
        setDebtorPhoneError(false)
        setDebtorEmailError(false)
      }

  

      if (debtorName === '' || currencyInput.current.value === '' || debtAge === '' || productName === '' || (phoneNumber === '' && email === '') || creditorName === '') {
        setError(true)
      } else {
        setError(false)


        var id = v4()


        toast.success("Debtor successfully updated", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
          })

        window.addEventListener("beforeunload", handleBeforeUnload);

        const debtors = await updateDoc(doc(db, "debtors", docId), {
          user: currentUser.uid,
          debtorName: debtorName,
          debtAmount: currencyInput.current.value,
          debtAge: debtAge,
          phoneNumber: phoneNumber,
          email: email,
          address1: address1,
          city: city,
          state: state,
          zip: zip,
          creditorName: creditorName,
          debtSituation: debtSituation,
          contractFile: uploadContractFile ? (await uploadFile(uploadContractFile)) : null,
          invoiceFile: uploadInvoiceFile ? (await uploadFile(uploadInvoiceFile)) : null,
          otherFile: uploadOtherFile ? (await uploadFile(uploadOtherFile)) : null,
          date: serverTimestamp(),
          amountCollected: 0,
          numberOfEmails: 0,
          numberOfTexts: 0,
          numberOfCalls: 0,
          displayDate: formatDate,
          id: id,
          productName: productName,
          dob: dob
        })

        history.push(`/debtor/${id}`)

        window.removeEventListener("beforeunload", handleBeforeUnload);

        
        
        setLoading(false)

      }

  
    }
  }}
  />
   {  <Modal show={showModal} onHide={handleClose}
    backdrop="static"
    aria-labelledby="contained-modal-title-vcenter"
    centered>
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">Create Payment Plan</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <CreateSubscriptionModal setShowModal={setShowModal} docId={docId} debtorData={debtorData}/>
    </Modal.Body>
  </Modal> }
  {  <Modal show={showSettleModal} onHide={() => setShowSettleModal(false)}
    backdrop="static"
    aria-labelledby="contained-modal-title-vcenter"
    centered>
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">Create Settlement</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <CreateSettlementModal setShowSettleModal={setShowSettleModal} docId={docId} debtorData={debtorData}/>
    </Modal.Body>
  </Modal> }
  {  <Modal show={showCreditorModal} onHide={() => setShowCreditorModal(false)}
    backdrop="static"
    aria-labelledby="contained-modal-title-vcenter"
    centered>
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">Creditor Payout</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <CreditorPayoutModal setShowCreditorModal={setShowCreditorModal} docId={docId} debtorData={debtorData} userInfoDocId={userInfoDocId}/>
    </Modal.Body>
  </Modal> }
  </>
}

const MasterViewDebtor = React.forwardRef(MasterViewDebtor_);

export default MasterViewDebtor;