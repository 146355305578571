import * as React from "react";
import { PlasmicSelectBizClass } from "./plasmic/collect_wise_draft/PlasmicSelectBizClass";
import Option from "./SelectBizClass__Option";
import OptionGroup from "./SelectBizClass__OptionGroup";

function SelectBizClass_(props, ref) {
  const { plasmicProps, state } = PlasmicSelectBizClass.useBehavior(props, ref);
  return <PlasmicSelectBizClass {...plasmicProps} />;
}

const SelectBizClass = React.forwardRef(SelectBizClass_);

export default Object.assign(SelectBizClass, {
  Option,
  OptionGroup,
  __plumeType: "select"
});
