// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useEffect, useRef} from "react";
import { PlasmicProfileStack } from "./plasmic/collect_wise_draft/PlasmicProfileStack";
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import ReactDOM from "react-dom";
import ClickAwayListener from 'react-click-away-listener';


function ProfileStack_(props, ref) {
  const [state, setState] = useState(false)
  const [show, setShow] = useState(false)

  const { currentUser, logout } = useAuth()
  const history = useHistory()

  const onClickAway = () => {
    setShow(false)
  }


  async function handleLogout(){
    try {
      await logout()
      history.push("/login")
    } catch {
      alert("Failed to log out")
    }
  }


  return <ClickAwayListener onClickAway={onClickAway}>
  <PlasmicProfileStack root={{ ref }} {...props} 
  profileButton={{
    onClick: () => setShow(!show)
  }}
  signOutButton={{
    wrap: node => show ? node : null,
    props: {
      onClick: () =>  handleLogout()
    }
  }} />
  </ClickAwayListener>
}

const ProfileStack = React.forwardRef(ProfileStack_);

export default ProfileStack;
