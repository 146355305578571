import * as React from "react";
import { PlasmicSelectBizClass__OptionGroup } from "./plasmic/collect_wise_draft/PlasmicSelectBizClass__OptionGroup";

function SelectBizClass__OptionGroup(props) {
  const { plasmicProps } =
    PlasmicSelectBizClass__OptionGroup.useBehavior(props);
  return <PlasmicSelectBizClass__OptionGroup {...plasmicProps} />;
}

export default Object.assign(SelectBizClass__OptionGroup, {
  __plumeType: "select-option-group"
});
