import React, { useEffect, useState } from 'react';
import 'filepond/dist/filepond.min.css';
import { config } from './constants';
import { useAuth } from '../contexts/AuthContext';
import { Dashboard } from '@uppy/react';
import Uppy from '@uppy/core';
import FirebaseCloudStorage from './FirebaseCloudStorageUploader'
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/progress-bar/dist/style.css';
import axios from "axios";
import LoadingSpinner from './LoadingSpinner';
import LoadingSpinner4 from "./LoadingSpinner4WithText";
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit, updateDoc } from "firebase/firestore";
import { db } from "../firebase"
import { toast } from "react-toastify";


function FolderUploader(props) {
  const { currentUser } = useAuth();
  const [files, setFiles] = useState([]);
  const [token, setToken] = useState(null);
  const [loadingFiles, setLoadingFiles] = useState([])
  const [successFiles, setSuccessFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [querySnapshot, setQuerySnapshot] = useState(null)
  
    
  useEffect(() => {
    console.log(currentUser.uid)
    const getQuerySnap = async () => {
      const q = query(
        collection(db, "userInfo"),
        where("user", "==", currentUser.uid)
      );
      const querySnapshot = await getDocs(q)
      return
    }
    setQuerySnapshot(getQuerySnap())
  }, []); // Empty dependency array ensures this effect runs only once


  const uploadToFirestore = async (filePath) => {
    const newPath = filePath.toLowerCase().replace(/[\s_]+/g, '').replace(/[^\w]/g, '')

    const q = query(
      collection(db, "debtors"),
      where("user", "==", currentUser.uid)
    )
    const debtorSnapshot = await getDocs(q)

    let found = false;
    for (const doc of debtorSnapshot.docs) {
        const debtorData = doc.data()

        const newName = debtorData.debtorName.toLowerCase().replace(/[\s_]+/g, '').replace(/[^\w]/g, '');
        const client_account_number = debtorData.client_account_number ? debtorData.client_account_number.replace(/[\s_]+/g, '').replace(/[^\w]/g, '') : "";
        const regex1 = new RegExp(newName);
        const regex2 = new RegExp(client_account_number);
        let found = false;

      if (client_account_number === "") {

        if (regex1.test(newPath)) {
            found = true;
            try {
              if (debtorData.files) {
                // Appending the new file to the existing one
                const newFiles = debtorData.files;
                newFiles.push(filePath);
                await updateDoc(doc.ref, {
                    files: newFiles
                });
            } else {
                await updateDoc(doc.ref, {
                    files: [filePath]
                });
            }
            } catch (error) {
                console.error(error.toString());
                return false;
            }
            return true
        }

      } else {

        if (regex1.test(newPath) || regex2.test(newPath)) {
            found = true;
            try {
              if (debtorData.files) {
                // Appending the new file to the existing one
                const newFiles = debtorData.files;
                newFiles.push(filePath);
                await updateDoc(doc.ref, {
                    files: newFiles
                });
            } else {
                await updateDoc(doc.ref, {
                    files: [filePath]
                });
            }
            } catch (error) {
                console.error(error.toString());
                return false;
            }
            return true
        }

      }
    }

    return false
   
  }

  

  const uppy = new Uppy()
      .use(FirebaseCloudStorage, { currentUserID: currentUser.uid })
      .on('upload-progress', (file, progress) => {

        setLoading(true)

        console.log('Upload progress:', progress);
        // Update the files state with the new progress
        setFiles(prevFiles =>
          prevFiles.map(f =>
            f.id === file.id ? { ...f, progress: progress.progress } : f
          )
        )
      })
      .on('upload-success', async (filePath, file, lastOne) => {

        const found = await uploadToFirestore(filePath)

        if (lastOne) {

          setLoading(false)

          if(props.showModal){
            props.setShowModal(false)
          }

          toast.success("Files Uploaded Successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
            })
          
        }
     

        if(!found){
          console.log("Could not find debtor with file", filePath)
        } else{
          console.log("Finished uploading ", file.name)
        }

      })
      .on('upload-error', (file, error) => {
        console.error('Upload error:', error);
        // Handle the upload error
        setFiles(prevFiles =>
          prevFiles.map(f =>
            f.id === file.id ? { ...f, error } : f
          ))
      })
  


  return loading ? <LoadingSpinner4 text={"Uploading Files. Please Wait"}/> : <Dashboard uppy={uppy} proudlyDisplayPoweredByUppy={false}/>;
    /*
    <div className="App">
        <FilePond
          files={files}
          onupdatefiles={setFiles}
          allowMultiple={true}
          maxFiles={3}
          server={{
            process: async (
              fieldName,
              file,
              metadata,
              load,
              error,
              progress,ƒ
              abort
            ) => {
              const formData = new FormData();
              formData.append(fieldName, file, file.name);
              const request = new XMLHttpRequest();
              request.open(
                "POST",
                `${config.endpoint}/crmFunctions-folderUpload`
              );
              request.setRequestHeader(
                "Authorization",
                // make sure to change and enforce your policy
                `Bearer ${await currentUser.getIdToken()}`
              );
              request.upload.onprogress = (e) => {
                progress(e.lengthComputable, e.loaded, e.total);
              };
              request.onload = function () {
                if (request.status >= 200 && request.status < 300) {
                  load(request.responseText);
                } else {
                  error("oh no");
                }
              };
  
              request.send(formData);
  
              return {
                abort: () => {
                  request.abort();
                  abort();
                },
              };
            },
            remove: async (fileId, load) => {
              // implementation here
            },
            revert: (source, load, error) => {
              // implementation here
            },
            load: async (source, load, error) => {
              // implementation here
            },
          }}
          name="files"
          labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
        />
    </div>
    */
}

export default FolderUploader;