import React, {useState, useEffect} from 'react'
import { config } from "./constants";
import Sample from "./Sample"
import LoadingSpinner from './LoadingSpinnerModal';
import axios from 'axios'

export default function CardForm(props) {

const [token, setToken] = useState(null)

const [open, setOpen] = useState(false)
const [card, setCard] = useState('')
const [expDate, setExpDate] = useState('')
const [cvv, setCvv] = useState('')
const [zip, setZip] = useState('')
const [error, setError] = useState(false)


function cc_format1(value) {
    var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    var matches = v.match(/\d{4,16}/g);
    var match = matches && matches[0] || ''
    var parts = []
    for (var i=0, len=match.length; i<len; i+=4) {
      parts.push(match.substring(i, i+4))
    }
    if (parts.length) {
      return parts.join(' ')
    } else {
      return value
    }
  }
  

  function cc_expires_format(string) {
    return string.replace(
        /[^0-9]/g, '' // To allow only numbers
    ).replace(
        /^([2-9])$/g, '0$1' // To handle 3 > 03
    ).replace(
        /^(1{1})([3-9]{1})$/g, '0$1/$2' // 13 > 01/3
    ).replace(
        /^0{1,}/g, '0' // To handle 00 > 0
    ).replace(
        /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2' // To handle 113 > 11/3
    );
  }


useEffect(() => {

    const script = document.createElement('script')
    script.src = "https://js.authorize.net/v1/Accept.js"
    script.async = true

    document.body.appendChild(script)

    return () => {

      document.body.removeChild(script)
    }

  }, [])


  function sendPaymentDataToAnet() {

    
    props.setModalLoading(true)
    
    var authData = {};
        // consider storing these in the document that gets created to make it more secure.
        authData.clientKey = "9w26T59BJf5gMJUjra4jT4JmvVv373eRJ49k8aKHKH7NsL6N2basG8q3rF5q9c9A";
        authData.apiLoginID = "7u5mEYV3B";

        let dateString = expDate; 

        let [month, year] = dateString.split(' / ').map(str => str.trim());

    var cardData = {};
        cardData.cardNumber = card.replace(/\s/g, '')
        cardData.month = month
        cardData.year = year
        cardData.cardCode = cvv
        cardData.zip = zip

        var secureData = {}
        secureData.authData = authData;
        secureData.cardData = cardData;

        window.Accept.dispatchData(secureData, props.onCardSuccess);


        setCard('')
        setExpDate('')
        setCvv('')
        setZip('')
       

}


  return ( <Sample 
    center={ props.modalLoading && {
      children: <LoadingSpinner />
    }}
      payButton={{
        onClick: () => {
            
        if (card === '' || expDate === '' || cvv === '' || zip === '') {
            setError(true)
        } else {
            setError(false)
            sendPaymentDataToAnet() 
        }
        
      }
      }}
      cardNumber={{
        value: card,
        onChange: (e) => {
          setCard(cc_format1(e.target.value))
        }
      }}
      expDate={{
        value: expDate,
        onChange: (e) => {

          if (e.target.value.length <= 7) {

          if (expDate.length == 1 && e.target.value !== '') {
            setExpDate(e.target.value + " / ")
          } else {
            setExpDate(e.target.value)
          }
          
          }

        },
        onBlur: (e) => {

          if (expDate.length == 5 ) {
            setExpDate(expDate.replace(/(\d{2})\/(\d{2})/, '$1 / $2'))
          }
          
        }
      }}
      cvc={{
        value: cvv,
        onChange: (e) => {
         
          if (e.target.value.length < 5){
            setCvv(e.target.value)
          }
        
        }
      }}
      zip={{
        value: zip,
        onChange: (e) => {
          setZip(e.target.value)
        }
      }}
      errorText={{
        wrap: node => error ? node : null
      }}
      /> 
   /* {<form id="paymentForm"
    method="POST"
    action="https://us-central1-collectwise-dev.cloudfunctions.net/paymentFunctions-authorizenet">
    <input type="hidden" name="dataValue" id="dataValue" />
    <input type="hidden" name="dataDescriptor" id="dataDescriptor" />
    <button type="button"
        class="AcceptUI"
        data-billingAddressOptions='{"show":true, "required":false}' 
        data-apiLoginID="7u5mEYV3B" 
        data-clientKey="9w26T59BJf5gMJUjra4jT4JmvVv373eRJ49k8aKHKH7NsL6N2basG8q3rF5q9c9A"
        data-acceptUIFormBtnTxt="Pay" 
        data-acceptUIFormHeaderTxt="Card Information"
        data-paymentOptions='{"showCreditCard": true, "showBankAccount": false}' 
        data-responseHandler="authnetResponseHandler">Pay
    </button>
    </form> } */
  )

}
