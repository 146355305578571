// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import * as React from "react";
import { createUseScreenVariants } from "@plasmicapp/react-web";

export const ScreenContext = React.createContext(
  "PLEASE_RENDER_INSIDE_PROVIDER"
);

export const useScreenVariants = createUseScreenVariants(true, {
  medium: "(max-width:1440px)",
  small: "(max-width:1370px)",
  large: "(max-width:1600px)",
  mobile: "(max-width:414px)",
  extraLarge: "(max-width:1800px)",
});

export default ScreenContext;
/* prettier-ignore-end */
