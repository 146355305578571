// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicAddClientButton } from "./plasmic/collect_wise_draft/PlasmicAddClientButton";

function AddClientButton_(props, ref) {


  
  return <PlasmicAddClientButton root={{ ref }} {...props} 
  
  />;
}

const AddClientButton = React.forwardRef(AddClientButton_);

export default AddClientButton;
