// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState} from "react";
import { PlasmicOnboardingPersonalDetailsDocument } from "./plasmic/collect_wise_draft/PlasmicOnboardingPersonalDetailsDocument";
import axios from "axios"
import LoadingSpinner2 from "./LoadingSpinner2";

import {useAuth} from "../contexts/AuthContext"
import { useHistory } from "react-router-dom";
import { config } from './constants'
import LoadingSpinner from "./LoadingSpinner";

function OnboardingPersonalDetailsDocument_(props, ref1) {
 
  const hiddenFileInput = React.useRef(null);
  const { currentUser, onboardModal, setOnboardModal } = useAuth()
  const [licenseCheck, setLicenseCheck] = useState(false)
  const [passportCheck, setPassportCheck] = useState(false)
  const [idCheck, setIdCheck] = useState(false)
  const [type, setType] = useState('')
  const [error, setError] = useState(false)
  const [contentError, setContentError] = useState(false)
  const [loading, setLoading] = useState(false)
  const history = useHistory()



  const handleChange = async (event) => {

    function convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
       reader.onerror = reject;
      });
    }

    const file = event.target.files[0];

    if (file.size / 1024 / 1024 > 7.5) {
      // Dwolla/Firebase supports files up to 10MB
      // but the base64 encoding is ~4/3 of the file size (4/3*7.5MB = 10MB)
      alert("Max file size allowed is 7.5MB");
      return;
    }



    const getDocumentType = () => {
      if (idCheck) {
        return "idCard"
      }
      if (licenseCheck) {
        return "license"
      }
      if (passportCheck) {
        return "passport"
      }
      return null;
    }
  
    const documentType = getDocumentType();

    // history.push("/onboarding-personaldetails-documentreceived")


    const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg"];

    if (!allowedFileTypes.includes(file.type)) {
      setContentError(true)
    } else {
      
    
      const data = {
        // Convert to base64 and remove data-url prefix
        file: (await convertFileToBase64(file)).replace(/^data:.+;base64,/, ""),
        filename: file.name,
        contentType: file.type,
        documentType,
      };

      try {

        setLoading(true)

        const status =  await axios({
          method: "POST",
          url: `${config.endpoint}/onboardingFunctions-handleDocument`,
          data,
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${await currentUser.getIdToken()}`
          }
        })
        
        setLoading(false)

        history.push("/onboarding-personaldetails-documentreceived")

        

      } catch (e){
        setLoading(false)
        alert("An error occured while uploading your file. Please ensure that your file meets all of the requirements and try again.")
      }
    

    }


    };



  return (
  <>
 {onboardModal ? <LoadingSpinner/> : <PlasmicOnboardingPersonalDetailsDocument root={{ ref1 }} {...props}
  vertStack={ loading && {
    children: <LoadingSpinner2/>
  }} 
  submitButton={{
    onClick: () =>  {

      if (type === ''){
        setError(true)
      } else {
        hiddenFileInput.current.click()
      }
      
    }
  }}
  licenseCheck={{
    isChecked: licenseCheck,
    onChange: (e) => {
      setType('license')
      setLicenseCheck(!licenseCheck)
      setPassportCheck(false)
      setIdCheck(false)
    },
  }}
  passportCheck={{
    isChecked: passportCheck,
    onChange: (e) => {
      setType('passport')
      setPassportCheck(!passportCheck)
      setLicenseCheck(false)
      setIdCheck(false)
    },
  }}
  idCheck={{
    isChecked: idCheck,
    onChange: (e) => {
      setType('idCard')
      setIdCheck(!idCheck)
      setLicenseCheck(false)
      setPassportCheck(false)
    },
  }}
  errorText={{
    wrap: node => error ? node : null
  }}
  contentError={{
    wrap: node => contentError ? node : null
  }}
  /> }
  <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{display: 'none'}}
      /> 
  </>
  )
  
}

const OnboardingPersonalDetailsDocument = React.forwardRef(
  OnboardingPersonalDetailsDocument_
);

export default OnboardingPersonalDetailsDocument;
