import React from 'react'
import { db, storage } from "../firebase"
import { collection, addDoc, updateDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit, startAt, endAt, startAfter, endBefore, limitToLast } from "firebase/firestore";


export default function UpdateInformation() {


async function handleUpdate() {
    
    var hardCodedUser = ""

    const q = query(
        collection(db, "debtors"),
        where("user", "==", hardCodedUser),
      )

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(async (docSnap) => {
     
        console.log(docSnap.id)
        await updateDoc(doc(db, "debtors", docSnap.id), {
            numberOfEmails: 2,
            numberOfTexts: 1,
            numberOfCalls: 2,
        })


      })

      alert("Done")

}



  return (
   <>
   <button onClick={handleUpdate}>Update Information</button> 
   </>
  )


}
