// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, { useState, useEffect, useCallback } from 'react'
import { PlasmicEscrowPaymentsPage } from './plasmic/collect_wise_draft/PlasmicEscrowPaymentsPage'
import { useParams } from 'react-router-dom'
import { db, storage } from '../firebase'
import {
  collection,
  addDoc,
  doc,
  setDoc,
  serverTimestamp,
  getDoc,
  query,
  where,
  getDocs,
  onSnapshot,
  orderBy,
  limit,
  updateDoc,
} from 'firebase/firestore'
import {
  ref,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
  listAll,
  list,
} from 'firebase/storage'
import { useAuth } from '../contexts/AuthContext'
import { useHistory } from 'react-router-dom'
import LoadingSpinner from './LoadingSpinner'
import axios from 'axios'
import {
  usePlaidLink,
  PlaidLinkOptions,
  PlaidLinkOnSuccess,
} from 'react-plaid-link'
import { v4 } from 'uuid'
import PaymentSuccess from './PaymentSuccess'
import Moment from 'moment'
import PaymentVoided from './PaymentVoided'
import PayrocIframe from './PayrocIframe'
import LoadingSpinnerWithText from './LoadingSpinnerWithText'
import { PlasmicEscrowPaymentsPageAch } from './plasmic/collect_wise_draft/PlasmicEscrowPaymentsPageAch'
import { PlasmicEscrowPaymentsPageCard } from './plasmic/collect_wise_draft/PlasmicEscrowPaymentsPageCard'
import { PlasmicEscrowPaymentsPageSurcharge } from './plasmic/collect_wise_draft/PlasmicEscrowPaymentsPageSurcharge'
import { Button, Modal, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../CardModal.css'
import CardModalHeader from './CardModalHeader'
import EdgePayInput from './EdgePayInput'
import EdgePayInput1 from './EdgePayInput1'
import { config } from './constants'
import PaymentFraud from "./PaymentFraud"


function Link(props, ref1) {
  let { paymentId } = useParams()

  const [screenData, setScreenData] = useState({})
  const [loading, setLoading] = useState(false)
  const [showSideBar, setShowSideBar] = useState(false)
  const [paymentSuccess, setPaymentSuccess] = useState(
    props.screenData.paymentSuccess,
  )
  const [successDate, setSuccessDate] = useState(props.screenData.datePaid)
  const formatDate = Moment().format('MMM Do, YYYY')
  const { closeCardModal, setCloseCardModal, edgeLoading, setEdgeLoading, currentUser } = useAuth()
  const [paymentFraud, setPaymentFraud] = useState(props.screenData.paymentFraud)
  const [sessionId, setSessionId] = useState('')
  const [ip, setIp] = useState('')


  const handleClose = () => setCloseCardModal(false)

  function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  useEffect(() => {

    const session_id = v4()
      
    window.seon.config({
      host: "seondf.com",
      session_id: session_id,
      audio_fingerprint: true,
      canvas_fingerprint: true,
      webgl_fingerprint: true,
      onSuccess: function(message) {
        console.log("success", message);
      },
      onError: function(message) {
        console.log("error", message);
      }
    });
  
    setSessionId(session_id)
  
  }, [])

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };

  function handleDownload() {
    getDownloadURL(ref(storage, props.screenData.contractFile))
      .then((url) => {
        handleDownloadUrl(url)
      })
      .catch((error) => {
        // Handle any errors
      })
  }

  async function handleDownloadUrl(source) {
    const image = await fetch(source)
    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)

    const link = document.createElement('a')
    link.href = imageURL
    link.download = props.screenData.contractFileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  function downloadPDF(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`
    const downloadLink = document.createElement('a')
    const fileName = 'contract-terms.pdf'
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }


  async function handleFraud() {

    const response = await axios({
      method: 'POST',
      url: `${config.endpoint}/handleFraud`,
      headers: {
        'authorization': props.screenData.yourToken
      },
      data: {
        paymentId: paymentId,
      },
    })
  
  }
  
    async function checkFraud() {
  
      const ipAddress = await axios.get('https://geolocation-db.com/json/')
    
      setIp(ipAddress.data.IPv4)
  
      if(props.screenData.ip === ipAddress.data.IPv4 || props.screenData.clientEmail === props.screenData.userEmail){
        setPaymentFraud(true)
        
        console.log('fraud')
  
        await handleFraud()
  
        return false
      } else {
        return true
      } 
    
    }
  
    // function is below here
    const onCardSuccess = useCallback( async (resp) => {
    
      var zip = document.getElementById('zip').value

      const apiDeckPayment = {
        payment: {
          currency: "USD",
          currency_rate: null,
          total_amount: props.screenData.amountNum,
          reference: null,
          payment_method: "Credit Card",
          payment_method_reference: null,
          payment_method_id: null,
          // accounts_receivable_account_type: "Account",
          // accounts_receivable_account_id: "123456",
          // this needs to be the bank account where the payments are being made to/from
          account: {
              id: props.screenData.api_deck_bank_id
          },
          transaction_date: Date(props.screenData?.date?.nanoseconds),
          customer: {
            id: JSON.parse(props.screenData?.client)?.api_deck_clientId,
            display_name: JSON.parse(props.screenData?.client)?.contactName,
            name: JSON.parse(props.screenData?.client)?.contactName,
          },
          supplier: {
            id: "12345",
            display_name: "Windsurf Shop",
            address: {
              id: "123",
              type: "primary",
              string: "25 Spring Street, Blackburn, VIC 3130",
              name: "HQ US",
              line1: "Main street",
              line2: "apt #",
              line3: "Suite #",
              line4: "delivery instructions",
              street_number: "25",
              city: "San Francisco",
              state: "CA",
              postal_code: "94104",
              country: "US",
              latitude: "40.759211",
              longitude: "-73.984638",
              county: "Santa Clara",
              contact_name: "Elon Musk",
              salutation: "Mr",
              phone_number: "111-111-1111",
              fax: "122-111-1111",
              email: "elon@musk.com",
              website: "https://elonmusk.com",
              row_version: "1-12345",
            },
          },
          reconciled: true,
          status: "paid",
          type: "accounts_receivable",
          allocations: [
            {
              id: props.screenData?.api_deck_invoiceId,
              type: "invoice",
              amount: props.screenData?.amountNum,
            },
          ],
          note: "Some notes about this payment",
          row_version: v4(),
          display_id: v4(),
        },
      };
  
      setEdgeLoading(true)
  
      setCloseCardModal(false)
  
      const noFraud = await checkFraud()
  
      if (noFraud) {
      
        window.addEventListener("beforeunload", handleBeforeUnload);
  
        window.seon.getBase64Session(async function(data) {
          if (data) {
            console.log("Session payload", data);
          } else {
            console.log("Failed to retrieve session data.");
          }

        // might have to update the processCard function within this
        const payment = await axios({
            method: "POST",
            url: `${config.endpoint}/propayFunctions-handleCardPayment`,
            headers: {
              'authorization': props.screenData.yourToken
            },
            data: {
              ip: ip,
              email: props.screenData.clientEmail,
              email_domain: props.screenData.clientEmail.split('@').pop(),
              session_id: sessionId,
              session: data,
              token: resp.token_value,
              zip: zip,
              amount: props.screenData.amountNum,
              paymentId: paymentId,
              type: "milestone",
              apiDeckPayload:apiDeckPayment,
              user:props.screenData.user
            }
          })
  
          window.removeEventListener("beforeunload", handleBeforeUnload);
  
         if (payment.data !== "APPROVE") {
          
          setPaymentFraud(true)
          await handleFraud()
    
        } else {
  
          setSuccessDate(formatDate)
          setPaymentSuccess(true)
  
        }
  
        });
  
    }
  
    }, [])


    // ACH function here
    const onSuccess = useCallback(async (public_token, metadata) => {

      setLoading(true)
      const apiDeckPayment = {
        payment: {
          currency: "USD",
          currency_rate: null,
          total_amount: props.screenData.amountNum,
          reference: null,
          payment_method: "Credit Card",
          payment_method_reference: null,
          payment_method_id: null,
          // accounts_receivable_account_type: "Account",
          // accounts_receivable_account_id: "123456",
          // this needs to be the bank account where the payments are being made to/from
          account: {
              id: props.screenData.api_deck_bank_id
          },
          transaction_date: Date(props.screenData?.date?.nanoseconds),
          customer: {
            id: JSON.parse(props.screenData?.client)?.api_deck_clientId,
            display_name: JSON.parse(props.screenData?.client)?.contactName,
            name: JSON.parse(props.screenData?.client)?.contactName,
          },
          supplier: {
            id: "12345",
            display_name: "Windsurf Shop",
            address: {
              id: "123",
              type: "primary",
              string: "25 Spring Street, Blackburn, VIC 3130",
              name: "HQ US",
              line1: "Main street",
              line2: "apt #",
              line3: "Suite #",
              line4: "delivery instructions",
              street_number: "25",
              city: "San Francisco",
              state: "CA",
              postal_code: "94104",
              country: "US",
              latitude: "40.759211",
              longitude: "-73.984638",
              county: "Santa Clara",
              contact_name: "Elon Musk",
              salutation: "Mr",
              phone_number: "111-111-1111",
              fax: "122-111-1111",
              email: "elon@musk.com",
              website: "https://elonmusk.com",
              row_version: "1-12345",
            },
          },
          reconciled: true,
          status: "paid",
          type: "accounts_receivable",
          allocations: [
            {
              id: props.screenData?.api_deck_invoiceId,
              type: "invoice",
              amount: props.screenData?.amountNum,
            },
          ],
          note: "Some notes about this payment",
          row_version: v4(),
          display_id: v4(),
        },
      };
      const noFraud = await checkFraud()
      if (noFraud) {
  
      if (props.screenData?.clientUrl) {
        window.addEventListener("beforeunload", handleBeforeUnload);
        
        window.seon.getBase64Session(async function(data) {
          if (data) {
            console.log("Session payload", data);
          } else {
            console.log("Failed to retrieve session data.");
          }
          //api deck integrations

          /// modified function - see if this works
  
          const payment = await axios({
            method: "POST",
            url: `${config.endpoint}/createTransfer`,
            headers: {
              'authorization': props.screenData.yourToken
            },
            data: {
              ip: ip,
              email: props.screenData.clientEmail,
              email_domain: props.screenData.clientEmail.split('@').pop(),
              session_id: sessionId,
              session: data,
              public_token_object: {
                public_token: public_token,
              },
              account_id: metadata.account_id,
              metadata: metadata,
              customerUrl: props.screenData.clientUrl,
              paymentId: props.screenData.paymentId,
              type: 'milestone',
              apiDeckPayload:apiDeckPayment,
              user:props.screenData.user
            }
          })
  
        console.log(payment.data)
  
        window.removeEventListener("beforeunload", handleBeforeUnload);
  
        if (payment.data !== "APPROVE") {
          setPaymentFraud(true)
    
          await handleFraud()
    
        } else {
  
          setSuccessDate(formatDate)
          setPaymentSuccess(true)
  
        }
  
  
        });
  
      }
  
    }
  
    }, [])



  const config1 = {
    onSuccess,
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    token: props.linkToken,
    env: config.version,
  }

  const { open, ready } = usePlaidLink(config1)

  function handleBankTransfer() {
    open()
  }

  return (
    <>
      { paymentFraud ? <PaymentFraud/> : paymentSuccess ? (
        <PaymentSuccess
          amount={ (typeof props.screenData.surchargedAmount !== 'undefined' && Number(props.screenData.surchargedAmount) !== props.screenData.amountNum ) ? "$" + props.screenData.surchargedAmount : props.screenData.amountNum.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          }) }
          date={'On ' + successDate}
          screenData={props.screenData}
        />
      ) : edgeLoading ?  <LoadingSpinnerWithText text={'Processing Payment'} />  : loading ? (
        <LoadingSpinnerWithText text={'Processing Payment'} />
      ) :  props.screenData.paymentVoided ? (
        <PaymentVoided date={'On ' + props.screenData.voidDate} />
      ) : props.pageType === 'ACH' ? (
        <PlasmicEscrowPaymentsPageAch
          root={{ ref1 }}
          {...props}
          merchantName={props.screenData.merchantName}
          escrowDeposit={`Requested Milestone Deposit: ${props.screenData.escrowDeposit}`}
          milestoneOrContract={props.screenData.milestoneOrContract}
          footerText={`Your funds will be safeguarded until ${props.screenData.merchantName} has delivered their service according to the contract terms.`}
          contractName={props.screenData.contractName}
          milestoneName={props.screenData.sideBarMilestoneName}
          amount={props.screenData.escrowDeposit}
          handleBankTransfer={{
            onClick: () => handleBankTransfer(),
          }}
          viewDetails={{
            onClick: () => setShowSideBar(!showSideBar),
          }}
          sideBarInstance={{
            wrap: (node) => (showSideBar ? node : null),
          }}
          viewContract={{
            wrap: (node) =>
              props.screenData.contractFile === '' &&
              props.screenData.contractDescription === ''
                ? null
                : node,
          }}
          downloadContract={{
            wrap: (node) =>
              props.screenData.contractFile === '' &&
              props.screenData.contractDescription === ''
                ? null
                : node,
            props: {
              onClick: async () => {
                if (props.screenData.contractFile !== '') {
                  handleDownload()
                } else {
                  const termsPDF = await axios({
                    method: 'POST',
                    url:
                      `${config.endpoint}/contractTerms`,
                    data: {
                      contractDescription: props.screenData.contractDescription,
                    },
                  })

                  downloadPDF(termsPDF.data)
                }
              },
            },
          }}
          lowerBar={{
            wrap: (node) =>
              props.screenData.contractFile === '' &&
              props.screenData.contractDescription === ''
                ? null
                : node,
          }}
          closeButton={{
            onClick: () => setShowSideBar(!showSideBar),
          }}
          handleCardPayment={{
            onClick: () => setCloseCardModal(true),
          }}
        />
      ) : props.pageType === 'Card' ? (
        <PlasmicEscrowPaymentsPageCard
          root={{ ref1 }}
          {...props}
          merchantName={props.screenData.merchantName}
          escrowDeposit={`Requested Milestone Deposit: ${props.screenData.escrowDeposit}`}
          milestoneOrContract={props.screenData.milestoneOrContract}
          footerText={`Your funds will be safeguarded until ${props.screenData.merchantName} has delivered their service according to the contract terms.`}
          contractName={props.screenData.contractName}
          milestoneName={props.screenData.sideBarMilestoneName}
          amount={props.screenData.escrowDeposit}
          handleBankTransfer={{
            onClick: () => handleBankTransfer(),
          }}
          viewDetails={{
            onClick: () => setShowSideBar(!showSideBar),
          }}
          sideBarInstance={{
            wrap: (node) => (showSideBar ? node : null),
          }}
          viewContract={{
            wrap: (node) =>
              props.screenData.contractFile === '' &&
              props.screenData.contractDescription === ''
                ? null
                : node,
          }}
          downloadContract={{
            wrap: (node) =>
              props.screenData.contractFile === '' &&
              props.screenData.contractDescription === ''
                ? null
                : node,
            props: {
              onClick: async () => {
                if (props.screenData.contractFile !== '') {
                  handleDownload()
                } else {
                  const termsPDF = await axios({
                    method: 'POST',
                    url:
                      `${config.endpoint}/contractTerms`,
                    data: {
                      contractDescription: props.screenData.contractDescription,
                    },
                  })

                  downloadPDF(termsPDF.data)
                }
              },
            },
          }}
          lowerBar={{
            wrap: (node) =>
              props.screenData.contractFile === '' &&
              props.screenData.contractDescription === ''
                ? null
                : node,
          }}
          closeButton={{
            onClick: () => setShowSideBar(!showSideBar),
          }}
          handleCardPayment={{
            onClick: () => setCloseCardModal(true),
          }}
        />
      ) : props.pageType === 'CardSurcharge' ? (
        <PlasmicEscrowPaymentsPageSurcharge
          root={{ ref1 }}
          {...props}
          merchantName={props.screenData.merchantName}
          escrowDeposit={`Requested Milestone Deposit: ${props.screenData.escrowDeposit}`}
          milestoneOrContract={props.screenData.milestoneOrContract}
          footerText={`Your funds will be safeguarded until ${props.screenData.merchantName} has delivered their service according to the contract terms.`}
          contractName={props.screenData.contractName}
          milestoneName={props.screenData.sideBarMilestoneName}
          amount={props.screenData.escrowDeposit}
          handleBankTransfer={{
            onClick: () => handleBankTransfer(),
          }}
          viewDetails={{
            onClick: () => setShowSideBar(!showSideBar),
          }}
          sideBarInstance={{
            wrap: (node) => (showSideBar ? node : null),
          }}
          viewContract={{
            wrap: (node) =>
              props.screenData.contractFile === '' &&
              props.screenData.contractDescription === ''
                ? null
                : node,
          }}
          downloadContract={{
            wrap: (node) =>
              props.screenData.contractFile === '' &&
              props.screenData.contractDescription === ''
                ? null
                : node,
            props: {
              onClick: async () => {
                if (props.screenData.contractFile !== '') {
                  handleDownload()
                } else {
                  const termsPDF = await axios({
                    method: 'POST',
                    url:
                      `${config.endpoint}/contractTerms`,
                    data: {
                      contractDescription: props.screenData.contractDescription,
                    },
                  })

                  downloadPDF(termsPDF.data)
                }
              },
            },
          }}
          lowerBar={{
            wrap: (node) =>
              props.screenData.contractFile === '' &&
              props.screenData.contractDescription === ''
                ? null
                : node,
          }}
          closeButton={{
            onClick: () => setShowSideBar(!showSideBar),
          }}
          handleCardPayment={{
            onClick: () => setCloseCardModal(true),
          }}
        />
      ) : (
        <PlasmicEscrowPaymentsPage
          root={{ ref1 }}
          {...props}
          merchantName={props.screenData.merchantName}
          escrowDeposit={`Requested Milestone Deposit: ${props.screenData.escrowDeposit}`}
          milestoneOrContract={props.screenData.milestoneOrContract}
          footerText={`Your funds will be safeguarded until ${props.screenData.merchantName} has delivered their service according to the contract terms.`}
          contractName={props.screenData.contractName}
          milestoneName={props.screenData.sideBarMilestoneName}
          amount={props.screenData.escrowDeposit}
          handleBankTransfer={{
            onClick: () => handleBankTransfer(),
          }}
          viewDetails={{
            onClick: () => setShowSideBar(!showSideBar),
          }}
          sideBarInstance={{
            wrap: (node) => (showSideBar ? node : null),
          }}
          viewContract={{
            wrap: (node) =>
              props.screenData.contractFile === '' &&
              props.screenData.contractDescription === ''
                ? null
                : node,
          }}
          downloadContract={{
            wrap: (node) =>
              props.screenData.contractFile === '' &&
              props.screenData.contractDescription === ''
                ? null
                : node,
            props: {
              onClick: async () => {
                if (props.screenData.contractFile !== '') {
                  handleDownload()
                } else {
                  const termsPDF = await axios({
                    method: 'POST',
                    url:
                      `${config.endpoint}/contractTerms`,
                    data: {
                      contractDescription: props.screenData.contractDescription,
                    },
                  })

                  downloadPDF(termsPDF.data)
                }
              },
            },
          }}
          lowerBar={{
            wrap: (node) =>
              props.screenData.contractFile === '' &&
              props.screenData.contractDescription === ''
                ? null
                : node,
          }}
          closeButton={{
            onClick: () => setShowSideBar(!showSideBar),
          }}
          handleCardPayment={{
            onClick: () => setCloseCardModal(true),
          }}
        />
      )}
      <Modal
        dialogClassName="card-modal"
        show={closeCardModal}
        onHide={handleClose}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <CardModalHeader />
          <EdgePayInput1
            screenData={props.screenData}
            type="milestone"
            onCardSuccess={onCardSuccess}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

function EscrowPaymentsPage_(props, ref1) {
  let { paymentId } = useParams()

  const [screenData, setScreenData] = useState({})
  const [loading, setLoading] = useState(true)
  const [showSideBar, setShowSideBar] = useState(false)
  const [linkToken, setLinkToken] = useState(null)
  const [docId, setDocId] = useState('')
  const [pageType, setPageType] = useState('')
  const { currentUser } = useAuth()


  useEffect( async () => {
  


  

    const response = await axios({
      method: 'POST',
      url: `${config.endpoint}/paymentInfo`,
      data: {
        paymentId,
        type: 'milestone',
        user: v4()
      }
      })

      console.log(response.data)

      const paymentDoc = response.data.paymentDoc

      setLinkToken(response.data.link_token.link_token)

        if (paymentDoc.paymentMethods.ACH && !(paymentDoc.paymentMethods.Card || paymentDoc.paymentMethods.CardSurcharge)){
          setPageType('ACH')
        } else if ((paymentDoc.paymentMethods.Card || paymentDoc.paymentMethods.CardSurcharge) && !paymentDoc.paymentMethods.ACH){
          setPageType('Card')
        } else if (paymentDoc.paymentMethods.CardSurcharge && paymentDoc.paymentMethods.ACH){
          setPageType('CardSurcharge')
        }

        setLoading(false)
        setScreenData(paymentDoc)
 
  }, [])

  function handleDownload() {
    getDownloadURL(ref(storage, screenData.contractFile))
      .then((url) => {
        handleDownloadUrl(url)
      })
      .catch((error) => {
        // Handle any errors
      })
  }

  async function handleDownloadUrl(source) {
    const image = await fetch(source)
    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)

    const link = document.createElement('a')
    link.href = imageURL
    link.download = screenData.contractFileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  function hanleBankTransfer() {}

  /// have to decide if you want to display a blue loading screen or a display it from the start like you are right now. For example, one idea may be to have it be in a loading state while
  // it fetches both screen data and the token, and then have it display the interface after the token and the data has been fetched.

  return (
    <>
      {loading || !Object.keys(screenData).length ? (
        <LoadingSpinner />
      ) : linkToken == null ? (
        <PlasmicEscrowPaymentsPage
          root={{ ref1 }}
          {...props}
          merchantName={screenData.merchantName}
          escrowDeposit={`Requested Milestone Deposit: ${screenData.escrowDeposit}`}
          milestoneOrContract={screenData.milestoneOrContract}
          footerText={`Your funds will be safeguarded until ${screenData.merchantName} has delivered their service according to the contract terms.`}
          contractName={screenData.contractName}
          milestoneName={screenData.sideBarMilestoneName}
          amount={screenData.escrowDeposit}
          handleBankTransfer={{
            onClick: () => hanleBankTransfer(),
          }}
          viewDetails={{
            onClick: () => setShowSideBar(!showSideBar),
          }}
          sideBarInstance={{
            wrap: (node) => (showSideBar ? node : null),
          }}
          downloadContract={{
            onClick: () => handleDownload(),
          }}
          closeButton={{
            onClick: () => setShowSideBar(!showSideBar),
          }}
        />
      ) : (
        <Link
          linkToken={linkToken}
          screenData={screenData}
          docId={docId}
          pageType={pageType}
        />
      )}
    </>
  )
}

const EscrowPaymentsPage = React.forwardRef(EscrowPaymentsPage_)

export default EscrowPaymentsPage