import * as React from "react";
import { PlasmicAutomationSelect__OptionGroup } from "./plasmic/collect_wise_draft/PlasmicAutomationSelect__OptionGroup";

function AutomationSelect__OptionGroup(props) {
  const { plasmicProps } =
    PlasmicAutomationSelect__OptionGroup.useBehavior(props);
  return <PlasmicAutomationSelect__OptionGroup {...plasmicProps} />;
}

export default Object.assign(AutomationSelect__OptionGroup, {
  __plumeType: "select-option-group"
});
