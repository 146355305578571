import * as React from "react";
import { PlasmicSelectGender2 } from "./plasmic/collect_wise_draft/PlasmicSelectGender2";
import Option from "./SelectGender2__Option";
import OptionGroup from "./SelectGender2__OptionGroup";

function SelectGender2_(props, ref) {
  const { plasmicProps, state } = PlasmicSelectGender2.useBehavior(props, ref);
  return <PlasmicSelectGender2 {...plasmicProps} />;
}

const SelectGender2 = React.forwardRef(SelectGender2_);

export default Object.assign(SelectGender2, {
  Option,
  OptionGroup,
  __plumeType: "select"
});
