// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useRef, useEffect} from "react";
import { PlasmicOnboardingPersonalDetails } from "./plasmic/collect_wise_draft/PlasmicOnboardingPersonalDetails";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import {useAuth} from "../contexts/AuthContext"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Onboarding.css"
import useLocalStorage from "../hooks/useLocalStorage";
import CurrencyInput from "react-currency-input-field";
import "./Boarding.css"
import { hotjar } from 'react-hotjar';

hotjar.initialize(3206049, 6);

function OnboardingPersonalDetails_(props, ref) {
  const history = useHistory()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [address1, setAddress1] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [dob, setDob] = useState('')
  const [ssn, setSSN] = useState('')
  const [error, setError] = useState(false)
  const [ageError, setAgeError] = useState(false)
  const [industry, setIndustry] = useState('')
  const [phone, setPhone] = useState('')
  const [productDescription, setProductDescription] = useState('')
  const [website, setWebsite] = useState('')
  const [gender, setGender] = useState('')
  const [suite, setSuite] = useState('')
  const [ssnError, setSSNError] = useState(false)
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastNameError, setLastNameError] = useState(false)
  const [addressError, setAddressError] = useState(false)
  const [cityError, setCityError] = useState(false)
  const [stateError, setStateError] = useState(false)
  const [zipError, setZipError] = useState(false)
  const [dobError, setDobError] = useState(false)
  const [securityError, setSecurityError] = useState(false)
  const [genderError, setGenderError] = useState(false)
  const [phoneError, setPhoneError] = useState(false)
  const [suiteError, setSuiteError] = useState(false)
  const [industryError, setIndustryError] = useState(false)
  const [linkError, setLinkError] = useState(false)
  const [volumeError, setVolumeError] = useState(false)
  const [averageError, setAverageError] = useState(false)
  const [highestEstimationError, setHighestEstimationError] = useState(false)

  const {personalDetails, setPersonalDetails} = useAuth()

  const monthlyVolume = useRef(null)
  const averageTicket = useRef(null)
  const highestTicket = useRef(null)

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };

  useEffect(() => {

    window.addEventListener("beforeunload", handleBeforeUnload);

  }, [] )

  function getAge(date) {
    
    var ageInMilliseconds = new Date() - new Date(date);

    return Math.floor(ageInMilliseconds/1000/60/60/24/365)

 }

//  const handleBeforeUnload = (e) => {
//   e.preventDefault();
//   const message =
//     "Are you sure you want to leave? All provided data will be lost.";
//   e.returnValue = message;
//   return message;
// };


// window.addEventListener("beforeunload", handleBeforeUnload);


  return <PlasmicOnboardingPersonalDetails root={{ ref }} {...props}
  firstName={{
    value: firstName,
    onChange: (e) => {
      setFirstName(e.target.value)
      setFirstNameError(false)
    }
  }}
  lastName={{
    value: lastName,
    onChange: (e) => {
      setLastName(e.target.value)
      setLastNameError(false)
    }
  }} 
  address1={{
    value: address1,
    onChange: (e) => {
      setAddress1(e.target.value)
      setAddressError(false)
    }
  }}
  city={{
    value: city,
    onChange: (e) => {
      setCity(e.target.value)
      setCityError(false)
    }
  }}
  selectState={{
    onChange: (e) => {
      setState(e)
      setStateError(false)
    }
  }}
  selectGender={{
    onChange: (e) => {
      setGender(e)
      setGenderError(false)
    }
  }}
  zip={{
    value: zip,
    onChange: (e) => {
      setZip(e.target.value)
      setZipError(false)
    }
  }}
  dob={{
    children: <DatePicker placeholderText={"mm/dd/yyyy"} className="dateBox" selected={dob} 
    onChange={(date) => { 
      setDob(date);
      setDobError(false)
    }} />
  }}
  ssn={{
    value: ssn,
    onChange: (e) => {
      setSSN(e.target.value)
      setSecurityError(false)
    }, 
    onBlur: (e) => {
      if (!(e.target.value.includes("-")) && ssn.length > 4){
        setSSN(ssn.substring(0, 3) + "-" + ssn.substring(3,5) + "-" + ssn.substring(5))
      }
    }
  }}
  suite={{
    value: suite,
    onChange: (e) => {
      setSuite(e.target.value)
      setSuiteError(false)
    }, 
    onBlur: (e) => {
      if (!(e.target.value.includes("suite") || e.target.value.includes("Suite") || e.target.value.includes("apartment") || e.target.value.includes("Apartment") || e.target.value.includes("STE") || e.target.value.includes("ste") || e.target.value.includes("Ste") || e.target.value.includes("apt") || e.target.value.includes("Apt") || e.target.value.includes("APT") || e.target.value.includes("Unit") || e.target.value.includes("unit")) && (e.target.value !== "") && (e.target.value !== " ")) {
        setSuite("Suite " + e.target.value)
      }
    }
  }}
  monthlyVolume={{
    children: <CurrencyInput ref={monthlyVolume}
    onValueChange={(value) => value !== undefined && setVolumeError(false)}
    className="input"
    prefix="$"
    name="currencyInput"
    id="currencyInput"
    data-number-to-fixed="2"
    data-number-stepfactor="100"
    placeholder="$ "
    // onChange={handleChange}
    // onBlur={handleOnBlur}
    allowDecimals
    decimalsLimit="2"
    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
    disableAbbreviations
  />
  }}
  averageTicket={{
    children: <CurrencyInput ref={averageTicket}
    onValueChange={(value) => value !== undefined && setAverageError(false)}
    className="input"
    prefix="$"
    name="currencyInput"
    id="currencyInput"
    data-number-to-fixed="2"
    data-number-stepfactor="100"
    placeholder="$ "
    // onChange={handleChange}
    // onBlur={handleOnBlur}
    allowDecimals
    decimalsLimit="2"
    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
    disableAbbreviations
  />
  }}
  highestTicket={{
    children: <CurrencyInput ref={highestTicket}
    onValueChange={(value) => value !== undefined && setHighestEstimationError(false)}
    className="input"
    prefix="$"
    name="currencyInput"
    id="currencyInput"
    data-number-to-fixed="2"
    data-number-stepfactor="100"
    placeholder="$ "
    // onChange={handleChange}
    // onBlur={handleOnBlur}
    allowDecimals
    decimalsLimit="2"
    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
    disableAbbreviations
  />
  }}
  phoneNumber={{
    value: phone,
    onChange: (e) => {
      setPhone(e.target.value)
      setPhoneError(false)
    }
  }}
  selectIndustry={{
    onChange: (e) => {
      setIndustry(e)
      setIndustryError(false)
    }
  }}
  website={{
    value: website,
    onChange: (e) => {
      setWebsite(e.target.value)
      setLinkError(false)
    }
  }}
  errorText={{
    wrap: node => error ? node : null
  }}
  ageError={{
    wrap: node => ageError ? node : null
  }}
  ssnError={{
    wrap: node => ssnError ? node : null
  }}
  firstNameError={{
    wrap: node => firstNameError ? node : null
  }}
  lastNameError={{
    wrap: node => lastNameError ? node : null
  }}
  countryError={{
    wrap: () => null
  }}
  addressError={{
    wrap: node => addressError  ? node : null
  }}
  cityError={{
    wrap: node => cityError  ? node : null
  }}
  stateError={{
    wrap: node => stateError  ? node : null
  }}
  zipError={{
    wrap: node => zipError  ? node : null
  }}
  dateError={{
    wrap: node => dobError  ? node : null
  }}
  genderError={{
    wrap: node => genderError  ? node : null
  }}
  securityError={{
    wrap: node => securityError  ? node : null
  }}
  industryError={{
    wrap: node => industryError  ? node : null
  }}
  phoneError={{
    wrap: node => phoneError  ? node : null
  }}
  linkError={{
    wrap: node => linkError ? node : null
  }}
  volumeError={{
    wrap: node => volumeError ? node : null
  }}
  averageTransactionError={{
    wrap: node => averageError ? node : null
  }}
  highestEstimationerror={{
    wrap: node => highestEstimationError ? node : null
  }}
  continueButton={{
    onClick: () => {


      var mcc = ''
      var productDescription = ''
      var commodityCode = ''

      switch (industry){
        case "9ed38146-7d6f-11e3-89d6-5404a6144203":
          productDescription = "Marketing Services"
          mcc = '7311'
          commodityCode = '915-01'
          break;
        case "9ed46b9e-7d6f-11e3-9fec-5404a6144203":
          productDescription = "Graphic Design Services"
          mcc = '7333'
          commodityCode = '915-96'
          break;
        case "9ed3a851-7d6f-11e3-92a4-5404a6144203":
          productDescription = "Software Product/Service"
          mcc = '7372'
          commodityCode = '920-40'
          break;
        case "9ed3cf60-7d6f-11e3-a80d-5404a6144203":
          productDescription = "Accounting Services"
          mcc = '8931'
          commodityCode = '946-11'
          break;
        case "9ed3814f-7d6f-11e3-aaf6-5404a6144203":
          productDescription = "Wholesale Products"
          mcc = "5300"
          commodityCode = '010-83'
          break;
        case "9ed3813d-7d6f-11e3-bd65-5404a6144203":
          productDescription = "Education Services"
          mcc = '8299'
          commodityCode = '924-18'
          break;
        case "9ed3813c-7d6f-11e3-82cc-5404a6144203":
          productDescription = "Consulting Services"
          mcc = '7392';
          commodityCode = '918-20'
          break;
        case "9ed3cf63-7d6f-11e3-b50c-5404a6144203":
          productDescription = "Investment Services"
          mcc = "8999"
          commodityCode = "946-56"
          break;
        case "9ed46b95-7d6f-11e3-970b-5404a6144203":
          productDescription = "eCommerce Services"
          mcc = "7399"
          commodityCode = "918-36"
          break;
        case "9ed3a853-7d6f-11e3-a35d-5404a6144203":
          productDescription = "Web Hosting and Design Service"
          mcc = "7372"
          commodityCode = "209-31"
          break;
        case "9ed4449a-7d6f-11e3-a380-5404a6144203":
          productDescription = "Retail Products"
          mcc = "5999"
          commodityCode = '918-70'
          break;
        case "9ed41d7a-7d6f-11e3-ae21-5404a6144203":
          productDescription = "Construction Services"
          mcc = '8999'
          commodityCode = '918-31'
          break;
        case "9ed46ba6-7d6f-11e3-ae95-5404a6144203":
          productDescription = "Legal Services"
          mcc = '8111'
          commodityCode = "961-49"
          break;
        case "9ed35a38-7d6f-11e3-a24f-5404a6144203":
          productDescription = "Publishing Services"
          mcc = '2741'
          commodityCode = "961-90"
          break;
        case "9ed444a6-7d6f-11e3-ad1c-5404a6144203":
          productDescription = "Photography Services"
          mcc = '7333'
          commodityCode = "915-72"
          break;
        case "9ed492a5-7d6f-11e3-8d8f-5404a6144203":
          productDescription = "Telecommunication Services"
          mcc = '4814'
          commodityCode = "915-79"
          break;
        case "9ed4449f-7d6f-11e3-b867-5404a6144203":
          productDescription = "Public Relation Services"
          mcc = '7392'
          commodityCode = "915-03"
          break;
        case "9ed46ba3-7d6f-11e3-9efe-5404a6144203":
          productDescription = "Insurance Services"
          mcc = '5960'
          commodityCode = "953-56"
          break;
        case "9ed492ab-7d6f-11e3-9907-5404a6144203":
          productDescription = "Real Estate Services"
          mcc = '6513'
          commodityCode = "958-83"
          break;
        case "9ed3814e-7d6f-11e3-9087-5404a6144203":
          productDescription = "Secretarial Services"
          mcc = '7339'
          commodityCode = "961-02"
          break;
        case "9ed46b9a-7d6f-11e3-a11b-5404a6144203":
          productDescription = "Event Planning Services"
          mcc = '8999'
          commodityCode = "962-34"
          break;
        case "9ed3f679-7d6f-11e3-a884-5404a6144203":
          productDescription = "Gifting Services"
          mcc = '5947'
          commodityCode = "037-43"
          break;
        case "9ed4b9bb-7d6f-11e3-af9f-5404a6144203":
          productDescription = "Travel Agency Services"
          mcc = '4722'
          commodityCode = "961-78"
          break;
        case "9ed38143-7d6f-11e3-bc00-5404a6144203":
          productDescription = "Recruiting Services"
          mcc = '7361'
          commodityCode = "918-85"
          break;
        case "9ed3cf5a-7d6f-11e3-9a99-5404a6144203":
          productDescription = "Digital Media"
          mcc = "7311"
          commodityCode = "052-48"
          break;
          
      }

      if(firstName === "" )
        setFirstNameError(true)
      if(lastName === "")
        setLastNameError(true)
      if(address1 === "")
        setAddressError(true)
      if(city === "")
        setCityError(true)
      if(state === "")
        setStateError(true)
      if(zip === "")
        setZipError(true)
      if(!getAge(dob))
        setDobError(true)
      if(gender === "")
        setGenderError(true)
      if(!ssn.length)
        setSecurityError(true)
      if(industry === "")
        setIndustryError(true)
      if(phone === "")
        setPhoneError(true)
      if(website === "")
        setLinkError(true)
      if(monthlyVolume.current.value === "")
        setVolumeError(true)
      if(highestTicket.current.value === "")
        setHighestEstimationError(true)
      if(averageTicket.current.value === "")
        setAverageError(true)



      setPersonalDetails({firstName: firstName, lastName: lastName, address1: address1, city: city, state: state, zip: zip, dob: dob, ssn: ssn, monthlyVolume: monthlyVolume.current.value, averageTicket: averageTicket.current.value, highestTicket: highestTicket.current.value, industry: industry, mcc: mcc, productDescription: productDescription, phoneNumber: phone, commodityCode: commodityCode, website: website, gender: gender, suite: suite})

      console.log(Number(monthlyVolume.current.value.substring(1).replace(/,/g, "")))
   


      if (firstName === '' || lastName  === '' || address1  === '' || city  === '' || state  === '' || zip  === '' || dob  === '' || ssn  === '' || monthlyVolume.current.value === '' || averageTicket.current.value === '' || highestTicket.current.value === '' || industry === '' || phone === '' || productDescription === '' || website === '' || gender === ''){
        setError(true)
      }else if (getAge(dob) < 18){
        setError(false)
        setAgeError(true)

      } else if (ssn.length != 11) {
        setAgeError(false)
        setSSNError(true)
      } else {

        window.removeEventListener("beforeunload", handleBeforeUnload);
        history.push("/onboarding-bankingdetails")

      }

      


    }
  }}
  />;
}

const OnboardingPersonalDetails = React.forwardRef(OnboardingPersonalDetails_);

export default OnboardingPersonalDetails;
