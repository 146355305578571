// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicEditRoleModal } from "./plasmic/collect_wise_draft/PlasmicEditRoleModal";
import { useState } from "react";
import { useAuth } from "../contexts/AuthContext"
import axios from "axios";
import { config } from "./constants";
import LoadingSpinner from "./LoadingSpinner";

function EditRoleModal_(props, ref) {


  const { role, permissions, showToast, setShowModal } = props;
  const [roleName, setroleName] = useState(role.name)
  const [rolePermissions, setrolePermissions] = useState(role.permissions)
  const [loading, setloading] = useState(false)
  const { currentUser } = useAuth();

  const handleOnChange = (value) => {
    setrolePermissions(value)
  }

  const handleEditRole = async () => {

    if (!roleName) {
      showToast(false, "Please enter role name")
      return;
    }

    if (rolePermissions.length <= 0) {
      showToast(false, "Please select atleast one permission")
      return;
    }

    try {
      setloading(true);

      await axios({
        method: "POST",
        url: `${config.endpoint}/editRole`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${await currentUser.getIdToken()}`
        },
        data: {
          role: {
            name: roleName,
            permissions: rolePermissions
          },
          uid: currentUser.uid,
          prevRoleName: role.name
        }
      })
      setShowModal(false);
      showToast(true, "Role Updated successfully")


    } catch (error) {
      console.log(error)
      showToast(false, "Something went wrong")

    }

    setloading(false);

  }

  return <>
    {loading ? <LoadingSpinner styles={{ height: "260px" }} />
      : <PlasmicEditRoleModal root={{ ref }} {...props}

      permissionSelectionStack={{
        value: rolePermissions,
        options: permissions?.map((value)=>({ label: value.name, value: value.name})),
        onChange: (val) => handleOnChange(val)
      }}

        saveEditRoleBtn={{
          onClick: handleEditRole
        }}
        roleName={{
          value: roleName,
          onChange: (e) => setroleName(e.target.value)
        }}
        
        permissionChipsStack={{
          children: <></>
        }}
      />}

  </>
}

const EditRoleModal = React.forwardRef(EditRoleModal_);

export default EditRoleModal;
