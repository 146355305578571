import React, { useState, useEffect, useCallback, useRef } from "react";
import { PlasmicBillingSettings } from "./plasmic/collect_wise_draft/PlasmicBillingSettings";
import { db, storage } from "../firebase";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  serverTimestamp,
  getDoc,
  query,
  where,
  getDocs,
  onSnapshot,
  orderBy,
  limit,
  updateDoc,
} from "firebase/firestore";
import LoadingSpinner from "./LoadingSpinner";
import { useAuth } from "../contexts/AuthContext";
import {
  usePlaidLink,
  PlaidLinkOptions,
  PlaidLinkOnSuccess,
} from "react-plaid-link";
import axios from "axios";
import { v4 } from "uuid";
import { config } from "./constants";
import { ApideckVault } from "@apideck/vault-js";
import LoadingSpinner4 from "./LoadingSpinner4";
import InvitedStatusCard from "./InvitedStatusCard"
import AcceptedStatusCard from "./AcceptedStatusCard"
import { Button, Modal } from "react-bootstrap"
import InviteUserCard from "./InviteUserCard"
import InviteUserModal from "./InviteUserModal"
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import BillingSettings from "./BillingSettings";


function BillingLink(props, ref) {

    const [loading, setLoading] = useState(true)
    const { currentUser } = useAuth();
    const history = useHistory()


    const onSuccess = useCallback(async (public_token, metadata) => {
            
            setLoading(true)
       
            var resp = await axios({
                method: "POST",
                url: `${config.endpoint}/paymentFunctions-linkBankAccount`,
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${await currentUser.getIdToken()}`
                },
                data: {
                    public_token_object: {
                        public_token: public_token
                    },
                    metadata: metadata,
                    userInfoDocId: props.userInfoDocId,
                    email: currentUser.email
                }
              })
           // write code for linking the bank to their firestore account
    

           props.setLinkToken("")

           toast.success("Bank account linked successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
           
      
      }, [])
    
    
      const config1 = {
        onSuccess: onSuccess,
        onExit: (err, metadata) => { props.setLinkToken("") },
        onEvent: (eventName, metadata) => {},
        token: props.linkToken,
        env: config.version,
      }
    
      const { open, ready } = usePlaidLink(config1)
    



if (ready){
    open()
}



  


  return (
    <React.Fragment>
       {<PlasmicBillingSettings root={{ ref }} {...props}
        vertStack = { loading && {
            children: <LoadingSpinner4 />
        }}
        linkBank = {{
            onClick: async () => {
              open()
  
            }
          }}
       /> }
  </React.Fragment>
  );


}

export default React.forwardRef(BillingLink);