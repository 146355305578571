// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicRoles } from "./plasmic/collect_wise_draft/PlasmicRoles";
import { Container, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { Button, Modal } from "antd"
import AddRoleModal from "./AddRoleModal"
import RolesCard from "./RolesCard";
import EditRoleModal from "./EditRoleModal"
import { useEffect } from "react";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  serverTimestamp,
  getDoc,
  query,
  where,
  getDocs,
  onSnapshot,
  orderBy,
  limit,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../firebase";
import { useAuth } from "../contexts/AuthContext";
import LoadingSpinner from "./LoadingSpinner";
import PermissionChip from "./PermissionChip";
import "./roleCard.css";

function Roles_(props, ref) {


  const [permissions, setpermissions] = useState([])
  const [loading, setloading] = useState(false)
  const [user, setuser] = useState()
  const { currentUser, permissionModal, setPermissionModal } = useAuth();



  const handleClose = () => setPermissionModal(false)

  const showToast = (status, msg) => {

    if (status) {
      toast.success(msg, {
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
    else {
      toast.error(msg, {
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }


  }


  useEffect(() => {
    setloading(true);

    const q = query(
      collection(db, 'permissions'),
    );

    getDocs(q)
      .then((querySnapshot) => {

        querySnapshot.forEach((doc) => {

          const data = doc.data();

          setpermissions(prev => [...prev, data])
        });
      })



    if (!currentUser) return setloading(false);

    const qu = query(
      collection(db, 'userInfo'),
      where('user', '==', currentUser.uid)
    );

    onSnapshot(qu, (snapshot) => {
      if (snapshot.empty) return setloading(false);

      const userInfo = snapshot.docs[0].data();
      setuser(userInfo)
      setloading(false)
    })

  }, [])


  return <React.Fragment>
    <ToastContainer />
    {loading ? <LoadingSpinner />
      : <PlasmicRoles root={{ ref }} {...props}
        addRoleBtn={{
          onClick: () => setPermissionModal(true)
        }}
        rolesListStack={{
          children: <>
            {user?.user_defined_roles &&
              user?.user_defined_roles.map((r) => {
                return <RolesCard
                  permissions={permissions}
                  role={r}
                  showToast={showToast}
                />

              })
            }
          </>
        }}

      />}
      <Modal
      visible={permissionModal}
      title={<div className="role-card-title">Add Role</div>}
      centered
      onCancel={handleClose}
      footer={null}
    >
      <AddRoleModal permissions={permissions} showToast={showToast} setShowModal={setPermissionModal} />
    </Modal>

  </React.Fragment>
}

const Roles = React.forwardRef(Roles_);

export default Roles;