import * as React from "react";
import { PlasmicSelectBizClass__Overlay } from "./plasmic/collect_wise_draft/PlasmicSelectBizClass__Overlay";

function SelectBizClass__Overlay_(props, ref) {
  const { plasmicProps } = PlasmicSelectBizClass__Overlay.useBehavior(
    props,
    ref
  );

  return <PlasmicSelectBizClass__Overlay {...plasmicProps} />;
}

const SelectBizClass__Overlay = React.forwardRef(SelectBizClass__Overlay_);

export default Object.assign(SelectBizClass__Overlay, {
  __plumeType: "triggered-overlay"
});
