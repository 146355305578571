// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState} from "react";
import { PlasmicTimeSelectStack } from "./plasmic/collect_wise_draft/PlasmicTimeSelectStack";
import { useAuth } from "../contexts/AuthContext";
import ClickAwayListener from 'react-click-away-listener';

function TimeSelectStack_(props, ref) {
  const { currentUser, timeSelectPostClick, setTimeSelectPostClick, currentTimeSelect, setCurrentTimeSelect } = useAuth()


  const onClickAway = () => {
    setTimeSelectPostClick(false)
  }


  return <ClickAwayListener onClickAway={onClickAway}>
  <PlasmicTimeSelectStack root={{ ref }} {...props} 
  timeSelect={{
    onClick: () => {
      setTimeSelectPostClick(!timeSelectPostClick)
    }
  }}
  dropdownicon={{
    onClick: () => {
      setTimeSelectPostClick(!timeSelectPostClick)
    }
  }}
  lastWeek={{
    onClick: () => {
      setCurrentTimeSelect("Last Week")
      setTimeSelectPostClick(false)
    }
  }}
  lastMonth={{
    onClick: () => {
      setCurrentTimeSelect("Last Month")
      setTimeSelectPostClick(false)
    }
  }}
  last3Months={{
    onClick: () => {
      setCurrentTimeSelect("Last 3 Months")
      setTimeSelectPostClick(false)
    }
  }}
  lastYear={{
    onClick: () => {
      setCurrentTimeSelect("Last Year")
      setTimeSelectPostClick(false)
    }
  }}
  />
  </ClickAwayListener>
}

const TimeSelectStack = React.forwardRef(TimeSelectStack_);

export default TimeSelectStack;
