// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState} from "react";
import { PlasmicPaymentsPage } from "./plasmic/collect_wise_draft/PlasmicPaymentsPage";

function PaymentsPage_(props, ref) {
  const [showSideBar, setShowSideBar] = useState(false)

  return <PlasmicPaymentsPage root={{ ref }} {...props} 
  viewDetails={{
    onClick: () => setShowSideBar(!showSideBar)
  }}
  paymentsSideBar={{
    wrap: node => showSideBar ? node : null
  }}
  />;
}

const PaymentsPage = React.forwardRef(PaymentsPage_);

export default PaymentsPage;
