// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useEffect} from "react";
import { PlasmicNewOnboardingPage } from "./plasmic/collect_wise_draft/PlasmicNewOnboardingPage";
import {useAuth} from "../contexts/AuthContext"
import { useHistory } from 'react-router-dom';
import { hotjar } from 'react-hotjar';
import useLocalStorage from "../hooks/useLocalStorage";
import {db} from "../firebase"
import { collection, addDoc, doc, setDoc, serverTimestamp, updateDoc, query, where, getDocs } from "firebase/firestore";

hotjar.initialize(3206049, 6);

function NewOnboardingPage_(props, ref) {
 const [country, setCountry] = useState('')
 const [accountType, setAccountType] = useState('merchant')
 const [businessType, setBusinessType] = useState('')
 const [businessStructure, setBusinessStructure] = useState('')
const history = useHistory()
const {setupDetails, setSetupDetails, currentUser} = useAuth()

// uncomment once you are ready to bring on client users
// const [merchantCheck, setMerchantCheck] = useState(false)
// const [clientCheck, setClientCheck] = useState(false)
// const [hybridCheck, setHybridCheck] = useState(false)


const [error, setError] = useState(false)
// add in logic that disables the other check boxes when
// the others are clicked so the user can't pick both

  return <PlasmicNewOnboardingPage root={{ ref }} {...props}
  selectCountry={{
    onChange: (e) => {
      setCountry(e)
    }
  }}
  selectBusinessType={{
    onChange: (e) => {
      setBusinessType(e)
    }
  }}
  businessStructText={{
    wrap: node => businessType === 'individual'? null : node
  }}
  selectBusinessStructure={{
      wrap: node => businessType === 'individual'? null : node,
      props: {
        onChange: (e) => {
          setBusinessStructure(e)
        }
      }
  }}
  errorText={{
    wrap: node => error ? node : null
  }}
  continueButton={{
    onClick: async () => {
      setSetupDetails({country: country, accountType: accountType, businessType: businessType, businessStructure: businessStructure})

if (((businessType === 'individual') && (country === '' || accountType === '')) || ((businessType === 'company') && (country === '' || accountType === '' || businessStructure === '')) || businessType === ''){
  setError(true)
} else{

  if (businessType === 'individual' ){
    history.push("/onboarding-personaldetails")
  } else if (businessStructure === 'sole_proprietorship'){
    history.push("/onboarding-soleproprietordetails")
  } else{
    history.push("/onboarding-businessdetails")
  }

}

      
    }
  }}
  />;
}

const NewOnboardingPage = React.forwardRef(NewOnboardingPage_);

export default NewOnboardingPage;
