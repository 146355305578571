// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState} from "react";
import { PlasmicAddBankManuallyModal } from "./plasmic/collect_wise_draft/PlasmicAddBankManuallyModal";
import '../CardModal.css'
import LoadingSpinner from './LoadingSpinnerModal';
import axios from 'axios';
import { config } from "./constants";
import { useAuth } from "../contexts/AuthContext";

function AddBankManuallyModal_(props, ref) {

  const [accountNum, setAccountNum] = useState('')
  const [routingNum, setRoutingNum] = useState('')
  const [address1, setAddress1] = useState('')
  const [city, setCity] = useState('')
  const [zip, setZip] = useState('')
  const [selectState, setSelectState] = useState('')
  const [loading, setLoading] = useState(false)
  const { currentUser } = useAuth();

  return <PlasmicAddBankManuallyModal root={{ ref }} {...props} 
  manualAdd={ loading && {children: <LoadingSpinner />} }
  closeButton={{
    onClick: () => {
      props.setAddBankModal(false)
    }
  }}
  accountNum={{
    value: accountNum,
    onChange: (e) => setAccountNum(e.target.value)
  }}
  routingNum={{
    value: routingNum,
    onChange: (e) => setRoutingNum(e.target.value)
  }}
  address1={{
    value: address1,
    onChange: (e) => setAddress1(e.target.value)
  }}
  city={{
    value: city,
    onChange: (e) => setCity(e.target.value)
  }}
  zip={{
    value: zip,
    onChange: (e) => setZip(e.target.value)
  }}
  selectState={{
    children: <>
            <select className='select2' onChange={(e) => setSelectState(e.target.value)}>
        <option value="select-option" disabled selected hidden>Select State...</option>
        <option value="AL">Alabama</option>
	<option value="AK">Alaska</option>
	<option value="AZ">Arizona</option>
	<option value="AR">Arkansas</option>
	<option value="CA">California</option>
	<option value="CO">Colorado</option>
	<option value="CT">Connecticut</option>
	<option value="DE">Delaware</option>
	<option value="DC">District Of Columbia</option>
	<option value="FL">Florida</option>
	<option value="GA">Georgia</option>
	<option value="HI">Hawaii</option>
	<option value="ID">Idaho</option>
	<option value="IL">Illinois</option>
	<option value="IN">Indiana</option>
	<option value="IA">Iowa</option>
	<option value="KS">Kansas</option>
	<option value="KY">Kentucky</option>
	<option value="LA">Louisiana</option>
	<option value="ME">Maine</option>
	<option value="MD">Maryland</option>
	<option value="MA">Massachusetts</option>
	<option value="MI">Michigan</option>
	<option value="MN">Minnesota</option>
	<option value="MS">Mississippi</option>
	<option value="MO">Missouri</option>
	<option value="MT">Montana</option>
	<option value="NE">Nebraska</option>
	<option value="NV">Nevada</option>
	<option value="NH">New Hampshire</option>
	<option value="NJ">New Jersey</option>
	<option value="NM">New Mexico</option>
	<option value="NY">New York</option>
	<option value="NC">North Carolina</option>
	<option value="ND">North Dakota</option>
	<option value="OH">Ohio</option>
	<option value="OK">Oklahoma</option>
	<option value="OR">Oregon</option>
	<option value="PA">Pennsylvania</option>
	<option value="RI">Rhode Island</option>
	<option value="SC">South Carolina</option>
	<option value="SD">South Dakota</option>
	<option value="TN">Tennessee</option>
	<option value="TX">Texas</option>
	<option value="UT">Utah</option>
	<option value="VT">Vermont</option>
	<option value="VA">Virginia</option>
	<option value="WA">Washington</option>
	<option value="WV">West Virginia</option>
	<option value="WI">Wisconsin</option>
	<option value="WY">Wyoming</option>
  </select>
    </>
  }}
  submitButton={{
    onClick: async () => {

      // set modal loading to true, make API call to backend to add bank account, then close modal and set to false
      setLoading(true)

      const resp = await axios({
        url: `${config.endpoint}/paymentFunctions-newLinkBank`,
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${await currentUser.getIdToken()}`
        },
        data: {
          accountNum: accountNum,
          routingNum: routingNum,
          address1: address1,
          city: city,
          zip: zip,
          selectState: selectState,
          userInfoDocId: props.userInfoDocId,
          merchantName: props.merchantName
        }
      })

      setLoading(false)

      props.setAddBankModal(false)

    }
  }}
  />;
}

const AddBankManuallyModal = React.forwardRef(AddBankManuallyModal_);

export default AddBankManuallyModal;
