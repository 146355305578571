// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, { useState, useEffect } from "react";
import { PlasmicCurrentInvoicePaymentsPagePreview } from "./plasmic/collect_wise_draft/PlasmicCurrentInvoicePaymentsPagePreview";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { db, storage } from "../firebase";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  serverTimestamp,
  getDoc,
  query,
  where,
  getDocs,
  onSnapshot,
  orderBy,
  limit,
  updateDoc,
} from "firebase/firestore";
import { getMultiFactorResolver } from "firebase/auth";
import InvoiceItem from "./InvoiceItem";
import { format } from "date-fns";
import LoadingSpinner from "./LoadingSpinner";
import Moment from "moment";
import { v4 } from "uuid";
import axios from "axios";
import LoadingSpinnerWithText from "./LoadingSpinnerWithText";
import { PlasmicCurrentInvoicePaymentsPagePreviewAch } from "./plasmic/collect_wise_draft/PlasmicCurrentInvoicePaymentsPagePreviewAch";
import { PlasmicCurrentInvoicePaymentsPagePreviewCard } from "./plasmic/collect_wise_draft/PlasmicCurrentInvoicePaymentsPagePreviewCard";
import { PlasmicCurrentInvoicePaymentsPagePreviewSurcharge } from "./plasmic/collect_wise_draft/PlasmicCurrentInvoicePaymentsPagePreviewSurcharge";
import { config } from "./constants";

function CurrentInvoicePaymentsPagePreview_(props, ref) {
  const [loading, setLoading] = useState(true);
  const {
    currentUser,
    invoiceFields,
    setInvoiceFields,
    invoiceBalance,
    setInvoiceBalance,
    invoiceSubmitParams,
    setInvoiceSubmitParams,
  } = useAuth();
  const [merchantName, setMerchantName] = useState("");
  const [showSidebar, setShowSideBar] = useState(false);
  const [invoiceNum, setInvoiceNum] = useState(0);
  const formatDate = Moment().format("MMM Do, YYYY");
  const [loadingText, setLoadingText] = useState(false);
  const [pageType, setPageType] = useState("");
  const [approved, setApproved] = useState(false);
  const [userInfo, setuserInfo] = useState()
  const history = useHistory();
  

    useEffect(() => {
    const q = query(
      collection(db, "userInfo"),
      where("user", "==", currentUser.uid)
    )


    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setMerchantName(doc.data().merchantName);
        setInvoiceNum(doc.data().invoiceNum);
        setuserInfo(doc.data())
      
        // cards have to be approved and the identity also has to be approved in order for it to be approved
        setApproved(doc.data().cardApproval & doc.data().identityVerification)

      });
    });

    try {
      if (
        invoiceSubmitParams.paymentMethods.ACH &&
        !(
          invoiceSubmitParams.paymentMethods.Card ||
          invoiceSubmitParams.paymentMethods.CardSurcharge
        )
      ) {
        setPageType("ACH");
      } else if (
        (invoiceSubmitParams.paymentMethods.Card ||
          invoiceSubmitParams.paymentMethods.CardSurcharge) &&
        !invoiceSubmitParams.paymentMethods.ACH
      ) {
        setPageType("Card");
      } else if (
        invoiceSubmitParams.paymentMethods.CardSurcharge &&
        invoiceSubmitParams.paymentMethods.ACH
      ) {
        setPageType("CardSurcharge");
      }
    } catch (e) {}

    setLoading(false);
  }, []);



  function formatInvoiceNum() {

    return invoiceSubmitParams.client.invoice_identifier + "-" + String(invoiceSubmitParams.client.invoiceNum).padStart(4, '0');


  }






  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };

  const handleSendInvoice = async(paymentId,city,state,country,products,userInfoDocId)=>{
     axios({
      method: "POST",
      url: `${config.endpoint}/sendInvoice`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await currentUser.getIdToken()}`,
      },
      data: {
        clientEmail: invoiceSubmitParams.client.email,
        clientName: invoiceSubmitParams.client.company,
        merchantName: merchantName,
        merchantEmail: currentUser.email,
        amount: invoiceBalance.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
        buttonUrl: `https://app.astrius.co/invoicepayments/${paymentId}`,
        invoiceNum: invoiceSubmitParams.client.invoiceNum ? invoiceSubmitParams.client.invoiceNum : invoiceNum,
        invoice_identifier: invoiceSubmitParams.client.invoice_identifier ? invoiceSubmitParams.client.invoice_identifier : null,
        date: format(new Date(), "LL-dd-yy"),
        dueDate: format(invoiceSubmitParams.dueDate, "LL-dd-yy"),
        dueDate2: format(invoiceSubmitParams.dueDate, "MMMM d, y"),
        city: city,
        state: state,
        country: country,
        notes: invoiceSubmitParams.notes,
        products: products,
        invoiceFields: invoiceFields,
        userInfoDocId: userInfoDocId,
        amountNum: invoiceBalance * 100,
      },
    }).then((res)=>{
    console.log(res)
    }).catch((err)=>{console.log(err)});
  }


  async function handleSubmit() {

    if (!approved) {
      alert(
        "Payments haven't been fully approved on your account yet. We'll send you an email as soon as this has been enabled."
      );

      return;
    }
    // add webhook check here


    setLoadingText(true);

    window.addEventListener("beforeunload", handleBeforeUnload);

    try {
     
      const ip = await axios.get("https://geolocation-db.com/json/");
      var fundingSource;
      var merchantName = "";
      var invoiceNum = 0;
      var userInfoDocId = "";
      var city = "";
      var state = "";
      var country = "";
      var userData = {};

      const q = query(
        collection(db, "userInfo"),
        where("user", "==", currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        fundingSource = doc.data().fundingSource;
        merchantName = doc.data().merchantName;
        invoiceNum = doc.data().invoiceNum;
        userInfoDocId = doc.id;
        city = doc.data().city;
        state = doc.data().state;
        country = doc.data().country;
        userData = doc.data();
      });

      var paymentId = v4();
      var paymentDataId = v4();

      var products = [];

      for (var i = 0; i < invoiceFields.length; i++) {
        products.push({
          quantity: invoiceFields[i].quantity,
          description: invoiceFields[i].item,
          "tax-rate": 0,
          price: Number(invoiceFields[i].price.replace(/[^0-9\.-]+/g, "")),
        });
      }
     
      handleSendInvoice(paymentId,city,state,country,products,userInfoDocId)
      const invoice_dueDate = format(invoiceSubmitParams.dueDate, "YYY-MM-dd");
      var response = {
        data: {
          id: null
        }
      }

     
      if(invoiceSubmitParams.client.api_deck_clientId){
        
        const params = {
          invoice: {
            type: "service",
            number: invoiceNum?.toString(),
            customer: {
              id: invoiceSubmitParams.client.api_deck_clientId,
              display_name: invoiceSubmitParams.client.contactName,
            },
            invoice_date: new Date().toString(),
            due_date: invoice_dueDate,
            terms: "one year",
            po_number: null,
            reference: "8765432",
            status: "authorised",
            invoice_sent: true,
            currency: "USD",
            currency_rate: null,
            tax_inclusive: null,
            sub_total: invoiceBalance,
            total_tax: null,
            tax_code: null,
            discount_percentage: null,
            discount_amount: null,
            total: invoiceBalance,
            balance: invoiceBalance,
            deposit: null,
            customer_memo: invoiceSubmitParams.notes,
            line_items: invoiceFields?.length
              ? invoiceFields?.map((invoice) => ({
                  row_id: invoice?.id,
                  // code: "SALES",
                  line_number: 1,
                  description: invoice?.item,
                  type: "sales_item",
                  tax_amount: 0,
                  total_amount: invoice?.balanceDue,
                  quantity: +invoice?.quantity,
                  unit_price: +invoice?.price?.substring(1),
                  unit_of_measure: "pc.",
                  discount_percentage: 0,
                  discount_amount: 0,
                  location_id: null,
                  department_id: null,
                  item: {
                    id: "",
                  },
                  // may cause issues with Xero so will have to check
                  tax_rate: {
                    id: "",
                    // code: "OUTPUT",
                    // name: "OUTPUT",
                    // rate: 0.00
                  },
                  ledger_account: {
                    id: "",
                    nominal_code: "4000",
                    code: "4000",
                  },
                  row_version: "",
                }))
              : [],
            billing_address: {
              id: "123",
              type: "primary",
              string: "25 Spring Street, Blackburn, VIC 3130",
              name: "HQ US",
              line1: "Main street",
              line2: "apt #",
              line3: "Suite #",
              line4: "delivery instructions",
              street_number: "25",
              city: "San Francisco",
              state: "CA",
              postal_code: "94104",
              country: "US",
              latitude: "40.759211",
              longitude: "-73.984638",
              county: "Santa Clara",
              contact_name: "Elon Musk",
              salutation: "Mr",
              phone_number: "111-111-1111",
              fax: "122-111-1111",
              email: invoiceSubmitParams.client.email,
              website: "https://elonmusk.com",
              row_version: "1-12345",
            },
            shipping_address: {
              id: "123",
              type: "primary",
              string: "25 Spring Street, Blackburn, VIC 3130",
              name: "HQ US",
              line1: "Main street",
              line2: "apt #",
              line3: "Suite #",
              line4: "delivery instructions",
              street_number: "25",
              city: "San Francisco",
              state: "CA",
              postal_code: "94104",
              country: "US",
              latitude: "40.759211",
              longitude: "-73.984638",
              county: "Santa Clara",
              contact_name: "Elon Musk",
              salutation: "Mr",
              phone_number: "111-111-1111",
              fax: "122-111-1111",
              email: invoiceSubmitParams.client.email,
              website: "https://elonmusk.com",
              row_version: "1-12345",
            },
            // template_id: "123456",
            source_document_url: "https://www.invoicesolution.com/invoice/123456",
            row_version: "1-12345",
          },
        };
        response = await axios({
          method: "POST",
          url: `${config.endpoint}/apiDeckFunctions?consumerId=${currentUser.uid}&method=createInvoice`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await currentUser.getIdToken()}`,
          },
          data: params,
        })
      }

      const payments = await addDoc(collection(db, "payments"), {
        api_deck_invoiceId: response ? response.data.id : null,
        user: currentUser.uid,
        userEmail: currentUser.email,
        type: "Invoice Payment",
        amount: invoiceBalance.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
        amountNum: invoiceBalance,
        paymentId: paymentId,
        status: "Not Paid",
        client: JSON.stringify(invoiceSubmitParams.client),
        clientCompany: invoiceSubmitParams.client.company,
        dateCreated: formatDate,
        paymentDataId: paymentDataId,
        datePaid: "NA",
        paymentMethod: "NA",
        paymentNotes: "",
        date: serverTimestamp(),
        clientEmail: invoiceSubmitParams.client.email,
        clientUrl: invoiceSubmitParams.client.url,
        datePaidNum: "NA",
        fundsLocation: "NA",
        paymentSuccess: false,
        clientDocId: invoiceSubmitParams.client.docId,
        invoiceNum: invoiceSubmitParams.client.invoiceNum ? invoiceSubmitParams.client.invoiceNum : invoiceNum,
        invoice_identifier: invoiceSubmitParams.client.invoice_identifier ? invoiceSubmitParams.client.invoice_identifier : null,
        fundingDestination: typeof fundingSource === "undefined" ? "" : fundingSource,
        milestoneOrContract: "NA",
        merchantName: merchantName,
        invoiceFields: invoiceFields,
        dueDate: invoiceSubmitParams.dueDate,
        userInfoDocId: userInfoDocId,
        reminderOptions: invoiceSubmitParams.reminderOptions,
        automationOptions: invoiceSubmitParams.automationOptions,
        paymentMethods: invoiceSubmitParams.paymentMethods,
        paymentVoided: false,
        voidDate: "NA",
        contractTitle: "NA",
        merchantId:
          typeof userData.merchantId === "undefined" ? "" : userData.merchantId,
        terminalId:
          typeof userData.terminalId === "undefined" ? "" : userData.terminalId,
        zip: userData.zip,
        yourToken: v4(),
        ip: ip.data.IPv4,
        heartlandPublicKey: userData.heartlandPublicKey,
        plan: userInfo.plan,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        api_deck_ledger_id: userInfo.api_deck_ledger_id ? userInfo.api_deck_ledger_id : null,
        api_deck_supplier_id: userInfo.api_deck_supplier_id ? userInfo.api_deck_supplier_id : null,
        api_deck_bank_id: userData.api_deck_bank_id ? userData.api_deck_bank_id : null
      });
    

      await updateDoc(doc(db, "userInfo", userInfoDocId), {
        invoiceNum: invoiceNum + 1,
      });

      await updateDoc(doc(db, "clients", invoiceSubmitParams.client.docId), {
        invoiceNum: invoiceSubmitParams.client.invoiceNum ? (invoiceSubmitParams.client.invoiceNum + 1) : 1,
      })

      setInvoiceBalance(0);
      setInvoiceFields([
        { item: "", quantity: 0, price: "", id: v4(), balanceDue: 0 },
      ]);
      setInvoiceSubmitParams({});
      history.push(`payment/${paymentDataId}`);
    } catch (e) {
      console.log(e);
      alert("Failed to submit request, please try again");
    }

    window.removeEventListener("beforeunload", handleBeforeUnload);
  }

  return loading ? (
    <LoadingSpinner />
  ) : loadingText ? (
    <LoadingSpinnerWithText text={"Sending Invoice"} />
  ) : pageType === "ACH" ? (
    <PlasmicCurrentInvoicePaymentsPagePreviewAch
      merchantName={merchantName}
      amount={invoiceBalance.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}
      dueDate={
        " due on " + format(invoiceSubmitParams.dueDate, "MMMM do, yyyy")
      }
      invoiceNumber={invoiceSubmitParams.client.invoice_identifier ? "Invoice #" + formatInvoiceNum() : "Invoice #" + invoiceNum}
      sidebarAmount={invoiceBalance.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}
      viewDetails={{
        onClick: () => setShowSideBar(!showSidebar),
      }}
      sideBarInstance={{
        wrap: (node) => (showSidebar ? node : null),
      }}
      closeButton={{
        onClick: () => setShowSideBar(false),
      }}
      invoiceItemStack={{
        children: invoiceFields.map((x) => {
          return (
            <InvoiceItem
              item={x.item}
              quantity={`Qty ${x.quantity}`}
              amount={x.price.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            />
          );
        }),
      }}
      goBackText={{
        onClick: () => history.push("/invoicesubmission"),
      }}
      goBackArrow={{
        onClick: () => history.push("/invoicesubmission"),
      }}
      sendButton={{
        onClick: () => handleSubmit(),
      }}
    />
  ) : pageType === "Card" ? (
    <PlasmicCurrentInvoicePaymentsPagePreviewCard
      merchantName={"merchantName"}
      amount={invoiceBalance.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}
      dueDate={
        " due on " + format(invoiceSubmitParams.dueDate, "MMMM do, yyyy")
      }
      invoiceNumber={invoiceSubmitParams.client.invoice_identifier ? "Invoice #" + formatInvoiceNum() : "Invoice #" + invoiceNum}
      sidebarAmount={invoiceBalance.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}
      viewDetails={{
        onClick: () => setShowSideBar(!showSidebar),
      }}
      sideBarInstance={{
        wrap: (node) => (showSidebar ? node : null),
      }}
      closeButton={{
        onClick: () => setShowSideBar(false),
      }}
      invoiceItemStack={{
        children: invoiceFields.map((x) => {
          return (
            <InvoiceItem
              item={x.item}
              quantity={`Qty ${x.quantity}`}
              amount={x.price.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            />
          );
        }),
      }}
      goBackText={{
        onClick: () => history.push("/invoicesubmission"),
      }}
      goBackArrow={{
        onClick: () => history.push("/invoicesubmission"),
      }}
      sendButton={{
        onClick: () => handleSubmit(),
      }}
    />
  ) : pageType === "CardSurcharge" ? (
    <PlasmicCurrentInvoicePaymentsPagePreviewSurcharge
      merchantName={merchantName}
      amount={invoiceBalance.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}
      dueDate={
        " due on " + format(invoiceSubmitParams.dueDate, "MMMM do, yyyy")
      }
      invoiceNumber={invoiceSubmitParams.client.invoice_identifier ? "Invoice #" + formatInvoiceNum() : "Invoice #" + invoiceNum}
      sidebarAmount={invoiceBalance.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}
      viewDetails={{
        onClick: () => setShowSideBar(!showSidebar),
      }}
      sideBarInstance={{
        wrap: (node) => (showSidebar ? node : null),
      }}
      closeButton={{
        onClick: () => setShowSideBar(false),
      }}
      invoiceItemStack={{
        children: invoiceFields.map((x) => {
          return (
            <InvoiceItem
              item={x.item}
              quantity={`Qty ${x.quantity}`}
              amount={x.price.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            />
          );
        }),
      }}
      goBackText={{
        onClick: () => history.push("/invoicesubmission"),
      }}
      goBackArrow={{
        onClick: () => history.push("/invoicesubmission"),
      }}
      sendButton={{
        onClick: () => handleSubmit(),
      }}
    />
  ) : (
    <PlasmicCurrentInvoicePaymentsPagePreview
      root={{ ref }}
      {...props}
      merchantName={merchantName}
      amount={invoiceBalance.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}
      dueDate={
        " due on " + format(invoiceSubmitParams.dueDate, "MMMM do, yyyy")
      }
      invoiceNumber={invoiceSubmitParams.client.invoice_identifier ? "Invoice #" + formatInvoiceNum() : "Invoice #" + invoiceNum}
      sidebarAmount={invoiceBalance.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}
      viewDetails={{
        onClick: () => setShowSideBar(!showSidebar),
      }}
      sideBarInstance={{
        wrap: (node) => (showSidebar ? node : null),
      }}
      closeButton={{
        onClick: () => setShowSideBar(false),
      }}
      invoiceItemStack={{
        children: invoiceFields.map((x) => {
          return (
            <InvoiceItem
              item={x.item}
              quantity={`Qty ${x.quantity}`}
              amount={x.price.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            />
          );
        }),
      }}
      goBackText={{
        onClick: () => history.push("/invoicesubmission"),
      }}
      goBackArrow={{
        onClick: () => history.push("/invoicesubmission"),
      }}
      sendButton={{
        onClick: () => handleSubmit(),
      }}
    />
  );
}

const CurrentInvoicePaymentsPagePreview = React.forwardRef(
  CurrentInvoicePaymentsPagePreview_
);

export default CurrentInvoicePaymentsPagePreview;