import React, { useState } from "react";
import { PlasmicCreateNewPassword } from "./plasmic/collect_wise_draft/PlasmicCreateNewPassword";
import { confirmPasswordReset } from 'firebase/auth';
import { Link, useHistory } from "react-router-dom"
import { auth } from '../firebase';
import { ToastContainer, toast } from 'react-toastify';

function CreateNewPassword_(props, ref) {
  const [passwordContent, setPasswordContent] = useState('')
  const [confirmPasswordContent, setConfirmPasswordContent] = useState('')
  const [incorrectPasswordsError, setIncorrectPasswordsError] = useState(false)
  const [customError, setCustomError] = useState(false)
  const [errorContent, setErrorContent] = useState('Failed to login')
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  function containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }

  function containsNumber(str) {
    const specialChars = /[1234567890]/;
    return specialChars.test(str);
  }


  async function handleSubmit() {
   
    setIncorrectPasswordsError(false)
    setCustomError(false)
    setErrorContent('Unable to reset password')
  
  
 if (!(passwordContent.length > 11 && containsSpecialChars(passwordContent) && containsNumber(passwordContent))){
    setErrorContent("Password needs to be more than 11 characters, contain at least 1 special character, and contain at least 1 number")
    setCustomError(true)
  } else if (passwordContent !== confirmPasswordContent){
    setErrorContent("Passwords do not match")
    setCustomError(true)

  } else {

    setCustomError(false)

    var password = passwordContent

      setLoading(true)

      confirmPasswordReset(auth, props.code, password).then( async () => {
        
        toast.success("Password reset successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
          })

        history.push('/login')

      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setCustomError(true)
      })
   
  }

  setLoading(false)
  
  }


  return (
  <>
  <ToastContainer />
  <PlasmicCreateNewPassword root={{ ref }} {...props}
  submitButton={{
    onClick: () => handleSubmit(),
  }}
  password={{
    value: passwordContent,
    onChange: (e) => {
      setPasswordContent(e.target.value);
    }
  }}
  confirmPassword={{
    value: confirmPasswordContent,
    onChange: (e) => {
      setConfirmPasswordContent(e.target.value);
    }
  }}
  customErrorInstance={{
    wrap: node => customError ? node : null,
    props: {
      customErrorText: errorContent
    }
  }}
  /> 
  </>
  )
}

const CreateNewPassword = React.forwardRef(CreateNewPassword_);

export default CreateNewPassword;
