// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicDashboardPaymentsCard } from "./plasmic/collect_wise_draft/PlasmicDashboardPaymentsCard";
import DashboardPaidStatusCard from "./DashboardPaidStatusCard"
import DashboardNotPaidStatusCard from "./DashboardNotPaidStatusCard";
import DashboardVoidStatusCard from "./DashboardVoidStatusCard"

function DashboardPaymentsCard_(props, ref) {
 
  return <PlasmicDashboardPaymentsCard root={{ ref }} {...props} 
  statusStack={{
    children: props.paymentVoided ? <DashboardVoidStatusCard/> : props.paidStatus === 'Paid' ? <DashboardPaidStatusCard/> : <DashboardNotPaidStatusCard/>
  }}
  />;
}

const DashboardPaymentsCard = React.forwardRef(DashboardPaymentsCard_);

export default DashboardPaymentsCard;
