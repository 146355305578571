// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicDashboardContractsCard } from "./plasmic/collect_wise_draft/PlasmicDashboardContractsCard";
import DashboardInProgressStatusCard from "./DashboardInProgressStatusCard";
import DashboardCompletedStatusCard from "./DashboardCompletedStatusCard"

function DashboardContractsCard_(props, ref) {



  return <PlasmicDashboardContractsCard root={{ ref }} {...props} 
  statusStack={{
    children: <DashboardInProgressStatusCard/>
  }}
  />;
}

const DashboardContractsCard = React.forwardRef(DashboardContractsCard_);

export default DashboardContractsCard;
