// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useEffect}  from "react";
import { PlasmicNewPlanInformation } from "./plasmic/collect_wise_draft/PlasmicNewPlanInformation";
import {useAuth} from "../contexts/AuthContext"
import {useHistory} from 'react-router-dom'
import {
  collection,
  addDoc,
  doc,
  setDoc,
  serverTimestamp,
  getDoc,
  query,
  where,
  getDocs,
  onSnapshot,
  orderBy,
  limit,
  updateDoc,
} from "firebase/firestore";
import LoadingSpinner2 from "./LoadingSpinner2";
import { config } from './constants'
import {db} from "../firebase"

function NewPlanInformation_(props, ref) {
  const {currentUser} = useAuth()

  const history = useHistory()

  const [freeCheck, setFreeCheck] = useState(false)
  const [customCheck, setCustomCheck] = useState(false)
  const [premiumCheck, setPremiumCheck] = useState(false)
  const [growthCheck, setGrowthCheck] = useState(false)
  const [newPlan, setNewPlan] = useState('')  
  const [errorText, setErrorText] = useState(false)
  const [loading, setLoading] = useState(false)


  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };



  return <PlasmicNewPlanInformation root={{ ref }} {...props} 
  vertStack={ loading && {
    children: <LoadingSpinner2/>
  }}
  freeCheck={{
    isChecked: freeCheck,
    onChange: (e) => {
      setNewPlan('Free')
      setFreeCheck(!freeCheck)
      setCustomCheck(false)
      setPremiumCheck(false)
      setGrowthCheck(false)
    },
  }}
  premiumCheck={{
    isChecked: premiumCheck,
    onChange: (e) => {
      setNewPlan('Premium')
      setPremiumCheck(!premiumCheck)
      setCustomCheck(customCheck)
      setGrowthCheck(false)
      setFreeCheck(false)
    },
  }}
  growthCheck={{
    isChecked: growthCheck,
    onChange: (e) => {
      setNewPlan('Growth')
      setGrowthCheck(!growthCheck)
      setCustomCheck(customCheck)
      setPremiumCheck(false)
      setFreeCheck(false)
    },
  }}
  customCheck={{
    isChecked: customCheck,
    onChange: (e) => {
      setNewPlan('Custom')
      setCustomCheck(!customCheck)
      setPremiumCheck(false)
      setGrowthCheck(false)
      setFreeCheck(false)
    },
  }}
  errorText={{
    wrap: node => errorText ? node : null
  }}
  submitButton={{
    onClick: async () => {
    


      if (newPlan === ''){
        setErrorText(true)
      } else {

        window.addEventListener("beforeunload", handleBeforeUnload);

        history.push("/onboarding-confirm-identity")

        const q = query(
          collection(db, "userInfo"),
          where("user", "==", currentUser.uid)
        );
    
        const querySnapshot = await getDocs(q);

          var docId = ''

        querySnapshot.forEach((doc) => {
        
         docId = doc.id

        });

        if (newPlan === 'Custom') {

        await updateDoc(doc(db, "userInfo", docId), {
          plan: newPlan,
          customLimit: 1,
          customPrice: 0.0025,
          onboarding: {
            setupDetailsComplete: true,
            bankingDetailsComplete: true,
            planInformationComplete: true,
            confirmIdentityStarted: false,
          }
        })

      } else {

        await updateDoc(doc(db, "userInfo", docId), {
          plan: newPlan,
          onboarding: {
            setupDetailsComplete: true,
            bankingDetailsComplete: true,
            planInformationComplete: true,
            confirmIdentityStarted: false,
          }
        })

      }
       

      }

    }
  }}
  />;
}

const NewPlanInformation = React.forwardRef(NewPlanInformation_);

export default NewPlanInformation;
