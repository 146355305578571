// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicOnboardingBizSuccess } from "./plasmic/collect_wise_draft/PlasmicOnboardingBizSuccess";
import LoadingSpinner from "./LoadingSpinner";
import {useHistory} from "react-router-dom"
import {useAuth} from "../contexts/AuthContext"

function OnboardingBizSuccess_(props, ref) {
  
const {onboardModal, setOnboardModal} = useAuth()
const history = useHistory()

  return onboardModal ? <LoadingSpinner/> : <PlasmicOnboardingBizSuccess root={{ ref }} {...props} continueButton={{onClick: () => history.push("/")}}/>;
}

const OnboardingBizSuccess = React.forwardRef(OnboardingBizSuccess_);

export default OnboardingBizSuccess;
