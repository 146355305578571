// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useRef} from "react";
import { PlasmicCreditorPayoutModal } from "./plasmic/collect_wise_draft/PlasmicCreditorPayoutModal";
import Form from 'react-bootstrap/Form';
import CurrencyInput from "react-currency-input-field";
import { db, storage } from "../firebase"
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit, updateDoc } from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import { v4 } from "uuid";
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import { config } from './constants'
import LoadingSpinner from './LoadingSpinnerModal';


function CreditorPayoutModal_(props, ref) {

  const currencyInput = useRef(null)

  const formatDate = Moment().format("MMM Do, YYYY");
  const [loading, setLoading] = useState(false)
  const [percentage, setPercentage] = useState()
  const [type, setType] = useState("")

  const { currentUser, showToast } = useAuth()


  const handleTypeSelect = (e) => {

    setType(e.target.value)

  }


  return <PlasmicCreditorPayoutModal root={{ ref }} {...props}
  center={ loading && {
    children: <LoadingSpinner />
  }}
  percentage={{
    value: percentage,
    onChange: (e) => {

      if (e.target.value.length < 3) {
        setPercentage(e.target.value)
      }

    }
  }}
  selectTypeStack= {{
    children : <>
    <Form.Select aria-label="Default select example" onChange={handleTypeSelect} value={type}>
      <option value="" disabled>Select</option>
      <option key={"Standard"} value={"Standard"}>Standard</option>
      <option key={"Flat Rate"} value={"Flat Rate"}>Flat Rate</option>
      <option key={"Custom"} value={"Custom"}>Custom</option>
    </Form.Select>
  </>
  }}
  setPrice={{
    onClick: async () => {
      
      if (type === "" || (type === "Flat Rate" && !(percentage))) {
        alert("Fill out the pricing details")
      } else {

      await updateDoc(doc(db, "userInfo", props.userInfoDocId), {
        pricingType: type,
        pricingRate: percentage ? percentage : "NA"
      })

      // close modal
      props.setShowCreditorModal(false)

      toast.success("Pricing Set Successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        })

      }

    }
  }}
  />;
}

const CreditorPayoutModal = React.forwardRef(CreditorPayoutModal_);

export default CreditorPayoutModal;
