// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useEffect, useCallback} from "react";
import { useHistory } from "react-router-dom";
import { PlasmicOnboardingBankingDetailsBiz } from "./plasmic/collect_wise_draft/PlasmicOnboardingBankingDetailsBiz";
import LoadingSpinner2 from "./LoadingSpinner2";
import axios from "axios";
import { usePlaidLink, PlaidLinkOptions, PlaidLinkOnSuccess } from 'react-plaid-link';
import { v4 } from "uuid";
import {useAuth} from "../contexts/AuthContext"
import { config } from './constants'

const handleBeforeUnload = (e) => {
  e.preventDefault();
  const message =
    "Are you sure you want to leave? All provided data will be lost.";
  e.returnValue = message;
  return message;
};

function Link(props, ref1) {

  const {currentUser, publicToken, setPublicToken, metaData, setMetaData} = useAuth()
  const [showAlternate, setShowAlternate] = useState(false)
  const [accountNum, setAccountNum] = useState("")
  const [routingNum, setRoutingNum] = useState("")
  const [bankName, setBankName] = useState("")
  const [accountType, setAccountType] = useState("")

  useEffect(() => {

// adjust to the person that needs to manually link their bank account.
if (currentUser.email === "sidney@mishe.co"){
  setShowAlternate(true)
}

  }, [])


  const history = useHistory()

  const onSuccess = useCallback (async (public_token, metadata) => {

    setPublicToken(public_token)
    setMetaData(metadata)

    window.removeEventListener("beforeunload", handleBeforeUnload);
    
    history.push("/review-businessdetails")

  })

  const config1 = {
    onSuccess,
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    token: props.linkToken,
    env: config.version,
  }
  
  const {open, ready} = usePlaidLink(config1)


return <PlasmicOnboardingBankingDetailsBiz root={{ ref1 }} {...props} addBankButton={{
  onClick: () => open()
}}
manualAdd={{
  wrap: (node) => (showAlternate ? node : null),
}}
accountNum={{
  value: accountNum,
  onChange: (e) => {
    setAccountNum(e.target.value)
  }
}}
routingNum={{
  value: routingNum,
  onChange: (e) => {
    setRoutingNum(e.target.value)
  }
}}
bankName={{
  value: bankName,
  onChange: (e) => {
    setBankName(e.target.value)
  }
}}
accountType={{
  onChange: (e) => {
    console.log(e)
    setAccountType(e)
  }
}}
manualSubmit={{
  onClick: () => {
  setPublicToken({accountNum: accountNum, routingNum: routingNum, bankName: bankName, accountType: accountType})
  history.push("/review-businessdetails")

  }
}}
/>;


}



function OnboardingBankingDetailsBiz_(props, ref) {
  const [loading, setLoading] = useState(true)
  const [linkToken, setLinkToken] = useState(null);
  const {currentUser} = useAuth()
  

  async function generateToken(){


    const response = await axios({
      method: "POST",
      url: `${config.endpoint}/achFunctions-plaidAPIRequest`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${await currentUser.getIdToken()}`
      },
      data: {
        "user": v4()
      }
    })
    
    setLinkToken(response.data.link_token)
    
    setLoading(false)

    }


    useEffect(() => {
      window.addEventListener("beforeunload", handleBeforeUnload);
     
      generateToken()
    
      }, [])



  return ( linkToken == null ? <PlasmicOnboardingBankingDetailsBiz root={{ ref }} {...props} vertStack={ loading && {
    children: <LoadingSpinner2/>
  }}/> : <Link linkToken={linkToken}/> )

}

const OnboardingBankingDetailsBiz = React.forwardRef(
  OnboardingBankingDetailsBiz_
);

export default OnboardingBankingDetailsBiz;
