// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useRef} from "react";
import { PlasmicCreateSubscriptionModal } from "./plasmic/collect_wise_draft/PlasmicCreateSubscriptionModal";
import Form from 'react-bootstrap/Form';
import CurrencyInput from "react-currency-input-field";
import { db, storage } from "../firebase"
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit, updateDoc } from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import { v4 } from "uuid";
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';

function CreateSubscriptionModal_(props, ref) {
 
const [cycle, setCycle] = useState("")

const { currentUser } = useAuth()
const formatDate = Moment().format("MMM Do, YYYY");

  const currencyInput = useRef(null)

  const handleCycleSelect = (e) => {

    setCycle(e.target.value)

  }
  
  return <PlasmicCreateSubscriptionModal root={{ ref }} {...props}
  amount={{
    children: <CurrencyInput className="input"
    prefix="$"
    name="currencyInput"
    id="currencyInput"
    data-number-to-fixed="2"
    data-number-stepfactor="100"
    // value={value}
    placeholder="$ "
    // onChange={handleChange}
    // onBlur={handleOnBlur}
    allowDecimals
    decimalsLimit="2"
    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
    disableAbbreviations
    ref={currencyInput}
  />
  }}
  selectCycleStack = {{
    children : <>
    <Form.Select aria-label="Default select example" onChange={handleCycleSelect} value={cycle}>
      <option value="" disabled>Select</option>
      <option key={"Monthly"} value={"Monthly"}>Monthly</option>
      <option key={"Weekly"} value={"Weekly"}>Weekly</option>
      <option key={"Biweekly"} value={"Biweekly"}>Biweekly</option>
      <option key={"Bimonthly"} value={"Bimonthly"}>Bimonthly</option>
    </Form.Select>
  </>
  }}
  generateLinkButton={{
    onClick: async () => {
     
      if (currencyInput.current.value === "" || cycle === ""){
        alert("Please enter the required fields")
      } else {
       
        var paymentId = v4()

        var amount = currencyInput.current.value

        console.log(props.debtorData)

        var userInfoDocId = ""
        var userEmail = ""

        const q = query(
          collection(db, "userInfo"),
          where("user", "==", props.debtorData.user)
        )

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          userInfoDocId = doc.id
          userEmail = doc.data().email
        })


        const payments = await addDoc(collection(db, "payments"), {
          api_deck_invoiceId: null,
          user: props.debtorData.user,
          userEmail: userEmail,
          type: "Subscription Payment",
          amount: amount,
          amountNum: Number(amount.substring(1).replace(/,/g, "")),
          paymentId: paymentId,
          status: "Not Paid",
          client: JSON.stringify({"company":"Sample","email":"sean1107obrien+sample@gmail.com","contactName":"Sample","notes":"","date":{"seconds":1691864967,"nanoseconds":792000000},"docId":"28782b3a-1bea-4335-8c1e-3276ad86df56","url":"https://api-sandbox.dwolla.com/customers/8a685c39-3b04-4c06-bdca-8f40dc469b1d","clientId":"7eb03a3c-4c74-456a-b0d9-bee28458d3d8","api_deck_clientId":null,"invoiceNum":7,"invoice_identifier":"V7EB03A3"}),
          clientCompany: props.debtorData.debtorName,
          dateCreated: formatDate,
          paymentDataId: v4(),
          datePaid: "NA",
          paymentMethod: "NA",
          paymentNotes: "",
          date: new Date(),
          clientEmail: "",
          clientUrl: "NA",
          datePaidNum: "NA",
          fundsLocation: "NA",
          paymentSuccess: false,
          invoiceNum: "1",
          invoice_identifier: null,
          fundingDestination: "",
          milestoneOrContract: "NA",
          merchantName: props.debtorData.creditorName,
          invoiceFields: [{ item: props.debtorData.productName, quantity: 1, price: amount, id: v4(), balanceDue: Number(amount.substring(1).replace(/,/g, "")) }],
          dueDate: new Date(),
          userInfoDocId: userInfoDocId,
          reminderOptions: { enabled: false, number: 0, unit: '' },
          automationOptions: { enabled: false, number: 0, unit: '' },
          paymentMethods: { ACH: true, Card: true, Wire: true, CardSurcharge: false },
          paymentVoided: false,
          voidDate: "NA",
          contractTitle: "NA",
          yourToken: v4(),
          subscriptionCycle: cycle,
          subscriptionEnabled: false,
          totalDebtAmount: props.debtorData.debtAmount,
          logoUrl: "",
          color: "",
          debtorDocId: props.docId
          }
        )        


        var paymentLink = `https://app.collectwise.org/subscriptionpayments/${paymentId}`

        navigator.clipboard.writeText(paymentLink)

        props.setShowModal(false)

        toast.success("Payment Link copied to clipboard", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
          });

      }

    }
  }}
  />;
}

const CreateSubscriptionModal = React.forwardRef(CreateSubscriptionModal_);

export default CreateSubscriptionModal;
