import * as React from "react";
import { PlasmicSelectState__Overlay } from "./plasmic/collect_wise_draft/PlasmicSelectState__Overlay";

function SelectState__Overlay_(props, ref) {
  const { plasmicProps } = PlasmicSelectState__Overlay.useBehavior(props, ref);
  return <PlasmicSelectState__Overlay {...plasmicProps} />;
}

const SelectState__Overlay = React.forwardRef(SelectState__Overlay_);

export default Object.assign(SelectState__Overlay, {
  __plumeType: "triggered-overlay"
});
