import * as React from "react";
import { PlasmicSelectBizClass__Option } from "./plasmic/collect_wise_draft/PlasmicSelectBizClass__Option";

function SelectBizClass__Option_(props, ref) {
  const { plasmicProps } = PlasmicSelectBizClass__Option.useBehavior(
    props,
    ref
  );

  return <PlasmicSelectBizClass__Option {...plasmicProps} />;
}

const SelectBizClass__Option = React.forwardRef(SelectBizClass__Option_);

export default Object.assign(SelectBizClass__Option, {
  __plumeType: "select-option"
});
