// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicOnboardingBizApplicationReceived } from "./plasmic/collect_wise_draft/PlasmicOnboardingBizApplicationReceived";
import LoadingSpinner from "./LoadingSpinner";
import {useHistory} from "react-router-dom"
import {useAuth} from "../contexts/AuthContext"

function OnboardingBizApplicationReceived_(props, ref) {

  const {onboardModal, setOnboardModal} = useAuth()
  const history = useHistory()

  return onboardModal ? <LoadingSpinner/> : <PlasmicOnboardingBizApplicationReceived root={{ ref }} {...props} />;
}

const OnboardingBizApplicationReceived = React.forwardRef(
  OnboardingBizApplicationReceived_
);

export default OnboardingBizApplicationReceived;
