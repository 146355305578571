import * as React from "react";
import { PlasmicSelectControllerType__OptionGroup } from "./plasmic/collect_wise_draft/PlasmicSelectControllerType__OptionGroup";

function SelectControllerType__OptionGroup(props) {
  const { plasmicProps } =
    PlasmicSelectControllerType__OptionGroup.useBehavior(props);
  return <PlasmicSelectControllerType__OptionGroup {...plasmicProps} />;
}

export default Object.assign(SelectControllerType__OptionGroup, {
  __plumeType: "select-option-group"
});
