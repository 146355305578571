// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicTimeSelect } from "./plasmic/collect_wise_draft/PlasmicTimeSelect";
import { useAuth } from "../contexts/AuthContext";

function TimeSelect_(props, ref) {
  const { currentTimeSelect, setCurrentTimeSelect } = useAuth()



  return <PlasmicTimeSelect root={{ ref }} {...props} currentSelect={currentTimeSelect}/>;
}

const TimeSelect = React.forwardRef(TimeSelect_);

export default TimeSelect;
