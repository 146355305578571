// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, { useState, useEffect, useCallback } from "react";
import { PlasmicSubscriptionPaymentsPage } from "./plasmic/collect_wise_draft/PlasmicSubscriptionPaymentsPage";

import LoadingSpinner from './LoadingSpinner'
import axios from 'axios'
import {
  usePlaidLink,
  PlaidLinkOptions,
  PlaidLinkOnSuccess,
} from 'react-plaid-link'
import { v4 } from 'uuid'
import PaymentSuccess from './PaymentSuccess'
import { useParams } from 'react-router-dom'
import { db, storage } from '../firebase'
import {
  collection,
  addDoc,
  doc,
  setDoc,
  serverTimestamp,
  getDoc,
  query,
  where,
  getDocs,
  onSnapshot,
  orderBy,
  limit,
  updateDoc,
} from 'firebase/firestore'
import { format } from 'date-fns'
import InvoiceItem from './InvoiceItem'
import Moment from 'moment'
import PaymentVoided from './PaymentVoided'
import LoadingSpinnerWithText from './LoadingSpinnerWithText'
import { Button, Modal, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../CardModal.css'
import { useAuth } from '../contexts/AuthContext'
import CardModalHeader from './CardModalHeader'
import CardModalFoot from "./CardModalFoot"
import EdgePayInput from './EdgePayInput'
import EdgePayInput1 from './EdgePayInput1'
import { config } from './constants'
import PaymentFraud from "./PaymentFraud"
import {PlasmicSubscriptionPaymentsPageAch} from "./plasmic/collect_wise_draft/PlasmicSubscriptionPaymentsPageAch";
import {PlasmicSubscriptionPaymentsPageCard} from "./plasmic/collect_wise_draft/PlasmicSubscriptionPaymentsPageCard";
import {PlasmicSubscriptionPaymentsPageSurcharge} from "./plasmic/collect_wise_draft/PlasmicSubscriptionPaymentsPageSurcharge";
import PaymentCard from "./paymentCard";
import { saveAs } from 'file-saver';
import CardForm from "./CardForm"


function Link(props, ref) {

  let { paymentId } = useParams()
  const [loading, setLoading] = useState(false)
  const [showSidebar, setShowSideBar] = useState(false)
  const [paymentSuccess, setPaymentSuccess] = useState(
    props.screenData.paymentSuccess,
  )
  const [successDate, setSuccessDate] = useState(props.screenData.datePaid)
  const formatDate = Moment().format('MMM Do, YYYY')
  const [showModal, setShowModal] = useState(false)
  const { closeCardModal, setCloseCardModal,  edgeLoading, setEdgeLoading, paySuccess, setPaySuccess } = useAuth()
  const [achModal, setAchModal] = useState(false)
  const [paymentFraud, setPaymentFraud] = useState(props.screenData.paymentFraud)
  const [sessionId, setSessionId] = useState('')
  const [modalLoading, setModalLoading] = useState(false)

  const handleClose = () => setCloseCardModal(false)

  const handleAchClose = () => setAchModal(false)

  function formatInvoiceNum() {

    return props.screenData.invoice_identifier + "-" + String(props.screenData.invoiceNum).padStart(4, '0');

  }


  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };



const handleDownloadPDF = async ()=> {
  axios({
   method: "POST",
   url: `${config.endpoint}/createInvoicePDF`,
   headers: {
     "Content-Type": "application/json",
   },
   data: {
     clientEmail: props?.screenData?.clientEmail,
     clientName: props?.screenData?.clientCompany,
     merchantName: props?.screenData?.merchantName,
     amount: props?.screenData?.amount,
     invoiceNum: props?.screenData?.invoiceNum ? props?.screenData?.invoiceNum : props?.screenData?.invoiceNum,
     invoice_identifier: props?.screenData?.invoice_identifier ? props?.screenData?.invoice_identifier : null,
     date: props?.screenData?.date,
     dueDate: props?.screenData?.dueDate,
     products: props?.screenData?.products ? props?.screenData?.products : [],
     invoiceFields: props?.screenData?.invoiceFields,
     userInfoDocId: props?.screenData?.userInfoDocId,
     amountNum: props?.screenData?.amountNum * 100,
     buttonUrl: window.location.href,
   },
 }).then((res)=> {

  // Assuming pdfResponse.attachment.content contains the base64-encoded PDF
  const base64Data = res.data.attachment.content;

  // Decode the base64 data into a binary string
  const binaryString = atob(base64Data);

  // Create an array buffer from the binary string
  const arrayBuffer = new ArrayBuffer(binaryString.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < binaryString.length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i);
  }

  // Create a Blob from the array buffer with the correct MIME type
  const blob = new Blob([arrayBuffer], { type: 'application/pdf' });

  // Trigger the download
  saveAs(blob, res.data.attachment.filename);
  }).catch((err)=>{console.log(err)});
}


async function onCardSuccess(response) {

  console.log(response)

  if (response.messages.resultCode === "Error") {
      var i = 0;
      while (i < response.messages.message.length) {
          console.log (
              response.messages.message[i].code + ": " +
              response.messages.message[i].text
          )
          i = i + 1;
      }

      setModalLoading(false)
      
      // entually add in more custom error handling as described at the end of this documentation that details what the particular error is: https://developer.authorize.net/api/reference/features/acceptjs.html
      alert("The card information that you provided was invalid. Please re-enter your card details and try again.")

  } else {

  window.addEventListener("beforeunload", handleBeforeUnload);

  var resp = await axios({
      method: "POST",
      url: `${config.endpoint}/paymentFunctions-cardTransaction`,
      headers: {
        "Content-Type": "application/json",
        'authorization': props.screenData.yourToken
      },
      data: {
        opaqueData: response.opaqueData,
        screenData: props.screenData
      }
    })

  window.removeEventListener("beforeunload", handleBeforeUnload);


  if (resp.data === "success") {

    setSuccessDate(formatDate)
    setPaymentSuccess(true)

    setCloseCardModal(false)

  } else {

    setModalLoading(false)
    alert("Your card transaction was declined. Please ensure that you have sufficient funds or try again using a different card.")

  }


  }


}


  

  const onTransferSuccess = useCallback(async (public_token, metadata) => {
    setLoading(true)
   

      window.addEventListener("beforeunload", handleBeforeUnload);


        const payment = await axios({
          method: "POST",
          url: `${config.endpoint}/paymentFunctions-achTransaction`,
          headers: {
            'authorization': props.screenData.yourToken
          },
          data: {
            public_token_object: {
              public_token: public_token,
            },
            account_id: metadata.account_id,
            metadata: metadata,
            paymentId: props.screenData.paymentId,
            user: props.screenData.user,
            screenData: props.screenData
          }
        })


      window.removeEventListener("beforeunload", handleBeforeUnload);

        
        setSuccessDate(formatDate)
        setPaymentSuccess(true)
        setLoading(false)

  
  }, [])


  const config1 = {
    onSuccess: onTransferSuccess,
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    token: props.linkToken,
    env: config.version,
  }

  const { open, ready } = usePlaidLink(config1)


  function handleBankTransfer() {
    open()
  }


  return (
    <>
      { paymentFraud ? <PaymentFraud/> : paymentSuccess ? (
        <PaymentSuccess
          amount={ (typeof props.screenData.surchargedAmount !== 'undefined' && Number(props.screenData.surchargedAmount) !== props.screenData.amountNum ) ? "$" + props.screenData.surchargedAmount : props.screenData.amountNum.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          }) }
          date={'On ' + successDate}
          screenData={props.screenData}
        />
      ) : 
      edgeLoading ?  <LoadingSpinnerWithText text={'Processing Payment'} /> : loading ? (
        <LoadingSpinnerWithText text={'Processing Payment'} />
      ) : props.screenData.paymentVoided ? (
        <PaymentVoided date={'On ' + props.screenData.voidDate} />
      ) : <PaymentCard pageData = {props.screenData}
       pageType = {props.screenData?.type !== "Invoice Payment" ?
        "" : props.pageType}
       handleBankTransfer = {handleBankTransfer}
       handleModalOpen = { () => setCloseCardModal(true) }
       handleDownloadPDF = {handleDownloadPDF}
       />}   
      <Modal
        dialogClassName="card-modal"
        show={closeCardModal}
        onHide={handleClose}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <CardModalHeader color={props?.screenData?.color}/>
          <CardForm
            screenData={props.screenData}
            onCardSuccess={onCardSuccess}
            modalLoading={modalLoading}
            setModalLoading={setModalLoading}
          />
          <CardModalFoot/>
        </Modal.Body>
      </Modal>
    </>
  )
}



function SubscriptionPaymentsPage_(props, ref) {
  let { paymentId } = useParams()
  const [loading, setLoading] = useState(true)
  const [screenData, setScreenData] = useState({})
  const [userData, setUserData] = useState({})
  const [linkToken, setLinkToken] = useState(null)
  const [docId, setDocId] = useState('')
  const [pageType, setPageType] = useState('')
  const { currentUser } = useAuth()


  useEffect(async () => {

    // combine paymentInfo axios request and generate token into one request. Then test it out.

    const response = await axios({
      method: 'POST',
      url: `${config.endpoint}/paymentInfo`,
      data: {
        paymentId,
        type: 'invoice',
        user: v4()
      }
      })

      console.log(response.data)

      const paymentDoc = response.data.paymentDoc

      setLinkToken(response.data.link_token.link_token)

        if (paymentDoc.paymentMethods.ACH && !(paymentDoc.paymentMethods.Card || paymentDoc.paymentMethods.CardSurcharge)){
          setPageType('ACH')
        } else if ((paymentDoc.paymentMethods.Card || paymentDoc.paymentMethods.CardSurcharge) && !paymentDoc.paymentMethods.ACH){
          setPageType('Card')
        } else if (paymentDoc.paymentMethods.CardSurcharge && paymentDoc.paymentMethods.ACH){
          setPageType('CardSurcharge')
        }

        setLoading(false)
        setScreenData(paymentDoc)
 
     }, [])

  
  return (<>
  {loading || !Object.keys(screenData).length ? (
        <LoadingSpinner />
      ) : linkToken == null ? (
  <PlasmicSubscriptionPaymentsPage root={{ ref }} {...props} />
  ) : (
    <Link
      linkToken={linkToken}
      screenData={screenData}
      docId={docId}
      pageType={pageType}
    />
  )}
  </> )
}

const SubscriptionPaymentsPage = React.forwardRef(SubscriptionPaymentsPage_);

export default SubscriptionPaymentsPage;




