// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicSidebar } from "./plasmic/collect_wise_draft/PlasmicSidebar";
import { Link, useHistory } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext";


function Sidebar_(props, ref) {

  const { currentUser } = useAuth()
  
  const history = useHistory()
 
  
  return <PlasmicSidebar root={{ ref }} {...props}  
  dashboardButton={{
    onClick: () => history.push("/")
  }}
  paymentsButton={{
    onClick: () => history.push("/paymentslist")
  }}
  contractsButton={{
    onClick: () => history.push("/contractslist")
  }}
  clientsButton={{
    onClick: () => { 

      if (currentUser.uid === "cg0KYF8bHvSuyK7BFwPjCrwm0Dp2"){
        history.push('/alldebtors')
      } else {
        history.push("/debtorslist") 
      }
      
    }
  }}
  settingsButton={{
    onClick: () => history.push("/settings/billing")
  }}
  rolesBtn = {{
    onClick: () => history.push("/roles")
  }}
  />;
}

const Sidebar = React.forwardRef(Sidebar_);

export default Sidebar;
